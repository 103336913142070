import React from "react";
import PhoneInput from "../../../../components/form/PhoneInput";
import Toggle from "../../../../components/form/Toggle";
import ValidatedInput from "../../../../components/form/ValidatedInput";
import { validateEmail } from "../../../../lib";

export default class ParentContactInfo extends React.PureComponent {
  render() {
    const {
      countries,
      getProfileFieldLabel,
      isProfileFieldRequired,
      onChangeProfile,
      onChangeProfileEvt,
      profile: {
        address,
        doParentsShareResidence,
        father = {},
        mother = {},
      } = { person: {} },
      profileValidationErrors,
      showFormValidation,
      updateProfileValidation,
    } = this.props;

    return (
      <div className="profile-form-section">
        <p className="profile-section-title">Parent Contact Information</p>
        <div className="profile-form-grid parent-contact-info-grid">
          <ValidatedInput
            className="input-container"
            label={getProfileFieldLabel("Father's Name", "father.name")}
            name="father.name"
            onChange={onChangeProfileEvt}
            required={isProfileFieldRequired("father.name")}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            value={father.name}
          />
          <ValidatedInput
            className="input-container"
            label={getProfileFieldLabel("Mother's Name", "mother.name")}
            name="mother.name"
            onChange={onChangeProfileEvt}
            required={isProfileFieldRequired("mother.name")}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            value={mother.name}
          />
          <ValidatedInput
            className="input-container"
            label={getProfileFieldLabel("Father's Email", "father.email")}
            name="father.email"
            onChange={onChangeProfileEvt}
            required={isProfileFieldRequired("father.email")}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(email) => {
              const isValid = validateEmail(email);
              updateProfileValidation("father.email", isValid);
              return isValid;
            }}
            validationMessage="Invalid email address"
            value={father.email}
          />
          <ValidatedInput
            className="input-container"
            label={getProfileFieldLabel("Mother's Email", "mother.email")}
            name="mother.email"
            onChange={onChangeProfileEvt}
            required={isProfileFieldRequired("mother.email")}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(email) => {
              const isValid = validateEmail(email);
              updateProfileValidation("mother.email", isValid);
              return isValid;
            }}
            validationMessage="Invalid email address"
            value={mother.email}
          />
          <div
            className={`input-container ${
              profileValidationErrors.indexOf("father.phoneNumber") >= 0 ||
              (showFormValidation &&
                isProfileFieldRequired("father.phoneNumber") &&
                !father.phoneNumber)
                ? "error"
                : ""
            }`}
          >
            <label>
              {getProfileFieldLabel("Father's Phone", "father.phoneNumber")}
            </label>
            <PhoneInput
              countries={countries || []}
              countryId={father.phoneCountryID}
              defaultCountryName={address && address.country} //fallback on country associated with student's address
              error={profileValidationErrors.indexOf("father.phoneNumber") >= 0}
              name="father.phoneNumber"
              onBlur={(name, isValid) => updateProfileValidation(name, isValid)}
              onChange={(name, value) => onChangeProfile(name, value)}
              onChangeCountry={(countryId) => {
                onChangeProfile("father.phoneCountryID", countryId);
              }}
              value={father.phoneNumber}
            />
          </div>
          <div
            className={`input-container ${
              profileValidationErrors.indexOf("mother.phoneNumber") >= 0 ||
              (showFormValidation &&
                isProfileFieldRequired("mother.phoneNumber") &&
                !mother.phoneNumber)
                ? "error"
                : ""
            }`}
          >
            <label>
              {getProfileFieldLabel("Mother's Phone", "mother.phoneNumber")}
            </label>
            <PhoneInput
              countries={countries || []}
              countryId={mother.phoneCountryID}
              defaultCountryName={address && address.country} //fallback on country associated with student's address
              error={profileValidationErrors.indexOf("mother.phoneNumber") >= 0}
              name="mother.phoneNumber"
              onBlur={(name, isValid) => updateProfileValidation(name, isValid)}
              onChange={(name, value) => onChangeProfile(name, value)}
              onChangeCountry={(countryId) => {
                onChangeProfile("mother.phoneCountryID", countryId);
              }}
              value={mother.phoneNumber}
            />
          </div>
          <ValidatedInput
            input={
              <Toggle
                allowNoOption={true}
                className="toggle-container"
                options={[
                  {
                    value: true,
                    display: "Yes",
                  },
                  {
                    value: false,
                    display: "No",
                  },
                ]}
              />
            }
            label={getProfileFieldLabel(
              "Both parents reside at same address",
              "doParentsShareResidence",
            )}
            labelClassName="toggle-label"
            name="doParentsShareResidence"
            onChange={onChangeProfile}
            required={isProfileFieldRequired("doParentsShareResidence")}
            showRequired={
              showFormValidation &&
              doParentsShareResidence !== true &&
              doParentsShareResidence !== false
            }
            value={doParentsShareResidence}
          />
        </div>
      </div>
    );
  }
}
