import React from "react";
import { useSelector } from "react-redux";
import { useMobile } from "../../../themes";
import { Checkbox, Chip, IconButton, Tooltip } from "@material-ui/core";
import { formatFullName, PageLink } from "../../../lib";
import Pages from "../../../pages";
import Loader from "../../../components/Loader";
import { AuthSelectors } from "../../../state";
import JewishInterestLevelChip from "../JewishInterestLevelChip";
import { getStudentListColumnLayout } from "../engagement/shared";

export default function StudentsListRow(props) {
  const {
    index,
    student: {
      allTagNames,
      allTagIds,
      campusName,
      cell,
      checked,
      currentJil,
      email,
      graduationYear,
      isArchived,
      loading,
      studentID,
      studentFirstName,
      studentLastName = {},
    },
    updateArchiveStatus,
    deleteStudent,
    removeSingleParticipantFromGroup,
    toggleSelectStudent,
    viewStudentsByGroup,
  } = props;

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const { isEnrolledInEngagement } = useSelector(
    AuthSelectors.engagementAccess,
  );
  const showJewishInterestLevel = isShliach && isEnrolledInEngagement;
  const isEngagementPortal = window.location.pathname.includes("engagement");
  const isMobile = useMobile();

  const getGroupsLayout = () => (
    <div className="paginated-table-row student-group-table-row">
      <Checkbox
        checked={!!checked}
        color="default"
        inputProps={{ "aria-label": "checkbox with default color" }}
        onChange={() => {
          toggleSelectStudent(index);
        }}
      />
      <PageLink
        className="link-text"
        params={{ studentId: studentID }}
        to={
          isEngagementPortal
            ? Pages.engagement.studentDetails
            : Pages.students.studentDetails
        }
        style={{ maxWidth: "fit-content" }}
        target="_blank"
      >
        {formatFullName(studentFirstName, studentLastName)}
      </PageLink>
      <div className="desktop-hidden tablet-hidden"></div>
      <div className="desktop-hidden tablet-hidden"></div>
      <div className="student-table-row-mobile-small-text">
        {graduationYear && (
          <span className="desktop-hidden tablet-hidden">Graduation Year</span>
        )}{" "}
        {graduationYear}
      </div>
      <div className="desktop-hidden tablet-hidden"></div>
      <div className="desktop-hidden tablet-hidden"></div>
      <div className="student-table-row-mobile-small-text">
        {allTagNames?.map((t, i) => (
          <Chip
            key={i}
            label={t}
            onDelete={(p) => {
              removeSingleParticipantFromGroup(studentID, allTagIds[i]);
            }}
            className="student-tag-chip"
            variant="outlined"
          />
        ))}
      </div>
      <div className="text-right">
        <Tooltip
          arrow
          title={`${isArchived ? "Unarchive" : "Archive"} student`}
        >
          <IconButton
            aria-label="archive"
            style={{ padding: "0px" }}
            onClick={(e) => {
              e.preventDefault();
              updateArchiveStatus(studentID, !isArchived);
            }}
          >
            <i className="material-icons remove-icon">
              {isArchived ? "unarchive" : "archive"}
            </i>
          </IconButton>
        </Tooltip>
      </div>
      <div className="student-table-row-mobile-small-text xs-loader">
        {loading && <Loader />}
      </div>
    </div>
  );

  const getStudentsLayout = () => {
    const archiveIcon = (
      <Tooltip arrow title={`${isArchived ? "Unarchive" : "Archive"} student`}>
        <IconButton
          aria-label="archive"
          style={{ padding: "0px" }}
          onClick={(e) => {
            e.preventDefault();
            updateArchiveStatus(studentID, !isArchived);
          }}
        >
          <i className="material-icons remove-icon">
            {isArchived ? "unarchive" : "archive"}
          </i>
        </IconButton>
      </Tooltip>
    );

    const deleteIcon = (
      <div
        className="btn link-text uppercase-text fw-700"
        onClick={(e) => {
          e.preventDefault();
          deleteStudent(studentID);
        }}
      >
        Delete
      </div>
    );

    return (
      <div className={isShliach && "students-list-table-row-container"}>
        {isShliach && (
          <Checkbox
            checked={!!checked}
            color="default"
            inputProps={{ "aria-label": "checkbox with default color" }}
            onChange={() => {
              toggleSelectStudent(index);
            }}
          />
        )}
        <PageLink
          className="paginated-table-row students-list-table-row"
          style={{
            gridTemplateColumns: getStudentListColumnLayout(
              showJewishInterestLevel,
            ),
          }}
          params={{ studentId: studentID }}
          to={
            isEngagementPortal
              ? Pages.engagement.studentDetails
              : Pages.students.studentDetails
          }
        >
          <div>{formatFullName(studentFirstName, studentLastName)}</div>
          <div className="student-table-row-mobile-small-text">{email}</div>
          <div className="student-table-row-mobile-small-text">{cell}</div>
          <div className="student-table-row-mobile-small-text">
            {graduationYear && (
              <span className="desktop-hidden tablet-hidden">
                Graduation Year
              </span>
            )}{" "}
            {graduationYear}
          </div>
          <div className="student-table-row-mobile-small-text">
            {campusName}
          </div>
          {isMobile ? (
            <div className="flex flex-align-center" style={{ gridGap: "8px" }}>
              {showJewishInterestLevel && (
                <JewishInterestLevelChip
                  jewishInterestLevel={currentJil}
                  narrow
                />
              )}
              {deleteIcon}
              {archiveIcon}
            </div>
          ) : (
            <>
              {showJewishInterestLevel && (
                <JewishInterestLevelChip jewishInterestLevel={currentJil} />
              )}
              {deleteIcon}
              {archiveIcon}
            </>
          )}
        </PageLink>
      </div>
    );
  };

  return viewStudentsByGroup ? getGroupsLayout() : getStudentsLayout();
}
