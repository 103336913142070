import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FieldArray } from "formik";
import { Button, Tooltip } from "@material-ui/core";
import { components as SelectComponents } from "react-select";

import Toggle from "../../../../components/form/Toggle";
import FormInput from "../../../../components/formik/FormInput";
import FormCustomSelect from "../../../../components/form/CustomSelect";
import CustomSelect from "../../../../components/formik/CustomSelect";
import InternPermissions from "./InternPermissions";
import { isMobileView } from "../../../../lib";
import { useSelector } from "react-redux";
import { ChabadHousesActions, SystemSelectors } from "../../../../state";

const newPersonnel = {
  firstName: "",
  lastName: "",
  position: "",
  email: "",
  isNew: true,
};

function PersonnelInfo(props) {
  const { errors, require, setFieldValue, touched, values } = props;

  const [personnelInfoShowing, setPersonnelInfoShowing] = useState(true);
  const [internPermissionsOpen, setInternPermissionsOpen] = useState(false);
  const [openIntern, setOpenIntern] = useState({});

  const [studentsListLoading, setStudentsListLoading] = useState(false);
  const [studentsListError, setStudentsListError] = useState("");
  const [studentsList, setStudentsList] = useState(null);
  // TODO implement:
  // const [showCreateStudentModal, setShowCreateStudentModal] = useState(false);

  const chabadHousePersonnelPositions = useSelector(
    SystemSelectors.chabadHousePersonnelPositions,
  );

  useEffect(() => {
    if (isMobileView()) {
      setPersonnelInfoShowing(false);
    }
  }, []);

  useEffect(() => {
    async function loadStudents() {
      if (!studentsList && !studentsListLoading && !!values.id) {
        setStudentsListLoading(true);

        const response = await ChabadHousesActions.getStudents(values.id);
        if (response.success) {
          setStudentsList(response.data);
          setStudentsListError("");
        } else {
          setStudentsList([]);
          setStudentsListError(response.errorMessage);
        }

        setStudentsListLoading(false);
      }
    }
    loadStudents();
  }, [studentsList, studentsListLoading, values.id]);

  const studentsListOptions = useMemo(
    () =>
      (studentsList || [])
        .filter((s) => !values.personnel.find((p) => p.personID === s.personID))
        .map((s) => ({
          firstName: s.firstName,
          lastName: s.lastName,
          studentID: s.id,
          personID: s.personID,
          email: s.email,
          key: s.id,
          value: s.id,
          label: `${s.firstName} ${s.lastName}`,
        })),
    [studentsList, values.personnel],
  );

  const setPersonnelDetails = useCallback(
    (value, index) => {
      setFieldValue(`personnel[${index}].studentID`, value.studentID);
      setFieldValue(`personnel[${index}].personID`, value.personID);
      setFieldValue(`personnel[${index}].email`, value.email);
      setFieldValue(`personnel[${index}].firstName`, value.firstName);
      setFieldValue(`personnel[${index}].lastName`, value.lastName);
    },
    [setFieldValue],
  );

  const setStudentPersonnelDetails = useCallback(
    (studentId, index) => {
      const student = studentsListOptions.find(
        (s) => s.studentID === studentId,
      );
      setPersonnelDetails(student, index);
    },
    [studentsListOptions, setPersonnelDetails],
  );

  const newPersonnelToggle = useCallback(
    (index) => (
      <div className="personnel-new-toggle">
        <FormInput
          name={`personnel[${index}].chooseStudent`}
          errors={errors}
          touched={touched}
          className="custom-input mr-12"
        >
          {({ field }) => (
            <Toggle
              {...field}
              onChange={(n, v) => {
                setPersonnelDetails(newPersonnel, index);
                setFieldValue(n, v);
              }}
              options={[
                {
                  value: false,
                  display: "Non-student",
                },
                {
                  value: true,
                  display: "Student",
                },
              ]}
            />
          )}
        </FormInput>
      </div>
    ),
    [errors, touched, setFieldValue, setPersonnelDetails],
  );

  const personDetailsSection = useCallback(
    (index, isStudent) => (
      <div className="personnel-line">
        <div>
          <Tooltip
            title={
              isStudent
                ? "Student data can only be edited from student profile"
                : ""
            }
            arrow
          >
            <FormInput
              className="personnel-input"
              label="First Name"
              type="text"
              name={`personnel[${index}].firstName`}
              validate={require}
              errors={errors}
              touched={touched}
              disabled={isStudent}
            />
          </Tooltip>
        </div>
        <div>
          <Tooltip
            title={
              isStudent
                ? "Student data can only be edited from student profile"
                : ""
            }
            arrow
          >
            <FormInput
              className="personnel-input"
              label="Last Name"
              type="text"
              name={`personnel[${index}].lastName`}
              validate={require}
              errors={errors}
              touched={touched}
              disabled={isStudent}
            />
          </Tooltip>
        </div>
        <div>
          <Tooltip
            title={
              isStudent
                ? "Student data can only be edited from student profile. Student must login using the same email they use to login to the student portal."
                : "Email must be a Google account"
            }
            arrow
          >
            <FormInput
              className="personnel-input"
              label="Email"
              type="email"
              name={`personnel[${index}].email`}
              validate={require}
              errors={errors}
              touched={touched}
              disabled={isStudent}
            />
          </Tooltip>
        </div>
      </div>
    ),
    [errors, require, touched],
  );

  const selectStudentSection = useCallback(
    (index, personnel) => (
      <div className="personnel-date-input personnel-line">
        <div className="personnel-student-input personnel-input">
          <label className="line-height-double accent-text small-text">
            Student
          </label>
          <FormCustomSelect
            components={{
              Menu: (props) => (
                <SelectComponents.Menu {...props}>
                  {props.children}
                  {/* TODO implement: */}
                  {/* <div
                    className="link-text"
                    onClick={() => setShowCreateStudentModal(true)}
                    style={{ padding: "8px 12px" }}
                  >
                    Create a new Student
                  </div> */}
                </SelectComponents.Menu>
              ),
            }}
            noOptionsMessage={() =>
              studentsListLoading
                ? "Loading students..."
                : studentsListError || "No students available"
            }
            onChange={(_, v) => {
              setStudentPersonnelDetails(v, index);
            }}
            options={studentsListOptions}
            placeholder={
              studentsListLoading ? "Loading students..." : "Select student..."
            }
            value={{
              value: personnel.studentID,
              label: `${personnel.firstName} ${personnel.lastName}`,
            }}
          />
        </div>
        <div></div>
      </div>
    ),
    [
      setStudentPersonnelDetails,
      studentsListError,
      studentsListLoading,
      studentsListOptions,
    ],
  );

  return (
    <div
      className={
        personnelInfoShowing
          ? "profile-form-section"
          : "profile-form-section mobile-tab-collapsed"
      }
    >
      <FieldArray name="personnel">
        {(arrayHelpers) => (
          <>
            <div className="flex mobile-flex-justify-space flex-align-center mb-16 mt-48">
              <div className="flex flex-align-center">
                <p
                  className="accent-text-dark medium-text fw-700"
                  onClick={() => setPersonnelInfoShowing(!personnelInfoShowing)}
                >
                  Personnel
                </p>
                <i
                  className={`material-icons link-text profile-add-icon ml-16 ${
                    !personnelInfoShowing && "hidden"
                  }`}
                  onClick={() =>
                    arrayHelpers.unshift({
                      ...newPersonnel,
                      chooseStudent: false,
                    })
                  }
                >
                  add_circle
                </i>
              </div>
              <i
                className="material-icons collapsible"
                onClick={() => setPersonnelInfoShowing(!personnelInfoShowing)}
              >
                {personnelInfoShowing
                  ? "keyboard_arrow_up"
                  : "keyboard_arrow_down"}
              </i>
            </div>

            <div className={!personnelInfoShowing ? "no-display" : "mt-12"}>
              {values.personnel?.map((personnel, index) => {
                // TODO: store chooseStudent in state
                const isStudent =
                  personnel.chooseStudent || personnel.studentID;
                const { isNew } = personnel;
                return (
                  <div
                    key={index}
                    className="flex flex-justify-space flex-align-center personnel-row-container hover-delete-container"
                  >
                    <div className="personnel-row">
                      {isNew && newPersonnelToggle(index)}
                      {isNew && isStudent
                        ? selectStudentSection(index, personnel)
                        : personDetailsSection(index, isStudent)}
                      <div className="personnel-line">
                        <div className="personnel-even-width personnel-space-top personnel-max-width">
                          <FormInput
                            label="Position"
                            name={`personnel[${index}].position`}
                            validate={require}
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-12"
                          >
                            {({ field }) => (
                              <div className="personnel-grow">
                                <CustomSelect
                                  {...field}
                                  errors={errors}
                                  options={
                                    chabadHousePersonnelPositions &&
                                    chabadHousePersonnelPositions.map((p) => ({
                                      key: p.enumValue,
                                      value: p.enumValue,
                                      label: p.displayValue,
                                    }))
                                  }
                                  placeholder="Select..."
                                  setFieldValue={setFieldValue}
                                  touched={touched}
                                />
                              </div>
                            )}
                          </FormInput>
                        </div>
                        <div className="personnel-date-input personnel-even-width">
                          <FormInput
                            label="Login Valid Through"
                            type="date"
                            name={`personnel[${index}].expirationDate`}
                            validate={false}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className="personnel-manage-access-button personnel-even-width">
                          <Tooltip
                            title={
                              isNew ? "Save new personnel to manage access" : ""
                            }
                            arrow
                          >
                            <div className="personnel-manage-access-btn-container">
                              <Button
                                disabled={isNew}
                                onClick={() => {
                                  setInternPermissionsOpen(true);
                                  setOpenIntern(personnel);
                                }}
                              >
                                Manage Access
                                <i className="material-icons ml-4 medium-text">
                                  launch
                                </i>
                              </Button>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <i
                      className="material-icons link-text ml-16 hover-delete"
                      style={{
                        fontSize: "16px",
                      }}
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      delete
                    </i>
                  </div>
                );
              })}
              {internPermissionsOpen && (
                <InternPermissions
                  onClose={() => {
                    setInternPermissionsOpen(false);
                    setOpenIntern({});
                  }}
                  {...openIntern}
                />
              )}
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
}

export default memo(PersonnelInfo);
