import React from "react";

export default class PercentageOptions extends React.PureComponent {
  clear = () =>
    this.props.onChange({ target: { name: this.props.name, value: "" } });

  render() {
    const {
      className,
      disabled,
      handleChange,
      id,
      name,
      sys: { percentageRanges },
      value,
    } = this.props;
    return (
      <div className={`radio-container ${className ? className : ""}`}>
        {percentageRanges.map((range) => (
          <React.Fragment key={range.enumValue}>
            <input
              type="radio"
              name={name}
              id={`${id}-${range.enumValue}`}
              onChange={handleChange}
              value={range.enumValue}
              checked={value === range.enumValue}
              disabled={disabled}
            />
            <label
              htmlFor={`${id}-${range.enumValue}`}
              className={value === range.enumValue ? "checked" : ""}
            >
              {range.displayValue}
            </label>
          </React.Fragment>
        ))}
      </div>
    );
  }
}
