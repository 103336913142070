import React, { useState, useEffect, useCallback } from "react";
import GaugeChart from "react-gauge-chart";

import { getVibrancyMetrics } from "../../../../state/engagement/EngagementApi";

import NoEngagementGoals from "../NoEngagementGoals";
import MonthYearsSelect from "../MonthYearsSelect";
import VibrancyMetricsBreakdownChart from "./VibrancyMetricsBreakdownChart";
import MetricsCalculations from "./MetricsCalculations";

import Loader from "../../../../components/Loader";
import { isMobileView, isSmallTabletView, isTabletView } from "../../../../lib";
import {
  getDateForMonthYearSelect,
  getValuesForMonthsYearsSelect,
} from "../shared";
import { getMonthYearLabel } from "../metrics";

const maxSelectedMonthYears = isMobileView()
  ? 2
  : isSmallTabletView()
  ? 3
  : isTabletView()
  ? 4
  : 6;

const monthYearsArray = getValuesForMonthsYearsSelect(60);

export default function VibrancyMeter(props) {
  // engagement periods are mapped in the parent component so pulling from props instead of straight from redux
  // perhaps put the mapping in a selector
  const { engagementPeriods, engagementPeriodsLoading } = props;

  const [selectedDates, setSelectedDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vibrancyMetrics, setVibrancyMetrics] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showMetricsCalculations, setShowMetricsCalculations] = useState(false);

  const getMetricsForSelectedDates = useCallback(async (values) => {
    setSelectedDates(values);
    setLoading(true);

    const { data, errorMessage } = await getVibrancyMetrics(values);
    // note that the data is returned sorted by engagement period date, ascending
    setVibrancyMetrics(data);
    setErrorMessage(errorMessage);

    setLoading(false);
  }, []);

  const getEngagementPeriodLabel = (epId) =>
    getMonthYearLabel(monthYearsArray, epId);

  useEffect(
    function defaultSelectedValue() {
      //if there is a current engagement period, default selected ep to that and get the vibrancy metrics
      const currentEngagementPeriod = engagementPeriods.find(
        (p) => p.isCurrent,
      );
      if (currentEngagementPeriod) {
        const currentValue = getDateForMonthYearSelect(
          currentEngagementPeriod.year,
          currentEngagementPeriod.month - 1,
        );
        const lastYearValue = getDateForMonthYearSelect(
          currentEngagementPeriod.year - 1,
          currentEngagementPeriod.month - 1,
        );
        getMetricsForSelectedDates([
          currentValue.toISOString(),
          lastYearValue.toISOString(),
        ]);
      }
    },
    [engagementPeriods, getMetricsForSelectedDates],
  );

  const content = () => {
    if (engagementPeriodsLoading) {
      return <Loader />;
    }

    if (!engagementPeriods || !engagementPeriods.length) {
      return <NoEngagementGoals />;
    }

    if (errorMessage) {
      return <div>{errorMessage}</div>;
    }

    return (
      <div>
        <MonthYearsSelect
          monthYearsArray={monthYearsArray}
          getColors={() => ({ dark: "#1E1E1C", light: "#F4F4F5" })}
          onChange={(vals) =>
            getMetricsForSelectedDates(vals.map((v) => v.value))
          }
          values={selectedDates}
          maxSelectedMonthYears={maxSelectedMonthYears}
        />
        <div className="vibrancy-meter-container">
          <div className="vibrancy-meter-cards-container">
            {vibrancyMetrics.map((metric, index) => {
              const label = getMonthYearLabel(
                monthYearsArray,
                metric.monthYear,
              );
              return (
                <GaugeCard
                  key={index}
                  index={index}
                  totalScore={metric.totalScore}
                  engagementPeriodLabel={label}
                />
              );
            })}
          </div>

          <VibrancyMetricsBreakdownChart
            metrics={vibrancyMetrics}
            errorMessage={errorMessage}
            loading={loading}
            getEngagementPeriodLabel={getEngagementPeriodLabel}
          />
        </div>

        <div className="mt-24">
          <div
            className="metrics-calculations-link link-text"
            onClick={() => setShowMetricsCalculations(!showMetricsCalculations)}
          >
            <p className="uppercase-text">How it's calculated</p>
            <i className="material-icons" style={{ fontSize: "18px" }}>
              {showMetricsCalculations
                ? "keyboard_arrow_up"
                : "keyboard_arrow_down"}
            </i>
          </div>

          {showMetricsCalculations && (
            <MetricsCalculations monthYearsArray={monthYearsArray} />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="full-width">{content()}</div>
    </>
  );
}

function getDisplayDataForScore(score) {
  switch (true) {
    case !score || score <= 2:
      return { label: "Quiet", color: "#FF6B6B" };
    case score <= 4:
      return { label: "Slow", color: "#FF9E57" };
    case score <= 6:
      return { label: "Vibrant", color: "#FFC602" };
    case score <= 8:
      return { label: "Very Vibrant", color: "#4FCEC5" };
    default:
      return { label: "Incredible", color: "#7C71F9" };
  }
}

function GaugeCard({ index, totalScore, engagementPeriodLabel }) {
  const scorePercent = totalScore / 10.0;
  const { label: scoreLabel, color: scoreColor } =
    getDisplayDataForScore(totalScore);

  return (
    <div className="vibrancy-meter-gauge-container">
      <GaugeChart
        id={`gauge-chart-${index}`}
        className="vibrancy-meter-gauge"
        arcsLength={[scorePercent, 1 - scorePercent]}
        colors={[scoreColor, "#F0F1F2"]}
        arcPadding={0.02}
        arcWidth={0.1}
        hideText
        // hide the needle:
        needleColor="#FFFFFF"
        needleBaseColor="#FFFFFF"
        animate={false}
        percent={0}
      />
      <div className="vibrancy-meter-gauge-text">
        <p className="vibrancy-meter-gauge-score-text">{totalScore}</p>
        <p className="vibrancy-meter-gauge-score-label-text">{scoreLabel}</p>
        <p className="vibrancy-meter-gauge-month-text">
          {engagementPeriodLabel}
        </p>
      </div>
    </div>
  );
}
