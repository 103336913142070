import React from "react";
import { FieldArray } from "formik";

import FormInput from "../../../../components/formik/FormInput";
import FormInputUpdate from "../../../../components/formik/FormInputUpdate";

import PercentageOptions from "./PercentageOptions";

export default class ShliachActivityInfo extends React.PureComponent {
  state = {
    shliachActivityInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        shliachActivityInfoShowing: false,
      });
    }
  }

  render() {
    const {
      errors,
      handleChange,
      require,
      setFieldValue,
      shliach: { person = {} },
      sys,
      sys: {
        shliachCategories,
        secondaryActivityTypes,
        demographics,
        shliachRoles,
        classFrequencies,
      },
      touched,
      touched: {
        outreach: { shliachOutreach: touchedShliachOutreach = {} } = {},
      },
      values,
      values: {
        outreach: { shliachOutreach = {} },
      },
    } = this.props;
    const shliachFirstName = person.firstName;

    return (
      <div
        className={
          this.state.shliachActivityInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-32 mb-16 flex flex-align-center flex-justify-space"
          onClick={() => {
            this.setState({
              shliachActivityInfoShowing:
                !this.state.shliachActivityInfoShowing,
            });
          }}
        >
          {shliachFirstName ? `${shliachFirstName}'s` : "Your"} Shlichus
          Activities
          <i className="material-icons">
            {this.state.shliachActivityInfoShowing
              ? "keyboard_arrow_up"
              : "keyboard_arrow_down"}
          </i>
        </p>
        <div className="profile-form-inputs profile-outreach-form-inputs">
          <div className="mb-24">
            <div className="radio-button-options">
              <FormInput
                label={`Which of these definitions best describes ${
                  shliachFirstName ? `${shliachFirstName}'s` : "your"
                } Shlichus?`}
                labelClassNames={"fw-700 accent-text-dark mb-8 block"}
                name="outreach.shliachOutreach.category"
                validate={require}
                errors={errors}
                touched={touched}
              >
                {({ field }) =>
                  shliachCategories.map((shliachCategory) => (
                    <div
                      className="radio-button-option radio-button-container mb-8"
                      key={shliachCategory.enumValue}
                    >
                      <input
                        type="radio"
                        id={`shliachCategory-${shliachCategory.enumValue}`}
                        name={field.name}
                        value={shliachCategory.enumValue}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue(
                            "outreach.shliachOutreach.secondaryActivity",
                            "",
                          );
                        }}
                        checked={field.value === shliachCategory.enumValue}
                      />
                      <label
                        htmlFor={`shliachCategory-${shliachCategory.enumValue}`}
                      >
                        {shliachCategory.value}
                      </label>
                    </div>
                  ))
                }
              </FormInput>
            </div>
          </div>
          {values &&
            shliachOutreach &&
            shliachOutreach.category === "PartTime" && (
              <div className="mb-24">
                <FormInput
                  label={`What ${
                    shliachFirstName ? `does ${shliachFirstName}` : "do you"
                  } primarily spend the rest of ${
                    shliachFirstName
                      ? person.gender === "Female"
                        ? "her"
                        : "his"
                      : "your"
                  } time on?`}
                  labelClassNames={"fw-700 accent-text-dark mb-8 block"}
                  name="outreach.shliachOutreach.secondaryActivity"
                  validate={require}
                  errors={errors}
                  touched={touched}
                >
                  {({ field }) =>
                    secondaryActivityTypes.map((activity) => (
                      <div
                        className="radio-button-container mb-8"
                        key={activity.enumValue}
                      >
                        <input
                          type="radio"
                          id={`secondaryActivity-${activity.enumValue}`}
                          name={field.name}
                          value={activity.enumValue}
                          onChange={handleChange}
                          checked={field.value === activity.enumValue}
                        />
                        <label
                          htmlFor={`secondaryActivity-${activity.enumValue}`}
                        >
                          {activity.displayValue}
                        </label>
                      </div>
                    ))
                  }
                </FormInput>
              </div>
            )}
          <div className="mb-24">
            <FormInput
              label={`What percentage of ${
                shliachFirstName ? `${shliachFirstName}'s` : "your"
              } Shlichus activities do you consider catered to college students?`}
              labelClassNames={"fw-700 accent-text-dark mb-8 block"}
              name="outreach.shliachOutreach.studentActivityPercentage"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <PercentageOptions
                  handleChange={handleChange}
                  id={"student-percentage"}
                  name={field.name}
                  sys={sys}
                  value={field.value}
                />
              )}
            </FormInput>
          </div>
          <div className="mb-24">
            {/* TODO: add validation for this field and an error class for the label */}
            <label
              className={`fw-700 accent-text-dark mb-8 block ${
                errors.shliachDemographics &&
                touchedShliachOutreach &&
                touchedShliachOutreach.demographics &&
                touchedShliachOutreach.demographics.length >= 0
                  ? "error-text"
                  : ""
              }`}
            >
              What demographics{" "}
              {shliachFirstName ? `does ${shliachFirstName}` : "do you"} serve?
            </label>
            <FieldArray name="outreach.shliachOutreach.demographics">
              {(arrayHelpers) => (
                <div className="demographics-grid">
                  <div>
                    <p className="small-text accent-text">Demographic</p>
                    <p className="small-text accent-text">
                      Percent of activities
                    </p>
                  </div>
                  {demographics &&
                    demographics.map((demographic, index) => {
                      const demographicIndex = sys.demographics.map(
                        (val) =>
                          shliachOutreach.demographics &&
                          shliachOutreach.demographics
                            .map((d) => d.demographic)
                            .indexOf(val.enumValue),
                      )[index];
                      const selectedDemographic =
                        shliachOutreach &&
                        shliachOutreach.demographics &&
                        shliachOutreach.demographics
                          .map((demographics) => demographics.demographic)
                          .includes(demographic.enumValue);
                      return (
                        <div key={demographic.enumValue}>
                          <FormInputUpdate
                            name={`outreach.shliachOutreach.demographics[${demographicIndex}].demographic`}
                          >
                            {({ field }) => (
                              <div className="flex custom-checkbox-container">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox"
                                  id={`demographics-${demographic.enumValue}`}
                                  name={field.name}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      arrayHelpers.push({
                                        demographic: demographic.enumValue,
                                      });
                                    } else {
                                      const index = shliachOutreach.demographics
                                        .map(
                                          (demographics) =>
                                            demographics.demographic,
                                        )
                                        .indexOf(demographic.enumValue);
                                      arrayHelpers.remove(index);
                                    }
                                  }}
                                  value={demographic.enumValue}
                                  checked={
                                    shliachOutreach &&
                                    shliachOutreach.demographics &&
                                    shliachOutreach.demographics
                                      .map(
                                        (demographics) =>
                                          demographics.demographic,
                                      )
                                      .includes(demographic.enumValue)
                                  }
                                />
                                <label
                                  className="small-text flex flex-align-center"
                                  htmlFor={`demographics-${demographic.enumValue}`}
                                >
                                  {demographic.displayValue}
                                </label>
                              </div>
                            )}
                          </FormInputUpdate>
                          <FormInputUpdate
                            name={`outreach.shliachOutreach.demographics[${demographicIndex}].percentageServed`}
                            validate={selectedDemographic ? require : null}
                            errors={errors}
                            touched={touched}
                          >
                            {({ field }) => {
                              return (
                                <PercentageOptions
                                  className={
                                    touchedShliachOutreach &&
                                    touchedShliachOutreach.demographics &&
                                    errors &&
                                    errors.outreach &&
                                    errors.outreach.shliachOutreach &&
                                    errors.outreach.shliachOutreach
                                      .demographics &&
                                    errors.outreach.shliachOutreach
                                      .demographics[demographicIndex] &&
                                    errors.outreach.shliachOutreach
                                      .demographics[demographicIndex]
                                      .percentageServed
                                      ? "error"
                                      : null
                                  }
                                  disabled={!selectedDemographic}
                                  handleChange={handleChange}
                                  id={demographic.enumValue}
                                  name={field.name}
                                  sys={sys}
                                  value={field.value}
                                />
                              );
                            }}
                          </FormInputUpdate>
                        </div>
                      );
                    })}
                </div>
              )}
            </FieldArray>
          </div>
          <div className="mb-24">
            <label
              className={`fw-700 accent-text-dark mb-8 block ${
                errors.shliachRoles &&
                touchedShliachOutreach &&
                touchedShliachOutreach.roles &&
                touchedShliachOutreach.roles.length >= 0
                  ? "error-text"
                  : ""
              }`}
            >
              What is {shliachFirstName ? `${shliachFirstName}'s` : "your"}{" "}
              primary role(s)?
            </label>
            <FieldArray name="outreach.shliachOutreach.roles">
              {(arrayHelpers) =>
                shliachRoles
                  ? shliachRoles.map((role, index) => (
                      <div key={role.enumValue}>
                        <FormInputUpdate
                          name={`outreach.shliachOutreach.roles[${index}]`}
                        >
                          {({ field }) => (
                            <div className="flex custom-checkbox-container mb-8">
                              <input
                                type="checkbox"
                                className="custom-checkbox"
                                id={`role-${role.enumValue}`}
                                name={field.name}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    shliachOutreach.id
                                      ? arrayHelpers.push({
                                          shliachOutreachID: shliachOutreach.id,
                                          role: role.enumValue,
                                        })
                                      : arrayHelpers.push({
                                          role: role.enumValue,
                                        });
                                  } else {
                                    const index = shliachOutreach.roles
                                      .map((roles) => roles.role)
                                      .indexOf(role.enumValue);
                                    arrayHelpers.remove(index);
                                  }
                                }}
                                value={role.enumValue}
                                checked={
                                  shliachOutreach &&
                                  shliachOutreach.roles &&
                                  shliachOutreach.roles
                                    .map((roles) => roles.role)
                                    .includes(role.enumValue)
                                }
                              />
                              <label
                                className="flex flex-align-center"
                                htmlFor={`role-${role.enumValue}`}
                              >
                                {role.displayValue}
                              </label>
                            </div>
                          )}
                        </FormInputUpdate>
                      </div>
                    ))
                  : null
              }
            </FieldArray>
          </div>
          <div className="mb-24">
            <FormInput
              label={`How often ${
                shliachFirstName ? `does ${shliachFirstName}` : "do you"
              } offer classes specifically for college students?`}
              labelClassNames={"fw-700 accent-text-dark mb-8 block"}
              name="outreach.shliachOutreach.classFrequency"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <div className="radio-container">
                  {classFrequencies.map((frequency) => (
                    <React.Fragment key={frequency.enumValue}>
                      <input
                        type="radio"
                        name={field.name}
                        id={`class-frequency-${frequency.enumValue}`}
                        onChange={handleChange}
                        value={frequency.enumValue}
                        checked={field.value === frequency.enumValue}
                      />
                      <label htmlFor={`class-frequency-${frequency.enumValue}`}>
                        {frequency.displayValue}
                      </label>
                    </React.Fragment>
                  ))}
                </div>
              )}
            </FormInput>
          </div>
          <div className="mb-24">
            <FormInput
              label={`Anything else we should know about ${
                shliachFirstName ? `${shliachFirstName}'s` : "your"
              } Shlichus as it relates to campus? (optional)`}
              labelClassNames={"fw-700 accent-text-dark mb-8 block"}
              name="outreach.shliachOutreach.otherInfo"
              touched={touched}
            >
              {({ field }) => (
                <textarea
                  className="custom-input"
                  name={field.name}
                  onChange={handleChange}
                  style={{ resize: "none", height: "80px", padding: "8px" }}
                  value={field.value}
                />
              )}
            </FormInput>
          </div>
        </div>
      </div>
    );
  }
}
