import React from "react";
import { FieldArray } from "formik";
import NumberFormat from "react-number-format";

import FormInput from "../../../../components/formik/FormInput";
import FormInputUpdate from "../../../../components/formik/FormInputUpdate";

export default class ActivitiesInfo extends React.PureComponent {
  state = {
    activitiesInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        activitiesInfoShowing: false,
      });
    }
  }

  render() {
    const {
      errors,
      handleChange,
      require,
      sys: { friDinnerFrequencies, holidays },
      touched,
      validateHolidayEvents,
      values,
    } = this.props;
    return (
      <div
        className={
          this.state.activitiesInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-32 mb-16 flex flex-align-center flex-justify-space"
          onClick={() => {
            this.setState({
              activitiesInfoShowing: !this.state.activitiesInfoShowing,
            });
          }}
        >
          {values.name} Activities
          <i className="material-icons">
            {this.state.activitiesInfoShowing
              ? "keyboard_arrow_up"
              : "keyboard_arrow_down"}
          </i>
        </p>
        <div className="profile-form-inputs profile-activities-form-inputs">
          <div className="mb-24">
            <FormInput
              label=" How often does your Chabad House host Friday night dinner primarily for college students?"
              labelClassNames={"fw-700 accent-text-dark mb-8 block"}
              name="friDinnerFrequency"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) =>
                friDinnerFrequencies.map((frequency) => (
                  <div
                    className="radio-button-container mb-8"
                    key={frequency.enumValue}
                  >
                    <input
                      type="radio"
                      id={`friDinnerFrequency-${frequency.enumValue}`}
                      name={field.name}
                      value={frequency.enumValue}
                      onChange={handleChange}
                      checked={field.value === frequency.enumValue}
                    />
                    <label
                      htmlFor={`friDinnerFrequency-${frequency.enumValue}`}
                    >
                      {frequency.displayValue}
                    </label>
                  </div>
                ))
              }
            </FormInput>
          </div>
          <div className="mb-24">
            <FormInput
              label="How many students come to your Chabad House on an average Friday night?"
              labelClassNames={"fw-700 accent-text-dark mb-8 block"}
              name="numberStudentsFriNight"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => {
                return (
                  <NumberFormat
                    thousandSeparator={true}
                    decimalScale={2}
                    {...field}
                    className="custom-input"
                  />
                );
              }}
            </FormInput>
          </div>
          <div className="mb-24">
            <label
              className={`fw-700 accent-text-dark mb-8 block ${
                errors.holidayEvents && touched.holidayEvents && "error"
              }`}
            >
              Does your Chabad House run holiday events specifically for college
              students?
            </label>
            <FieldArray name="holidayEvents" validate={validateHolidayEvents}>
              {(arrayHelpers) =>
                holidays &&
                holidays.map((holiday, index) => (
                  <div key={holiday.enumValue}>
                    <FormInputUpdate name={`holidayEvents[${index}]`}>
                      {({ field }) => (
                        <div className="flex custom-checkbox-container mb-8">
                          <input
                            type="checkbox"
                            className="custom-checkbox"
                            id={`holidayEvent-${holiday.enumValue}`}
                            name={field.name}
                            onChange={(e) => {
                              if (e.target.checked)
                                arrayHelpers.push({
                                  chabadHouseID: values.id,
                                  holiday: holiday.enumValue,
                                });
                              else {
                                const index = values.holidayEvents
                                  .map((event) => event.holiday)
                                  .indexOf(holiday.enumValue);
                                arrayHelpers.remove(index);
                              }
                            }}
                            value={holiday.enumValue}
                            checked={
                              values &&
                              values.holidayEvents &&
                              values.holidayEvents
                                .map((event) => event.holiday)
                                .includes(holiday.enumValue)
                            }
                          />
                          <label
                            className="flex flex-align-center"
                            htmlFor={`holidayEvent-${holiday.enumValue}`}
                          >
                            {holiday.displayValue}
                          </label>
                        </div>
                      )}
                    </FormInputUpdate>
                  </div>
                ))
              }
            </FieldArray>
          </div>
        </div>
      </div>
    );
  }
}
