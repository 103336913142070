import React from "react";

import { FieldArray } from "formik";
import CustomSelect from "../../../components/formik/CustomSelect";
import FormInput from "../../../components/formik/FormInput";

export default class StudiesInfo extends React.PureComponent {
  state = {
    studiesInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        studiesInfoShowing: false,
      });
    }
  }

  render() {
    const {
      errors,
      require,
      setFieldValue,
      touched,
      values,
      sys: { studyAreas, schoolRankingSource },
    } = this.props;
    return (
      <div
        className={
          this.state.studiesInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <FieldArray name="studyAreas">
          {(arrayHelpers) => {
            return (
              <React.Fragment>
                <div className="flex mobile-flex-justify-space flex-align-center mt-48">
                  <div className="flex flex-align-center">
                    <p className="accent-text-dark medium-text fw-700">
                      Schools Studies
                    </p>
                    <i
                      className={`material-icons link-text profile-add-icon ml-16 ${
                        !this.state.studiesInfoShowing ? "hidden" : ""
                      }`}
                      onClick={() => {
                        arrayHelpers.unshift({
                          studyArea: "",
                          rank: "",
                          source: "",
                        });
                      }}
                    >
                      add_circle
                    </i>
                  </div>
                  <i
                    className="material-icons collapsible"
                    onClick={() => {
                      this.setState({
                        studiesInfoShowing: !this.state.studiesInfoShowing,
                      });
                    }}
                  >
                    {this.state.studiesInfoShowing
                      ? "keyboard_arrow_up"
                      : "keyboard_arrow_down"}
                  </i>
                </div>
                <div className="profile-form-inputs profile-form-studies-info mt-8">
                  {values.studyAreas && values.studyAreas.length > 0 ? (
                    <div className="flex mb-16 mt-12 mobile-hidden profile-form-studies-info-grid">
                      <p className="accent-text small-text study-area">
                        Area of Study
                      </p>
                      <p className="accent-text small-text">Rank</p>
                      <p className="accent-text small-text">Source</p>
                      <p />
                    </div>
                  ) : null}
                  {values.studyAreas &&
                    values.studyAreas.map((studyArea, index) => (
                      <div
                        key={index}
                        className="flex flex-align-center flex-justify-space mb-8 hover-delete-container mobile-card"
                      >
                        <div
                          className="profile-form-studies-info-grid"
                          style={{ width: "100%" }}
                        >
                          <div className="relative">
                            <FormInput
                              label="Area of Study"
                              name={`studyAreas[${index}].studyArea`}
                              validate={require}
                              errors={errors}
                              touched={touched}
                              className="custom-input mr-12"
                              style={{ flex: "2" }}
                            >
                              {({ field }) => (
                                <CustomSelect
                                  placeholder="Select..."
                                  errors={errors}
                                  touched={touched}
                                  {...field}
                                  options={studyAreas.map((s) => ({
                                    key: s.enumValue,
                                    value: s.enumValue,
                                    label: s.displayValue,
                                  }))}
                                  setFieldValue={setFieldValue}
                                />
                              )}
                            </FormInput>
                          </div>
                          <div className="relative">
                            <FormInput
                              label="Rank"
                              type="number"
                              name={`studyAreas[${index}].rank`}
                              errors={errors}
                              touched={touched}
                              className="custom-input mr-24"
                              style={{ flex: "3" }}
                              validate={require}
                            />
                          </div>
                          <div className="relative">
                            <FormInput
                              label="Source"
                              name={`studyAreas[${index}].source`}
                              validate={require}
                              errors={errors}
                              touched={touched}
                              className="custom-input mr-12"
                              style={{ flex: "2" }}
                            >
                              {({ field }) => (
                                <CustomSelect
                                  placeholder="Select Source..."
                                  errors={errors}
                                  touched={touched}
                                  {...field}
                                  options={schoolRankingSource.map((s) => ({
                                    key: s.enumValue,
                                    value: s.enumValue,
                                    label: s.displayValue,
                                  }))}
                                  setFieldValue={setFieldValue}
                                />
                              )}
                            </FormInput>
                          </div>
                          <i
                            className="material-icons link-text hover-delete"
                            style={{ fontSize: "16px" }}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            delete
                          </i>
                        </div>
                      </div>
                    ))}
                </div>
              </React.Fragment>
            );
          }}
        </FieldArray>
      </div>
    );
  }
}
