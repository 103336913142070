import React from "react";
import { formatNumber, isDateEqual, pluralizeText } from "../../../../lib";
import { InteractionSettingTypes } from "../shared";

export {
  CustomBarLabel,
  CustomGoalBarLabel,
  CustomTooltip,
  GoalCustomTooltip,
  InteractionGoalCustomBarLabel,
  InteractionCustomBarLabel,
  formatStudentMetricsTickY,
  getInteractionDataKey,
  getMetricDetailsAccessor,
  getEngagementPeriodLabel,
  getMonthYearLabel,
};

const getMetricDetailsAccessor = (id: string | number) => `${id}-details`;

const getInteractionDataKey = (
  id: string | number,
  interactionSetting?: string,
) => `${id}-${interactionSetting || "all"}`;

function BarLabel(props: any) {
  const {
    colors,
    dx,
    height,
    interactionSetting,
    showInteractionSetting,
    x,
    y,
    total,
    excess,
  } = props;
  if (!total) {
    return null;
  }

  const fontSize = 10;
  return (
    <text
      dx={dx}
      dy={height / 2 + fontSize / 3}
      fill={colors.dark}
      fontSize={fontSize}
      fontFamily="sans-serif"
      pointerEvents="none"
      textAnchor="start"
      x={x ? x : ""}
      y={y}
    >
      {total} {showInteractionSetting ? interactionSetting : ""}
      {excess}
    </text>
  );
}

function CustomGoalBarLabel(props: any) {
  const { data, id, index } = props;
  const { goal = 0, goalProgress = 0 } =
    data[index][getMetricDetailsAccessor(id)] || {};
  const goalProgressPercentage = data[index][id];
  const goalProgressExcess = goalProgress > goal ? goalProgress - goal : 0;
  if (!goal) {
    return null;
  }
  return (
    <BarLabel
      dx={`${goalProgressPercentage ? goalProgressPercentage / 2 : 1}%`}
      total={`${goalProgress - goalProgressExcess}/${goal}`}
      excess={goalProgressExcess ? ` (+${goalProgressExcess})` : ""}
      {...props}
    />
  );
}

function CustomBarLabel(props: any) {
  const { data, id, index, width } = props;
  const { total = 0 } = data[index][getMetricDetailsAccessor(id)] || {};
  if (!total) return null;
  return <BarLabel dx={width / 2} total={total} {...props} />;
}

const GoalCustomTooltip = ({ payload, tooltipPeriodId }: any) => {
  if (!tooltipPeriodId || !payload?.length || !payload[0].payload) {
    return "";
  }

  const goalsData =
    payload[0].payload[getMetricDetailsAccessor(tooltipPeriodId)];
  if (!goalsData) {
    return "";
  }

  const { goalsByType } = goalsData;
  if (!goalsByType?.length) {
    return "";
  }

  let tooltipDescription = "";
  goalsByType
    .filter((goal: any) => {
      return !!goal.goal;
    })
    .forEach((g: any, index: number) => {
      const {
        goal,
        goalProgress,
        interactionType,
        individualTotal,
        communalTotal,
      } = g;
      tooltipDescription += `${index ? "\n" : ""}${interactionType}: `;
      const goalExcess = goalProgress > goal ? goalProgress - goal : 0;
      tooltipDescription += `${formatNumber(
        goalProgress - goalExcess,
      )}/${formatNumber(goal)} ${goalExcess ? ` (+${goalExcess})` : ""}`;
      if (individualTotal || communalTotal) {
        tooltipDescription += " (";
        if (individualTotal) {
          tooltipDescription += `${individualTotal} Individual`;
        }
        if (individualTotal && communalTotal) {
          tooltipDescription += " & ";
        }
        if (communalTotal) {
          tooltipDescription += `${communalTotal} Communal`;
        }
        tooltipDescription += ")";
      }
    });

  if (!tooltipDescription) {
    return "";
  }

  return (
    <div className="engagement-metrics-chart-tooltip">{tooltipDescription}</div>
  );
};
const CustomTooltip = ({
  interactionSettingFilter,
  payload,
  tooltipId,
}: any) => {
  if (!tooltipId || !payload?.length || !payload[0].payload) {
    return "";
  }

  const data = payload[0].payload[getMetricDetailsAccessor(tooltipId)];
  if (!data) return "";

  const { metricsByType } = data;
  if (!metricsByType?.length) return "";

  let tooltipDescription = "";

  metricsByType
    .filter((metric: any) => {
      if (interactionSettingFilter === InteractionSettingTypes.Individual) {
        return !!metric.individualTotal;
      } else if (
        interactionSettingFilter === InteractionSettingTypes.Communal
      ) {
        return !!metric.communalTotal;
      }
      return !!(metric.individualTotal || metric.communalTotal);
    })
    .forEach((metric: any, index: number) => {
      const { individualTotal, communalTotal, interactionType } = metric;

      const displayIndividualTotal =
        individualTotal &&
        (!interactionSettingFilter ||
          interactionSettingFilter === InteractionSettingTypes.Individual);
      const displayCommunalTotal =
        communalTotal &&
        (!interactionSettingFilter ||
          interactionSettingFilter === InteractionSettingTypes.Communal);

      tooltipDescription += `${index ? "\n" : ""}${interactionType}: `;
      if (displayIndividualTotal) {
        tooltipDescription += `${formatNumber(individualTotal)} Individual`;
      }
      if (metric.individualUnknownStudentsTotal) {
        tooltipDescription += ` (+ ${formatNumber(
          metric.individualUnknownStudentsTotal,
        )} unspecified ${pluralizeText(
          "Attendee",
          metric.individualUnknownStudentsTotal,
        )})`;
      }
      if (displayIndividualTotal && displayCommunalTotal) {
        tooltipDescription += " & ";
      }
      if (displayCommunalTotal) {
        tooltipDescription += `${formatNumber(communalTotal)} Communal`;
      }
      if (metric.communalUnknownStudentsTotal) {
        tooltipDescription += ` (+ ${formatNumber(
          metric.communalUnknownStudentsTotal,
        )} unspecified ${pluralizeText(
          "Attendee",
          metric.communalUnknownStudentsTotal,
        )})`;
      }
    });

  if (!tooltipDescription) {
    return "";
  }

  return (
    <div className="engagement-metrics-chart-tooltip">{tooltipDescription}</div>
  );
};

function InteractionGoalCustomBarLabel(props: any) {
  const { colors, data, dataKey, height, index, interactionSetting, id, x, y } =
    props;

  const { goal = 0, goalProgress = 0 } =
    data[index][getMetricDetailsAccessor(id)] || {};
  const goalProgressPercentage = data[index][dataKey];
  if (!goal) {
    return null;
  }
  const fontSize = 10;
  return (
    <text
      dx={`${goalProgressPercentage ? goalProgressPercentage / 2 : 1}%`}
      dy={height / 2 + fontSize / 3}
      fill={colors.dark}
      fontSize={fontSize}
      fontFamily="sans-serif"
      pointerEvents="none"
      textAnchor="start"
      x={x ? x : ""}
      y={y}
    >
      {goalProgress}/{goal}
    </text>
  );
}
function InteractionCustomBarLabel(props: any) {
  const {
    colors,
    data,
    width,
    height,
    index,
    interactionSetting,
    id,
    showInteractionSetting,
    x,
    y,
  } = props;

  const {
    communalTotal = 0,
    individualTotal = 0,
    individualUnknownStudentsTotal = 0,
    communalUnknownStudentsTotal = 0,
  } = data[index][getMetricDetailsAccessor(id)] || {};

  const total =
    interactionSetting === InteractionSettingTypes.Individual
      ? individualTotal
      : communalTotal;

  const totalUnknownAttendees =
    interactionSetting === InteractionSettingTypes.Individual
      ? individualUnknownStudentsTotal
      : communalUnknownStudentsTotal;

  if (!total) {
    return null;
  }
  const fontSize = 10;
  return (
    <text
      dx={width / 2}
      dy={height / 2 + fontSize / 3}
      fill={colors.dark}
      fontSize={fontSize}
      fontFamily="sans-serif"
      pointerEvents="none"
      textAnchor="start"
      x={x ? x : ""}
      y={y}
    >
      {total} {showInteractionSetting ? interactionSetting : ""}{" "}
      {totalUnknownAttendees
        ? ` (+ ${totalUnknownAttendees} unspecified ${pluralizeText(
            "attendee",
            totalUnknownAttendees,
          )})`
        : ""}
    </text>
  );
}

const formatStudentMetricsTickY = (
  jewishInterestLevels: any,
  key: any,
  data: any,
) => {
  const levelDisplay =
    jewishInterestLevels.find((l: any) => l.enumValue === key)?.displayValue ||
    "";
  const total = data.find((d: any) => d.jewishInterestLevel === key)?.total;

  return `${levelDisplay}\nTotal: ${formatNumber(total)}`;
};

const getEngagementPeriodLabel = (
  epId: number,
  allEngagementPeriods: any[],
) => {
  const { monthDisplay = "", year = "" } =
    allEngagementPeriods.find((ep) => ep.id === epId) || {};
  return `${monthDisplay} ${year}`;
};

const getMonthYearLabel = (monthYearsArray: any[], monthYear: string) => {
  return monthYearsArray.find((e) => isDateEqual(e.value, monthYear))?.label;
};
