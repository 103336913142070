import React from "react";

import { FieldArray } from "formik";
import CustomSelect from "../../../../components/formik/CustomSelect";
import FormInput from "../../../../components/formik/FormInput";
import FormInputHebrew from "../../../../components/formik/FormInputHebrew";
import FormInputUpdate from "../../../../components/formik/FormInputUpdate";
import ImageUpload from "../../../../components/formik/ImageWithCropUpload";
import { isNotValidDOB } from "../../../../lib";

export default class FamilyInfo extends React.PureComponent {
  state = {
    familyInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        familyInfoShowing: false,
      });
    }
  }

  addressLabelError = (touched, errors) => {
    return (
      touched &&
      touched.family &&
      touched.family.address &&
      touched.family.address.address1 === true &&
      touched.family.address.country === true &&
      touched.family.address.city === true &&
      touched.family.address.state === true &&
      touched.family.address.zip === true &&
      errors &&
      errors.family &&
      errors.family.address &&
      (errors.family.address.address1 === true ||
        errors.family.address.country === true ||
        errors.family.address.city === true ||
        errors.family.address.state === true ||
        errors.family.address.zip === true)
    );
  };

  render() {
    const {
      errors,
      handleChange,
      hasUSAddress,
      require,
      setFieldValue,
      touched,
      values,
      values: { family: { address: { country } = {} } = {} },
      sys: { countries, schoolTypes, youthAndAdultTShirtSizes },
      ui: { isPhone },
    } = this.props;

    const addressLabelError = this.addressLabelError(touched, errors);

    return (
      <div
        className={
          this.state.familyInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-32 flex flex-align-center flex-justify-space full-width"
          onClick={() => {
            this.setState({
              familyInfoShowing: !this.state.familyInfoShowing,
            });
          }}
        >
          Family Information
          <i className="material-icons">
            {this.state.familyInfoShowing
              ? "keyboard_arrow_up"
              : "keyboard_arrow_down"}
          </i>
        </p>
        <div className="profile-form-family-info mt-16">
          <ImageUpload
            errors={errors}
            name="family.familyImageURL"
            label="Upload Family Image"
            uploadedLabel="Change Family Image"
            onChange={(name, value) =>
              handleChange({ target: { name, value } })
            }
            imageName="Family Image"
            imageType="family"
            imageURL={values.family ? values.family.familyImageURL : ""}
            touched={touched}
            person={true}
          />

          <div className="mt-24">
            <label
              className={`accent-text fw-700 ${
                addressLabelError ? "error" : ""
              }`}
            >
              Home Address
            </label>
            <div className="mt-16 full-width">
              <div className="relative">
                <FormInput
                  type="text"
                  name="family.address.address1"
                  validate={require}
                  errors={errors}
                  touched={touched}
                  className="mb-8 full-width"
                />
              </div>
              <div className="relative">
                <FormInput
                  type="text"
                  name="family.address.address2"
                  errors={errors}
                  touched={touched}
                  className="mb-8 full-width"
                  placeholder="Apt #, Suite, Floor"
                />
              </div>
              <div className="address-grid">
                <div className="relative">
                  <FormInputUpdate
                    name="family.address.country"
                    validate={require}
                    errors={errors}
                    touched={touched}
                    className="mr-8 full-width"
                  >
                    {({ field }) => (
                      <CustomSelect
                        {...field}
                        errors={errors}
                        options={
                          countries &&
                          countries.map((c) => ({
                            key: c.name,
                            value: c.name,
                            label: c.name,
                          }))
                        }
                        placeholder="Select Country..."
                        setFieldValue={setFieldValue}
                        touched={touched}
                      />
                    )}
                  </FormInputUpdate>
                </div>
                <div className="relative">
                  <FormInput
                    type="text"
                    name="family.address.city"
                    validate={require}
                    errors={errors}
                    touched={touched}
                    className="mr-8 full-width"
                    placeholder="City"
                  />
                </div>
                <div className="relative">
                  <FormInputUpdate
                    name="family.address.state"
                    validate={hasUSAddress(values.family) ? require : null}
                    errors={errors}
                    touched={touched}
                    className="mr-8 full-width"
                  >
                    {({ field }) =>
                      country === "USA" ||
                      country === "Canada" ||
                      country === "Mexico" ? (
                        <CustomSelect
                          {...field}
                          errors={errors}
                          isClearable={true}
                          options={(
                            (countries &&
                              countries
                                .filter((c) => c.name === country)
                                .map((c) => c.states)[0]) ||
                            []
                          ).map((s) => ({
                            key: s.code,
                            value: s.name,
                            label: s.name,
                          }))}
                          placeholder="Select State/Region..."
                          setFieldValue={setFieldValue}
                          touched={touched}
                        />
                      ) : (
                        <FormInputUpdate
                          name="family.address.state"
                          type="text"
                          placeholder="State/Region"
                          validate={
                            hasUSAddress(values.family) ? require : null
                          }
                          errors={errors}
                          touched={touched}
                          className="mr-8 full-width"
                        />
                      )
                    }
                  </FormInputUpdate>
                </div>
                <div className="relative">
                  <FormInput
                    type="text"
                    name="family.address.zip"
                    validate={hasUSAddress(values.family) ? require : null}
                    errors={errors}
                    touched={touched}
                    placeholder="Zip"
                    className="full-width"
                  />
                </div>
              </div>
            </div>
          </div>

          <FieldArray name="family.children">
            {(arrayHelpers) => (
              <React.Fragment>
                <p className="mt-24 mb-16 accent-text fw-700 flex flex-align-center">
                  Children
                  <i
                    className="material-icons link-text profile-add-icon ml-16"
                    onClick={() =>
                      arrayHelpers.unshift({
                        person: {
                          firstName: "",
                          lastName: values.person.lastName,
                          dob: "",
                          beforeNightfall: "",
                          gender: "",
                          hebrewName: "",
                          hebrewDOB: "",
                        },
                        schoolType: "",
                        tShirtSize: "",
                      })
                    }
                  >
                    add_circle
                  </i>
                </p>
                {values.family &&
                  values.family.children &&
                  values.family.children.map((child, index) => (
                    <div
                      key={index}
                      className="flex flex-justify-space flex-align-center child-row-container hover-delete-container"
                    >
                      <div className="child-row">
                        <div>
                          <FormInput
                            label="First Name"
                            type="text"
                            name={`family.children[${index}].person.firstName`}
                            validate={require}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div>
                          <FormInput
                            label="Gender"
                            name={`family.children[${index}].person.gender`}
                            validate={require}
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-12"
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <CustomSelect
                                {...field}
                                errors={errors}
                                options={[
                                  {
                                    key: "1",
                                    value: "Male",
                                    label: "Male",
                                  },
                                  {
                                    key: "2",
                                    value: "Female",
                                    label: "Female",
                                  },
                                ]}
                                placeholder="Select..."
                                setFieldValue={setFieldValue}
                                touched={touched}
                              />
                            )}
                          </FormInput>
                        </div>
                        <div>
                          <FormInputHebrew
                            label="Hebrew Name"
                            type="text"
                            name={`family.children[${index}].person.hebrewName`}
                            validate={require}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            isPhone={isPhone}
                          />
                        </div>
                        <div>
                          <FormInput
                            label="Birthday"
                            type="date"
                            name={`family.children[${index}].person.dob`}
                            validate={(date) =>
                              require(date) || isNotValidDOB(date)
                            }
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div>
                          <FormInput
                            label="Nightfall/Sunset"
                            name={`family.children[${index}].person.beforeNightfall`}
                            validate={require}
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-12"
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <CustomSelect
                                {...field}
                                errors={errors}
                                options={[
                                  {
                                    key: "1",
                                    value: true,
                                    label: "Before",
                                  },
                                  {
                                    key: "2",
                                    value: false,
                                    label: "After",
                                  },
                                ]}
                                placeholder="Select..."
                                setFieldValue={setFieldValue}
                                touched={touched}
                              />
                            )}
                          </FormInput>
                        </div>

                        <div>
                          <FormInput
                            label={
                              <div className="flex flex-align-center accent-text small-text">
                                <label style={{ width: 94 }}>
                                  Hebrew Birthday
                                </label>
                                <span className="tooltip-container mt-4 ml-4 mobile-mt-0">
                                  <i
                                    className="material-icons link-text no-hover profile-add-icon"
                                    style={{ fontSize: 14 }}
                                  >
                                    info
                                  </i>
                                  <span
                                    className="tooltip"
                                    style={{ textTransform: "none" }}
                                  >
                                    Hebrew Birthday is calculated based on
                                    Birthday and Nightfall/Sunset. It will
                                    update on save.
                                  </span>
                                </span>
                              </div>
                            }
                            type="text"
                            readOnly={true}
                            name={`family.children[${index}].person.hebrewDOB`}
                            value={
                              values.family.children[index].person.hebrewDOB
                            }
                          />
                        </div>

                        <div>
                          <FormInput
                            label="School Type"
                            name={`family.children[${index}].schoolType`}
                            validate={require}
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-12"
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <CustomSelect
                                {...field}
                                errors={errors}
                                options={
                                  schoolTypes &&
                                  schoolTypes.map((t) => ({
                                    key: t.enumValue,
                                    value: t.enumValue,
                                    label: t.displayValue,
                                  }))
                                }
                                placeholder="Select..."
                                setFieldValue={setFieldValue}
                                touched={touched}
                              />
                            )}
                          </FormInput>
                        </div>
                        <div>
                          <FormInput
                            label="T-shirt Size"
                            name={`family.children[${index}].tShirtSize`}
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-12"
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <CustomSelect
                                {...field}
                                errors={errors}
                                options={
                                  youthAndAdultTShirtSizes &&
                                  youthAndAdultTShirtSizes.map((t) => ({
                                    key: t.enumValue,
                                    value: t.enumValue,
                                    label: t.displayValue,
                                  }))
                                }
                                placeholder="Select..."
                                setFieldValue={setFieldValue}
                                touched={touched}
                                isClearable
                              />
                            )}
                          </FormInput>
                        </div>
                      </div>
                      <i
                        className="material-icons link-text ml-16 mb-16 hover-delete"
                        style={{ fontSize: "16px" }}
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        delete
                      </i>
                    </div>
                  ))}
              </React.Fragment>
            )}
          </FieldArray>
        </div>
      </div>
    );
  }
}
