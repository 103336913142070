import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { PlaceOutlined } from "@material-ui/icons";
import { Address, Country } from "../../../lib/types";
import { loadScript } from "../../../lib";
import { useSelector } from "react-redux";
import { SystemSelectors } from "../../../state";
import { TextInput, TextInputProps } from "./TextInput";
//@ts-ignore
import debounce from "lodash.debounce";

const GOOGLE_MAPS_API_KEY = "AIzaSyBUUhBeNNMuZiNJDYh91-NnTT3BRq1Ohaw";

const autocompleteService = { current: null };
const placesService = { current: null };

interface GoogleAutocompleteResult {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

interface GooglePlaceResult {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
}

const addressFieldPlaceTypes = {
  homeNumber: ["street_number"],
  street: ["street_address", "route"],
  state: ["administrative_area_level_1"],
  city: [
    "locality",
    "sublocality",
    "sublocality_level_1",
    "neighborhood",
    "administrative_area_level_3",
    "postal_town",
  ],
  country: ["country"],
  zip: ["postal_code"],
};

function getAddressComponentByTypes(place: GooglePlaceResult, types: string[]) {
  for (const type of types) {
    const component = place.address_components.find((c) =>
      c.types.includes(type),
    );
    if (component) {
      return component.short_name;
    }
  }

  return "";
}

function googlePlaceToAddress(place: GooglePlaceResult, countries: Country[]) {
  const address: Address = {
    address1: [
      getAddressComponentByTypes(place, addressFieldPlaceTypes.homeNumber),
      getAddressComponentByTypes(place, addressFieldPlaceTypes.street),
    ]
      .filter((a) => a)
      .join(" "),
    address2: "",
    city: getAddressComponentByTypes(place, addressFieldPlaceTypes.city),
    country: getAddressComponentByTypes(place, addressFieldPlaceTypes.country),
    state: getAddressComponentByTypes(place, addressFieldPlaceTypes.state),
    zip: getAddressComponentByTypes(place, addressFieldPlaceTypes.zip),
  };

  // set long name for country and state
  if (address.country) {
    const country = countries.find((c) => c.code === address.country);
    address.country = country?.name || "";
    address.state =
      country?.states.find((s) => s.code === address.state)?.name || "";
  }

  return address;
}

const PoweredByGoogleListBox = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent({ children, ...props }, ref) {
  return (
    <div ref={ref} {...props}>
      {children}
      <Typography
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "8px 8px 0px 8px",
        }}
        variant="body2"
      >
        Powered by Google
        {/* TODO: add Google icon */}
      </Typography>
    </div>
  );
});

export type GoogleAddressInputProps = {
  name: string;
  onChange: (name: string, val: any) => void;
  onSelect: (value: Address) => void;
  value: string | null;
} & Omit<TextInputProps, "onChange" | "onSelect">;

export const GoogleAddressInput = React.memo(
  forwardRef(
    /**
     *
     */
    function GoogleAddressInput(
      {
        name,
        onChange,
        onSelect,
        value,
        ...passProps
      }: GoogleAddressInputProps,
      ref: React.Ref<any> | null,
    ) {
      const countries = useSelector(SystemSelectors.countries);

      const [options, setOptions] = useState<GoogleAutocompleteResult[]>([]);
      const [selectedOption, setSelectedOption] =
        useState<GoogleAutocompleteResult | null>(null);

      const loaded = useRef(false);
      if (typeof window !== "undefined" && !loaded.current) {
        if (!document.querySelector("#google-maps")) {
          loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&loading=async`,
          );
        }
        loaded.current = true;
      }

      const fetchAutocompletePredictions = useMemo(
        () =>
          debounce(
            (
              request: { input: string },
              callback: (results?: readonly GoogleAutocompleteResult[]) => void,
            ) => {
              (autocompleteService.current as any).getPlacePredictions(
                request,
                callback,
              );
            },
            400,
          ),
        [],
      );

      useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google?.maps) {
          autocompleteService.current =
            new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
          return undefined;
        }

        if (!value) {
          setOptions(selectedOption ? [selectedOption] : []);
          return undefined;
        }

        fetchAutocompletePredictions(
          { input: value },
          (results?: GoogleAutocompleteResult[]) => {
            if (active) {
              let newOptions: GoogleAutocompleteResult[] = [];

              if (selectedOption) {
                newOptions = [
                  selectedOption,
                  ...(results || []).filter(
                    (r) => r.description !== selectedOption.description,
                  ),
                ];
              } else if (results) {
                newOptions = results;
              }

              setOptions(newOptions);
            }
          },
        );

        return () => {
          active = false;
        };
      }, [fetchAutocompletePredictions, selectedOption, value]);

      const handleChange = useCallback(
        async (_, selection: GoogleAutocompleteResult | string | null) => {
          if (typeof selection === "string") {
            return;
          }

          setOptions(selection ? [selection, ...options] : options);

          if (!placesService.current && window.google?.maps) {
            placesService.current = new window.google.maps.places.PlacesService(
              document.createElement("div"),
            );
          }
          if (!placesService.current) {
            return undefined;
          }

          if (!selection) {
            setSelectedOption(null);
            return undefined;
          }

          (placesService.current as any).getDetails(
            { placeId: selection.place_id, fields: ["address_components"] },
            (result?: GooglePlaceResult) => {
              setSelectedOption(result ? selection : null);
              if (result) onSelect(googlePlaceToAddress(result, countries));
            },
          );
        },
        [countries, onSelect, options],
      );

      return (
        <Autocomplete
          autoComplete
          filterOptions={(x) => x}
          filterSelectedOptions
          forcePopupIcon={false}
          freeSolo={true}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.description
          }
          includeInputInList
          ListboxComponent={PoweredByGoogleListBox}
          noOptionsText="No locations"
          onChange={handleChange}
          onInputChange={(_, newInputValue) => onChange(name, newInputValue)}
          options={options}
          ref={ref}
          renderInput={(params) => <TextInput {...params} {...passProps} />}
          renderOption={(option) => (
            <Grid container alignItems="center">
              <Grid item style={{ display: "flex", width: 44 }}>
                <PlaceOutlined />
              </Grid>
              <Grid
                item
                style={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                <Box component="span">
                  {option.structured_formatting.main_text}
                </Box>
                <Typography variant="body2">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          )}
          value={value}
        />
      );
    },
  ),
);
