import React from "react";

import { FieldArray } from "formik";
import CustomSelect from "../../../../components/formik/CustomSelect";
import FormInput from "../../../../components/formik/FormInput";
import RadioContainer from "../../../../components/formik/RadioContainer";

export default class SchoolsInfo extends React.PureComponent {
  state = {
    schoolsInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        schoolsInfoShowing: false,
      });
    }
  }

  schoolsTouched = (touched) => {
    return (
      touched &&
      touched.campuses &&
      touched.campuses.map((c) => c && c.isPrimary).indexOf(true) >= 0
    );
  };

  render() {
    const {
      errors,
      handleChange,
      require,
      setFieldValue,
      sys: { distances },
      touched,
      values,
    } = this.props;
    const schoolsTouched = this.schoolsTouched(touched);
    return (
      <div
        className={
          this.state.schoolsInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <FieldArray name="campuses">
          <>
            <div className="flex flex-align-center mt-48 mb-16 mobile-flex-justify-space relative">
              <div className="flex flex-align-center">
                <p className="accent-text-dark medium-text fw-700">
                  Associated Schools
                  {errors.noPrimaryCampus && schoolsTouched ? (
                    <span
                      className="error-message small-text fw-400"
                      style={{ top: "20px", left: "0", fontSize: "12px" }}
                    >
                      One primary school is required.
                    </span>
                  ) : null}
                </p>
                <div className="tooltip-container">
                  <i
                    className={`material-icons link-text no-hover profile-add-icon ml-16 ${
                      !this.state.schoolsInfoShowing ? "hidden" : ""
                    }`}
                  >
                    info
                  </i>
                  <span className="tooltip">
                    To add/remove a school, contact help@chabadoncampus.org
                  </span>
                </div>
              </div>
              <i
                className="material-icons collapsible"
                onClick={() => {
                  this.setState({
                    schoolsInfoShowing: !this.state.schoolsInfoShowing,
                  });
                }}
              >
                {this.state.schoolsInfoShowing
                  ? "keyboard_arrow_up"
                  : "keyboard_arrow_down"}
              </i>
            </div>
            {this.state.schoolsInfoShowing &&
            values.campuses &&
            values.campuses.length > 0 ? (
              <p
                className="small-text accent-text desktop-hidden tablet-hidden"
                style={{ margin: "0 0 24px 0", padding: "0" }}
              >
                Schools associated with this chabad house
              </p>
            ) : null}
            <div className="profile-form-associated-schools-info profile-form-inputs">
              {values.campuses && values.campuses.length > 0 ? (
                <div>
                  <p className="accent-text small-text">
                    Schools associated with this chabad house
                  </p>
                  <p className="accent-text small-text text-center">Primary</p>
                  <div className="flex flex-align-center">
                    <p className="accent-text small-text">
                      Full / Limited Service{" "}
                    </p>
                    <div className="tooltip-container flex">
                      <i
                        className={`material-icons link-text no-hover profile-add-icon ml-16 ${
                          !this.state.schoolsInfoShowing ? "hidden" : ""
                        }`}
                      >
                        info
                      </i>
                      <span
                        className="tooltip line-height-double whitespace-normal"
                        style={{ width: "320px" }}
                      >
                        <span className="fw-700">Full Service School: </span>
                        The vast majority of your Shlichus is dedicated to this
                        university. You live close to the campus, create your
                        programs based on the school schedule, and the majority
                        of people who attend your Chabad House are from this
                        university.
                        <br />
                        <br />
                        <span className="fw-700">Limited Service School: </span>
                        You offer programs to students at this university.
                        However, you do not base your programs on the school
                        schedule and the majority of people who attend your
                        Chabad House are not from this university.
                      </span>
                    </div>
                  </div>
                  <p className="accent-text small-text">
                    Dist. to Chabad House
                  </p>
                </div>
              ) : null}
              {values.campuses &&
                values.campuses.map((campus, index) => (
                  <div
                    key={index}
                    className="hover-delete-container mobile-card"
                  >
                    <div>
                      <FormInput
                        label="School Name"
                        name={`campuses[${index}].campusName`}
                        validate={require}
                        errors={errors}
                        touched={touched}
                        className="custom-input mr-12"
                        readOnly={true}
                      />
                    </div>
                    <div className="text-center">
                      <FormInput
                        label=""
                        name={`campuses[${index}].isPrimary`}
                        validate={require}
                        errors={errors}
                        touched={touched}
                        className="custom-input mr-12"
                      >
                        {({ field }) => (
                          <div className="radio-button-container">
                            <div>
                              <input
                                type="checkbox"
                                id={`school-${index}`}
                                {...field}
                                value="isPrimary"
                                checked={campus.isPrimary}
                                onChange={(e) => {
                                  const currentPrimarySchoolIndex =
                                    values.campuses
                                      .map((campus) => campus.isPrimary)
                                      .indexOf(true);
                                  handleChange({
                                    target: {
                                      name: `campuses[${currentPrimarySchoolIndex}].isPrimary`,
                                      value: false,
                                    },
                                  });
                                  field.onChange(e);
                                }}
                              />
                              <label htmlFor={`school-${index}`}>
                                <span className="desktop-hidden tablet-hidden">
                                  Make this the primary school
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                      </FormInput>
                    </div>
                    <div>
                      <div className="flex flex-align-center desktop-hidden tablet-hidden">
                        <p className="accent-text small-text">
                          Full / Limited Service{" "}
                        </p>
                        <div className="tooltip-container flex">
                          <i
                            className={`material-icons link-text no-hover profile-add-icon ml-16`}
                          >
                            info
                          </i>
                          <span
                            className="tooltip line-height-double whitespace-normal"
                            style={{ width: "200px", left: 0 }}
                          >
                            <span className="fw-700">
                              Full Service School:{" "}
                            </span>
                            The vast majority of your Shlichus is dedicated to
                            this university. You live close to the campus,
                            create your programs based on the school schedule,
                            and the majority of people who attend your Chabad
                            House are from this university.
                            <br />
                            <br />
                            <span className="fw-700">
                              Limited Service School:{" "}
                            </span>
                            You offer programs to students at this university.
                            However, you do not base your programs on the school
                            schedule and the majority of people who attend your
                            Chabad House are not from this university.
                          </span>
                        </div>
                      </div>
                      <FormInput
                        label="Full / Limited Service"
                        name={`campuses[${index}].isFullTime`}
                        validate={require}
                        errors={errors}
                        touched={touched}
                      >
                        {({ field }) => (
                          <RadioContainer
                            errors={errors}
                            touched={touched}
                            {...field}
                            input1={{
                              id: `fullTime-${index}`,
                              value: true,
                              checked: "true",
                              label: "FS",
                            }}
                            input2={{
                              id: `partTime-${index}`,
                              value: false,
                              checked: "false",
                              label: "LS",
                            }}
                          />
                        )}
                      </FormInput>
                    </div>
                    <div>
                      <p className="accent-text small-text desktop-hidden tablet-hidden">
                        Dist. to Chabad House
                      </p>
                      <FormInput
                        name={`campuses[${index}].distance`}
                        validate={require}
                        errors={errors}
                        touched={touched}
                        className="mr-8"
                      >
                        {({ field }) => (
                          <CustomSelect
                            placeholder="Select Dist..."
                            errors={errors}
                            touched={touched}
                            {...field}
                            options={
                              distances &&
                              distances.map((d) => ({
                                key: d.enumValue,
                                value: d.enumValue,
                                label: d.displayValue,
                                data: d,
                              }))
                            }
                            setFieldValue={setFieldValue}
                          />
                        )}
                      </FormInput>
                    </div>
                  </div>
                ))}
            </div>
          </>
        </FieldArray>
      </div>
    );
  }
}
