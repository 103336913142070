import React from "react";
import ExportCSVButton from "../../../components/ExportCSVButton";
import {
  interactionTypeNames,
  interactionTypeClassifications,
} from "./StudentInteractionTypes";
import { AuthRequest } from "../../../lib";
import queryString from "query-string";
import moment from "moment";

export default class StudentsActivityExportButton extends React.PureComponent {
  getActivity = async () => {
    const {
      filters: {
        search: text, //search param renamed to text for API request
        ...filters
      },
    } = this.props.getActivityParams();

    const params = {
      isExport: true,
      text,
      ...filters,
    };
    const response = await AuthRequest.get(
      `Interactions?${queryString.stringify(params)}`,
    );

    return response.data.payload.results;
  };

  render() {
    const { disabled } = this.props;
    return (
      <ExportCSVButton
        disabled={disabled}
        className="btn btn-secondary students-export-btn"
        fileName="Students-Activity"
        getExportData={this.getActivity}
        getHeaders={() => [
          { label: "Type", value: "type" },
          { label: "Category", value: "category" },
          { label: "Classification", value: "classification" },
          { label: "Setting", value: "setting" },
          { label: "Name", value: "name" },
          {
            label: "Date",
            value: ({ dateTime }) =>
              dateTime ? moment(dateTime).format("MM/DD/YYYY") : "",
          },
          {
            label: "Israel/Antisemitism Related",
            value: ({ isIsraelRelated }) => (isIsraelRelated ? "Yes" : "No"),
          },
          { label: "Location", value: "locationDisplay" },
          { label: "Duration", value: "durationDisplay" },
          { label: "Notes", value: "notes" },
          {
            label: "Mezuzah from MyZuzah",
            value: ({ isMezuzahFromMyZuzah, type }) =>
              type === interactionTypeNames.AffixMezuzah
                ? isMezuzahFromMyZuzah
                  ? "Yes"
                  : "No"
                : "",
          },
          {
            label: "Educators",
            value: ({ educators }) =>
              educators
                ? educators
                    .map((e) => `${e.firstName} ${e.lastName}`)
                    .join(", ")
                : "",
          },
          {
            label: "Shliach/Shlucha Present",
            value: ({ wasShliachPresentForExperience, classification }) =>
              classification === interactionTypeClassifications.Experience
                ? wasShliachPresentForExperience
                  ? "Yes"
                  : "No"
                : "",
          },
          {
            label: "Students",
            value: ({ students }) =>
              students
                ? students.map((s) => `${s.firstName} ${s.lastName}`).join(", ")
                : "",
          },
        ]}
      />
    );
  }
}
