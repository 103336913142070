import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Card,
  Grid,
  Typography,
  Tooltip,
  Avatar,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  getJewishInterestLevelColors,
  getJewishObservanceStatusColor,
} from "../engagement/shared";
import { JewishnessStatuses } from "../../../state/engagement/types";
import CopyToClipboardTextbox from "../../../components/CopyToClipboardTextbox";
import FilePreviewModal from "../../../components/FilePreviewModal";
import StudentProfileModal from "../studentProfile/StudentProfileModal";
import StudentJewishnessModal from "../StudentJewishnessModal";
import StudentObservanceModal from "../StudentObservanceModal";
import {
  formatAddressDisplay,
  formatFullName,
  joinCommaDelimitedList,
  pluralizeText,
} from "../../../lib";
import { useStyles } from "./StudentOverview.styles";
import { useMobile } from "../../../themes";
import { AuthSelectors } from "../../../state";

const getJewishnessStatusIcon = (jewishnessStatus) => {
  switch (jewishnessStatus) {
    case JewishnessStatuses.Yes:
      return (
        <i
          className="material-icons"
          style={{
            color: "#77D9D2",
            fontSize: "32px",
          }}
        >
          verified
        </i>
      );
    case JewishnessStatuses.No:
      return <img src="/images/jewishnessNo.svg" alt="x_mark" height="32px" />;
    default:
      return (
        <img
          src="/images/jewishnessUnknown.svg"
          alt="question_mark"
          height="32px"
        />
      );
  }
};

export default function StudentOverview(props) {
  const {
    editStudentAccess,
    refreshStudentDetails,
    student,
    student: {
      address,
      allergies,
      allergiesDisplay,
      campus,
      cell,
      classDisplay,
      dateCreated,
      demographics = {},
      didStudentCreateAccount,
      didStudentVerifyCampus,
      didStudentVerifyCell,
      didStudentVerifyGraduationYear,
      didStudentVerifyProfile,
      dietaryNotes,
      email,
      graduationYear,
      id: studentID,
      isArchived,
      isDobEstimated,
      isJewish,
      person: { age, dob, firstName, lastName, profileImageURL } = {},
    } = {},
    sys: { jewishBackgroundTypes = [], jewishObservanceStatuses = [] } = {},
    goToOnSubmitProfile,
    editDemographicsMode,
    submitStudent,
  } = props;
  const formattedAddress = address
    ? formatAddressDisplay(
        address.address1,
        `${address.address2 ? `${address.address2},` : ""}`,
        //hard-coded comma because formatAddress prints it on 2 separate lines and here we're putting the entire address on one line
        address.city,
        address.state,
        address.zip,
        address.country,
      )
    : null;
  const hasContactValues = !!(email || cell || formattedAddress);
  const hasEducationValues = !!(campus || classDisplay || graduationYear);
  const hasGeneralValues =
    isJewish !== null ||
    (!!dob && !isDobEstimated) ||
    allergies.length > 0 ||
    !!dietaryNotes?.trim();

  const [showProfileImagePreview, setShowProfileImagePreview] = useState(false);
  const [showStudentProfileModal, setShowStudentProfileModal] = useState(false);
  const [showStudentJewishnessModal, setShowStudentJewishnessModal] =
    useState(false);
  const [showStudentObservanceModal, setShowStudentObservanceModal] =
    useState(false);
  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const { isEnrolledInEngagement } = useSelector(
    AuthSelectors.engagementAccess,
  );

  const classes = useStyles();
  const isMobile = useMobile();

  const profileEditIcon = (
    <>
      <Tooltip
        title={
          !editStudentAccess
            ? "You do not have access to edit this student because s/he is not enrolled at a campus associated with your Chabad House"
            : ""
        }
      >
        <IconButton
          style={{
            padding: "2px",
          }}
          aria-label="edit"
          disabled={!editStudentAccess}
          onClick={() => setShowStudentProfileModal(true)}
        >
          <i className="material-icons">edit</i>
        </IconButton>
      </Tooltip>
    </>
  );

  useEffect(() => {
    if (editDemographicsMode) {
      setShowStudentProfileModal(true);
    }
  }, [editDemographicsMode]);

  const limitedStudentVerifiedProfileFields = !didStudentVerifyProfile
    ? [
        didStudentVerifyCampus && "Campus",
        didStudentVerifyCell && "Cell",
        didStudentVerifyGraduationYear && "Graduating year",
      ].filter((v) => v)
    : [];

  return (
    <>
      {(!didStudentCreateAccount || !didStudentVerifyProfile) && (
        <Alert severity="info" className={classes.alert}>
          {!didStudentCreateAccount
            ? "An account has not yet been created by the student."
            : limitedStudentVerifiedProfileFields.length
            ? `Only the ${joinCommaDelimitedList(
                limitedStudentVerifiedProfileFields,
              )} ${
                limitedStudentVerifiedProfileFields.length > 1 ? "have" : "has"
              } been verified by the student. The rest of the student profile has not yet been verified.`
            : "Student profile has not yet been verified by the student."}{" "}
          Note that {!didStudentCreateAccount ? "" : "unverified "}profile
          fields will not be visible to the student until the profile is
          verified, and will be overridden by the student upon verification.
        </Alert>
      )}
      <Card
        variant={isMobile ? undefined : "outlined"}
        className={classes.cardContainer}
      >
        <Grid
          container
          item
          justifyContent="space-between"
          className="student-overview-header"
        >
          <div className="flex flex-align-center mobile-block tablet-block mobile-mb-16">
            <Typography variant="h2" className="mb-8 flex flex-align-top">
              <Avatar
                className={classes.profileImage}
                alt="profile"
                onClick={() => setShowProfileImagePreview(true)}
                src={profileImageURL}
              />

              <span
                className="xs-text mt-32 ml-0"
                style={{
                  color: isArchived ? "#B4B5B9" : "#33b7ae",
                  marginLeft: "-12px",
                  zIndex: 999,
                  fontSize: "10px",
                }}
              >
                {"\u2B24"}
              </span>
              <div className="ml-16">{formatFullName(firstName, lastName)}</div>
              <div className="desktop-hidden tablet-hidden flex flex-align-center mobile-ml-16">
                {profileEditIcon}
              </div>
            </Typography>
            {isShliach && isEnrolledInEngagement && (
              <>
                <div className="flex flex-justify-space">
                  <div
                    className={`student-overview-icons mobile-full-width flex flex-justify-center ${
                      editStudentAccess ? "pointer" : ""
                    }`}
                    onClick={
                      editStudentAccess
                        ? () => setShowStudentJewishnessModal(true)
                        : undefined
                    }
                  >
                    {getJewishnessStatusIcon(demographics.jewishnessStatus)}
                  </div>
                  <div className="student-overview-icons mobile-full-width flex flex-justify-center">
                    <i
                      className="material-icons accent-text-light mr-8"
                      style={{
                        color: getJewishInterestLevelColors(
                          demographics.jewishInterestLevel,
                        ).dark,
                        fontSize: "32px",
                      }}
                    >
                      favorite
                    </i>
                    <span className="mr-4">
                      {demographics.jewishInterestLevel}
                    </span>
                  </div>
                </div>
                <div className="flex">
                  <div
                    className={`student-overview-icons mobile-full-width flex flex-justify-center ${
                      editStudentAccess ? "pointer" : ""
                    }`}
                    onClick={
                      editStudentAccess
                        ? () => setShowStudentObservanceModal(true)
                        : undefined
                    }
                  >
                    <i
                      className="material-icons mr-8"
                      style={{
                        color: getJewishObservanceStatusColor(
                          demographics.currentJewishObservanceStatus,
                        ).dark,
                        fontSize: "32px",
                      }}
                    >
                      location_on
                    </i>
                    <div>
                      <p className="mr-4">
                        {demographics.currentJewishObservanceStatus}
                      </p>
                      <p className="mr-4 accent-text xs-text">
                        Current Observance
                      </p>
                    </div>
                  </div>
                  <div
                    className={`student-overview-icons mobile-full-width flex flex-justify-center ${
                      editStudentAccess ? "pointer" : ""
                    }`}
                    onClick={
                      editStudentAccess
                        ? () => setShowStudentObservanceModal(true)
                        : undefined
                    }
                  >
                    <i
                      className="material-icons mr-8"
                      style={{
                        color: getJewishObservanceStatusColor(
                          demographics.childhoodJewishObservanceStatus,
                        ).dark,
                        fontSize: "32px",
                      }}
                    >
                      escalator_warning
                    </i>
                    <div>
                      <p className="mr-4">
                        {demographics.childhoodJewishObservanceStatus}
                      </p>
                      <p className="mr-4 accent-text xs-text">
                        Childhood Observance
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <Grid item className="mobile-hidden">
            {profileEditIcon}
          </Grid>
        </Grid>
        {profileImageURL && (
          <FilePreviewModal
            close={() => setShowProfileImagePreview(false)}
            previewStyle={{ objectFit: "contain" }}
            show={showProfileImagePreview}
            title={`${formatFullName(firstName, lastName)} Profile`}
            url={profileImageURL}
          />
        )}
        <div className="student-overview-grid">
          <Grid container item justifyContent="space-between" spacing={3}>
            {hasContactValues && (
              <Grid item>
                <div className="uppercase-text accent-text-dark fw-500 mb-12 student-overview-title">
                  Contact info
                </div>
                {email && (
                  <p className="student-overview-field">
                    <i className="material-icons mr-8 large-text">email</i>
                    <a
                      className="medium-text"
                      href={`mailto:${email}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {email}
                    </a>
                  </p>
                )}
                {cell && (
                  <Typography
                    variant="body2"
                    className="student-overview-field"
                  >
                    <i className="material-icons mr-8 large-text">phone</i>
                    <a
                      className="link-text-secondary medium-text"
                      href={`tel:${cell}`}
                    >
                      {cell}
                    </a>
                  </Typography>
                )}
                {!!formattedAddress && (
                  <Typography
                    variant="body2"
                    className="student-overview-field"
                  >
                    <i className="material-icons mr-8 large-text">home</i>
                    {formattedAddress}
                  </Typography>
                )}
              </Grid>
            )}
            {hasEducationValues && (
              <Grid item>
                <div className="uppercase-text accent-text-dark fw-500 mb-12 student-overview-title">
                  School Info
                </div>
                {campus && (
                  <p className="student-overview-field">
                    <i className="material-icons mr-8 large-text">business</i>
                    {campus.name}
                  </p>
                )}
                {classDisplay && (
                  <p className="student-overview-field">
                    <i className="material-icons mr-8 large-text">label</i>
                    {classDisplay}
                  </p>
                )}
                {graduationYear && (
                  <p className="student-overview-field">
                    <i className="material-icons mr-8 large-text">school</i>
                    {graduationYear}
                  </p>
                )}
              </Grid>
            )}

            <Grid item>
              {hasGeneralValues && (
                <>
                  <div className="uppercase-text accent-text-dark fw-500 mb-12 student-overview-title">
                    General Info
                  </div>

                  {isJewish !== null && (
                    <p className="student-overview-field">
                      <i className="material-icons mr-8 large-text">badge</i>
                      Student identifies as {isJewish ? "Jewish" : "non-Jewish"}
                    </p>
                  )}

                  {dob && !isDobEstimated && (
                    <p className="student-overview-field">
                      <i className="material-icons mr-8">cake</i>
                      <Tooltip title={`Age: ${age}`} arrow>
                        <span>{moment(dob).format("MMMM D, YYYY")}</span>
                      </Tooltip>
                    </p>
                  )}
                  {(allergies.length > 0 || !!dietaryNotes?.trim()) && (
                    <p className="student-overview-field">
                      <img
                        className="mr-8"
                        src="/images/allergies.svg"
                        alt="allergies"
                      />

                      <Tooltip
                        title={
                          <div>
                            {allergies.length > 0 ? (
                              <div>
                                Allergies: {allergiesDisplay.join(", ")}
                              </div>
                            ) : (
                              ""
                            )}
                            {!!dietaryNotes?.trim() ? (
                              <div>Info: {dietaryNotes}</div>
                            ) : (
                              ""
                            )}
                          </div>
                        }
                        arrow
                      >
                        <span>
                          {allergies.length}{" "}
                          {pluralizeText(
                            "allergy",
                            allergies.length,
                            "allergies",
                          )}
                        </span>
                      </Tooltip>
                    </p>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <div className="flex flex-align-center mobile-block mb-16">
            <div className="flex flex-align-center accent-text">
              Joined:{" "}
              {dateCreated ? moment(dateCreated).format("MMMM D, YYYY") : "—"}
            </div>
            <div className="flex flex-align-center accent-text mobile-mt-16">
              <span className="mobile-hidden ml-4 mr-4"> &#x2022;</span>
              COC Student ID:
              <CopyToClipboardTextbox
                className={classes.copyToClipboard}
                id="coc-student-id"
                label="Copy"
                text={studentID}
              />
            </div>
          </div>
        </div>
        {showStudentProfileModal && (
          <StudentProfileModal
            close={() => setShowStudentProfileModal(false)}
            onSubmit={refreshStudentDetails}
            goToOnSubmitProfile={goToOnSubmitProfile}
            editDemographicsMode={editDemographicsMode}
            show={showStudentProfileModal}
            studentProfile={student}
          />
        )}
        {showStudentJewishnessModal && (
          <StudentJewishnessModal
            show={showStudentJewishnessModal}
            close={() => setShowStudentJewishnessModal(false)}
            onSubmit={refreshStudentDetails}
            student={student}
            jewishBackgroundTypes={jewishBackgroundTypes}
            getJewishnessStatusIcon={getJewishnessStatusIcon}
            submitStudent={submitStudent}
          />
        )}
        {showStudentObservanceModal && (
          <StudentObservanceModal
            show={showStudentObservanceModal}
            close={() => setShowStudentObservanceModal(false)}
            onSubmit={refreshStudentDetails}
            student={student}
            jewishObservanceStatuses={jewishObservanceStatuses}
            submitStudent={submitStudent}
          />
        )}
      </Card>
    </>
  );
}
