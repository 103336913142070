import React from "react";
import { withAppInsights } from "../../lib/AppInsights";
import { PageNavLink } from "../../lib";
import Pages from "../../pages";

import Personal from "./personal/PersonalFormContainer";
import ChabadHouse from "./chabadHouse/ChabadHouseFormContainer";
import School from "./school/SchoolForm";

import Loader from "../../components/Loader";

class Profile extends React.PureComponent {
  state = {
    chabadHousesDropdownOpen: false,
    schoolsDropdownOpen: false,
    submitError: this.props.submitError,
  };

  componentDidMount() {
    const { shliachId } = this.props.user;
    this.props.actions.getProfile(shliachId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile.submitError !== this.state.submitError) {
      this.setState({ submitError: nextProps.profile.submitError });
    }
  }

  render() {
    const { chabadHousesDropdownOpen, schoolsDropdownOpen, submitError } =
      this.state;

    const {
      pageRoute: {
        page: { type },
        query: { after },
      },
      profile: {
        loading,
        shliach = {},
        family = {},
        chabadHouses = [],
        schools = [],
        outreach = {},
      },
      user = {},
      sys,
      ui,
    } = this.props;

    return (
      <>
        {loading ? (
          <div style={{ marginTop: "120px" }}>
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            <div className="profile-page-subheader desktop-hidden card">
              <div className="container flex flex-justify-space flex-align-center">
                <ul className="profile-page-subheader-nav flex">
                  <li>
                    <PageNavLink
                      to={Pages.profile.profile}
                      query={{ after }}
                      activeClassName="active-subheader-nav-link"
                    >
                      Personal
                    </PageNavLink>
                    {(!shliach.isComplete || !outreach.isComplete) && (
                      <div className="tooltip-container profile-tooltip ml-8">
                        <i
                          className="material-icons"
                          style={{ fontSize: "16px" }}
                        >
                          error
                        </i>
                        <span className="tooltip">
                          Personal info incomplete
                        </span>
                      </div>
                    )}
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        chabadHousesDropdownOpen:
                          !this.state.chabadHousesDropdownOpen,
                        schoolsDropdownOpen: false,
                      });
                    }}
                  >
                    <p
                      className={
                        this.props.pageRoute.params.chabadHouseId
                          ? "active-subheader-nav-link"
                          : null
                      }
                    >
                      Chabad Houses
                    </p>
                    {!!(
                      chabadHouses && chabadHouses.find((ch) => !ch.isComplete)
                    ) && (
                      <div className="tooltip-container profile-tooltip ml-8">
                        <i
                          className="material-icons"
                          style={{ fontSize: "16px" }}
                        >
                          error
                        </i>
                        <span className="tooltip">
                          Chabad Houses info incomplete
                        </span>
                      </div>
                    )}
                    {chabadHouses && chabadHouses.length > 0 && (
                      <div
                        className={
                          chabadHousesDropdownOpen
                            ? "profile-dropdown-box dropdown-box open"
                            : "profile-dropdown-box dropdown-box"
                        }
                      >
                        <ul>
                          {chabadHouses.map(
                            ({
                              id: chabadHouseId,
                              name: chabadHouseName,
                              isComplete,
                            }) => (
                              <li
                                className="flex flex-align-center"
                                key={chabadHouseId}
                              >
                                <PageNavLink
                                  to={Pages.profile.profile}
                                  query={{ after }}
                                  params={{ chabadHouseId }}
                                  activeClassName="active-subheader-nav-link"
                                >
                                  {chabadHouseName}
                                </PageNavLink>
                                {!isComplete && (
                                  <div className="tooltip-container profile-tooltip flex flex-align-center ml-8">
                                    <i
                                      className="material-icons"
                                      style={{ fontSize: "16px" }}
                                    >
                                      error
                                    </i>
                                    <span className="tooltip">
                                      Chabad House info incomplete
                                    </span>
                                  </div>
                                )}
                              </li>
                            ),
                          )}
                        </ul>
                      </div>
                    )}
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        schoolsDropdownOpen: !this.state.schoolsDropdownOpen,
                        chabadHousesDropdownOpen: false,
                      });
                    }}
                  >
                    <p
                      className={
                        this.props.pageRoute.params.schoolId
                          ? "active-subheader-nav-link"
                          : null
                      }
                    >
                      Schools
                    </p>
                    {!!(schools && schools.find((s) => !s.isComplete)) && (
                      <div className="tooltip-container profile-tooltip ml-8">
                        <i
                          className="material-icons"
                          style={{ fontSize: "16px" }}
                        >
                          error
                        </i>
                        <span className="tooltip">Schools info incomplete</span>
                      </div>
                    )}
                    {schools && schools.length > 0 && (
                      <div
                        className={
                          schoolsDropdownOpen
                            ? "profile-dropdown-box dropdown-box open"
                            : "profile-dropdown-box dropdown-box"
                        }
                      >
                        <ul>
                          {schools.map(
                            ({
                              id: schoolId,
                              name: schoolName,
                              isComplete,
                            }) => (
                              <li
                                className="flex flex-align-center"
                                key={schoolId}
                              >
                                <PageNavLink
                                  to={Pages.profile.profile}
                                  query={{ after }}
                                  params={{ schoolId }}
                                  activeClassName="active-subheader-nav-link"
                                >
                                  {schoolName}
                                </PageNavLink>
                                {!isComplete && (
                                  <div className="tooltip-container profile-tooltip flex flex-align-center ml-8">
                                    <i
                                      className="material-icons"
                                      style={{ fontSize: "16px" }}
                                    >
                                      error
                                    </i>
                                    <span className="tooltip">
                                      School info incomplete
                                    </span>
                                  </div>
                                )}
                              </li>
                            ),
                          )}
                        </ul>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="profile-page page container">
              <div className="profile-cards-wrapper flex">
                <div className="card profile-nav-card mr-24 tablet-hidden mobile-hidden">
                  <ul className="profile-sections-nav">
                    <li>
                      <div className="flex flex-align-center">
                        <p className="profile-nav-section-header">Personal</p>
                      </div>
                      <div className="profile-sections-nested-nav flex flex-align-center">
                        <PageNavLink
                          to={Pages.profile.profile}
                          query={{ after }}
                          activeClassName="active-profile-link"
                          className="line-height-double"
                        >
                          {user.firstName} {user.lastName}
                        </PageNavLink>
                        {(!shliach.isComplete || !outreach.isComplete) && (
                          <div className="tooltip-container profile-tooltip ml-8">
                            <i
                              className="material-icons"
                              style={{ fontSize: "16px" }}
                            >
                              error
                            </i>
                            <span className="tooltip">
                              Personal info incomplete
                            </span>
                          </div>
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="flex flex-justify-space flex-align-center">
                        <p className="profile-nav-section-header">
                          Chabad Houses
                        </p>
                      </div>
                      <ul className="profile-sections-nested-nav">
                        {chabadHouses &&
                          chabadHouses.map(
                            ({
                              id: chabadHouseId,
                              name: chabadHouseName,
                              isComplete,
                            }) => (
                              <li
                                className="flex flex-align-center"
                                key={chabadHouseId}
                              >
                                <PageNavLink
                                  to={Pages.profile.profile}
                                  query={{ after }}
                                  params={{ chabadHouseId }}
                                  activeClassName="active-profile-link"
                                  className="line-height-double"
                                >
                                  {chabadHouseName}
                                </PageNavLink>
                                {!isComplete && (
                                  <div className="tooltip-container profile-tooltip flex flex-align-center ml-8">
                                    <i
                                      className="material-icons"
                                      style={{ fontSize: "16px" }}
                                    >
                                      error
                                    </i>
                                    <span className="tooltip">
                                      Chabad House info incomplete
                                    </span>
                                  </div>
                                )}
                              </li>
                            ),
                          )}
                      </ul>
                    </li>
                    <li>
                      <p className="profile-nav-section-header">Schools</p>
                      <ul className="profile-sections-nested-nav school">
                        {schools &&
                          schools.map(
                            ({
                              id: schoolId,
                              name: schoolName,
                              isComplete,
                            }) => (
                              <li
                                className="flex flex-align-center"
                                key={schoolId}
                              >
                                <PageNavLink
                                  to={Pages.profile.profile}
                                  query={{ after }}
                                  params={{ schoolId }}
                                  activeClassName="active-profile-link"
                                >
                                  {schoolName}
                                </PageNavLink>
                                {!isComplete && (
                                  <div className="tooltip-container profile-tooltip flex flex-align-center ml-8">
                                    <i
                                      className="material-icons"
                                      style={{ fontSize: "16px" }}
                                    >
                                      error
                                    </i>
                                    <span className="tooltip">
                                      School info incomplete
                                    </span>
                                  </div>
                                )}
                              </li>
                            ),
                          )}
                      </ul>
                    </li>
                  </ul>
                </div>
                {type === Pages.profile.profile.type ? (
                  <ChabadHouse
                    actions={this.props.actions}
                    campuses={this.props.campuses}
                    chabadHouse={this.props.profile.chabadHouseData}
                    chabadHouses={this.props.profile.chabadHouses}
                    getCampuses={this.props.actions.getCampuses}
                    getProfileChabadHouse={
                      this.props.actions.getProfileChabadHouse
                    }
                    isIncomplete={
                      !!(
                        this.props.profile.chabadHouseData &&
                        this.props.profile.chabadHouseData.chabadHouse &&
                        chabadHouses &&
                        chabadHouses.find(
                          (c) =>
                            c.id ===
                              this.props.profile.chabadHouseData.chabadHouse
                                .id && !c.isComplete,
                        )
                      )
                    }
                    pageRoute={this.props.pageRoute}
                    permissionClaims={this.props.permissionClaims}
                    shliachId={shliach && shliach.id}
                    submitError={submitError}
                    submitted={this.props.profile.submitted}
                    sys={sys}
                    user={user}
                  />
                ) : type === Pages.profile.profile.type ? (
                  <School
                    actions={this.props.actions}
                    campusData={this.props.profile.campusData}
                    isIncomplete={
                      !!(
                        this.props.profile.campusData &&
                        this.props.profile.campusData.campus &&
                        schools &&
                        schools.find(
                          (s) =>
                            s.id === this.props.profile.campusData.campus.id &&
                            !s.isComplete,
                        )
                      )
                    }
                    pageRoute={this.props.pageRoute}
                    permissionClaims={this.props.permissionClaims}
                    shliachId={shliach && shliach.id}
                    submitError={submitError}
                    submitted={this.props.profile.submitted}
                    sys={sys}
                  />
                ) : (
                  <Personal
                    actions={this.props.actions}
                    family={family}
                    isIncomplete={!shliach.isComplete || !outreach.isComplete}
                    outreach={outreach}
                    pageRoute={this.props.pageRoute}
                    shliach={shliach}
                    submitError={submitError}
                    submitted={this.props.profile.submitted}
                    sys={sys}
                    ui={ui}
                    user={user}
                  />
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </>
    );
  }
}

export default withAppInsights(Profile);
