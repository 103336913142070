import React from "react";
import Select from "react-select";
import get from "lodash.get";

export default class CustomSelect extends React.Component {
  render() {
    const {
      errors,
      isClearable,
      disabled,
      name,
      options,
      placeholder,
      setFieldValue,
      touched,
      value,
    } = this.props;
    const error = get(errors, name) && get(touched, name);
    const styles = {
      indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
      placeholder: (styles) => ({ ...styles, fontSize: "12px" }),
      clearIndicator: (styles) => ({
        ...styles,
        marginRight: "-8px",
        padding: "0",
      }),
    };
    return (
      <Select
        className={`custom-select ${error ? `error` : ""}`}
        classNamePrefix="custom-select"
        getOptionLabel={(option) => option.label}
        isClearable={isClearable}
        isDisabled={disabled}
        name={name}
        onChange={(option) => setFieldValue(name, option && option.value)}
        options={options}
        placeholder={placeholder}
        styles={styles}
        value={options && options.filter((option) => option.value === value)}
      />
    );
  }
}
