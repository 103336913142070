import React from "react";
import { validateEmail } from "../../../../lib";

import AccountInfo from "./AccountInfo";
import PersonalInfo from "./PersonalInfo";
import ContactInfo from "./ContactInfo";
import FamilyInfo from "./FamilyInfo";
import PrimaryChabadHouseInfo from "./PrimaryChabadHouseInfo";
import ConfidentialityPolicyModal from "./ConfidentialityPolicyModal";

export default class Personal extends React.PureComponent {
  state = {
    showConfidentialityPolicyModal: false,
  };

  componentDidMount() {
    // this._ismounted = true;
  }

  componentWillUnmount() {
    // this._ismounted = false;
  }

  validate = () => {
    const errors = {};
    return errors;
  };

  require = (value) => (!value && value !== false ? true : "");

  hasUSAddress = (family) =>
    family && family.address && family.address.country === "USA";

  validateContactMethod = (value, contactMethod) => {
    if (!value) return true;
    if (contactMethod.type === "Email") {
      return !validateEmail(value) ? "Invalid Email" : "";
    }
  };

  validateImageUpload = (value) => {
    if (!value) return true;
  };

  toggleConfidentialityPolicyModal = () => {
    this.setState({
      showConfidentialityPolicyModal:
        !this.state.showConfidentialityPolicyModal,
    });
  };

  render() {
    const {
      errors,
      handleChange,
      setFieldValue,
      setFieldError,
      sys,
      touched,
      ui,
      user,
      values,
    } = this.props;
    const { showConfidentialityPolicyModal } = this.state;

    return (
      <div className="profile-form-sections full-height">
        <div className="profile-form-section">
          <p className="accent-text small-text line-height-double">
            Review and edit your personal information. Information will not be
            shared publicly except for names. Contact information will be shared
            within the Shluchim Portal, which will only be available to other
            Campus Shluchim.
          </p>
          <p
            className="accent-text small-text line-height-double mt-8"
            onClick={this.toggleConfidentialityPolicyModal}
          >
            View{" "}
            <span className="link-text">COCI's confidentiality policy</span>
          </p>
        </div>

        <AccountInfo user={user} />

        <PersonalInfo
          actions={this.props.actions}
          errors={errors}
          handleChange={handleChange}
          personType="person"
          require={this.require}
          setFieldValue={setFieldValue}
          sys={sys}
          ui={ui}
          touched={touched}
          validateImageUpload={this.validateImageUpload}
          values={values}
        />

        <ContactInfo
          errors={errors}
          handleChange={handleChange}
          personType="person"
          require={this.require}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
          sys={sys}
          touched={touched}
          validateContactMethod={this.validateContactMethod}
          values={values}
        />

        <PersonalInfo
          actions={this.props.actions}
          errors={errors}
          handleChange={handleChange}
          personType="spouse"
          require={!!values.spouse ? this.require : null}
          setFieldValue={setFieldValue}
          sys={sys}
          ui={ui}
          touched={touched}
          validateImageUpload={this.validateImageUpload}
          values={values}
          contactInfo={
            !!values.spouse && (
              <ContactInfo
                errors={errors}
                handleChange={handleChange}
                personType="spouse"
                require={this.require}
                setFieldError={setFieldError}
                setFieldValue={setFieldValue}
                sys={sys}
                touched={touched}
                validateContactMethod={this.validateContactMethod}
                values={values}
              />
            )
          }
        />

        <FamilyInfo
          errors={errors}
          handleChange={handleChange}
          hasUSAddress={this.hasUSAddress}
          require={this.require}
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
          sys={sys}
          ui={ui}
        />

        {values.chabadHouses && values.chabadHouses.length > 0 && (
          <PrimaryChabadHouseInfo
            errors={errors}
            handleChange={handleChange}
            require={this.require}
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
            sys={sys}
          />
        )}

        <ConfidentialityPolicyModal
          close={this.toggleConfidentialityPolicyModal}
          show={showConfidentialityPolicyModal}
        />
      </div>
    );
  }
}
