import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "../../../components/react-hook-form";
import {
  ShliachProfile,
  formatProfileForForm,
  validationSchema,
  formatProfileForSubmission,
} from "./ProfileFormHelpers";
import { Box, CircularProgress } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AuthSelectors, ProfileActions, SystemSelectors } from "../../../state";
import { ProfileFormOutline } from "./ProfileFormOutline";
import { ProfileFormSave } from "./ProfileFormSave";
import { Campuses, ChabadHouses, Personal } from "./profileSections";
import {
  LoadingContainerStyled,
  ProfileFormContainerStyled,
  ProfileFormContentStyled,
} from "./ProfileForm.styles";
import MomentUtils from "@date-io/moment";
import ErrorDisplay from "../../../components/ErrorDisplay";

export const ProfileForm = React.memo(function ProfileForm() {
  const dispatch = useDispatch();

  const shliachId = useSelector(AuthSelectors.shliachID);
  const allStudentResources = useSelector(SystemSelectors.studentResources);

  const form = useForm<ShliachProfile>({
    resolver: yupResolver(validationSchema),
    context: { allStudentResources },
  });
  const [loading, setLoading] = useState(true);
  // we are setting initial values and not relying on form.defaultValues because all fields
  // are considered optional in defaultValues which is cumbersome to handle throughout all usages
  const [initialFormValues, setInitialFormValues] = useState<ShliachProfile>();
  const [errorMessage, setErrorMessage] = useState("");
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");

  const setProfileForm = useCallback(
    async (data: ShliachProfile) => {
      const formattedProfile = formatProfileForForm(data);
      setInitialFormValues(formattedProfile);
      form.reset(formattedProfile);
    },
    [form],
  );

  const retrieveProfile = useCallback(async () => {
    const response = await dispatch(
      ProfileActions.getProfileNew(shliachId) as any, //TODO: resolve TS discrepancy
    );

    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setProfileForm(response.data);
    }

    setLoading(false);
  }, [dispatch, shliachId, setProfileForm]);

  useEffect(() => {
    retrieveProfile();
  }, [retrieveProfile]);

  const submitProfile = useCallback(
    async (values: ShliachProfile) => {
      setSubmitErrorMessage("");
      const formattedValues = await formatProfileForSubmission(
        values,
        dispatch,
      );
      const response = await dispatch(
        ProfileActions.submitStudentProfileNew(formattedValues) as any,
      );
      if (response.errorMessage) {
        setSubmitErrorMessage(response.errorMessage);
      } else {
        setProfileForm(response.data);
      }
    },
    [dispatch, setProfileForm],
  );

  return loading ? (
    <LoadingContainerStyled>
      <CircularProgress size="60px" />
    </LoadingContainerStyled>
  ) : errorMessage ? (
    <ErrorDisplay
      errorMessage={errorMessage}
      imgSrc="/images/error.svg"
      className={""}
    />
  ) : initialFormValues ? (
    <Form form={form} onSubmit={submitProfile}>
      <ProfileFormContainerStyled>
        <ProfileFormOutline initialFormValues={initialFormValues} />
        <ProfileFormContentStyled>
          <Box>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Personal form={form} initialFormValues={initialFormValues} />
              <ChabadHouses form={form} initialFormValues={initialFormValues} />
              <Campuses form={form} initialFormValues={initialFormValues} />
            </MuiPickersUtilsProvider>
          </Box>
          <ProfileFormSave submitErrorMessage={submitErrorMessage} />
        </ProfileFormContentStyled>
      </ProfileFormContainerStyled>
    </Form>
  ) : null;
});
