import { AuthRequest, ApiCallErrorMessageHandler } from "../../lib";
import queryString from "query-string";
import { getStudentInteractionsSummaryAsync } from "./EngagementApi";

export const EngagementActions = {
  SET_ENGAGEMENT_PERIODS_LOADING: "SET_ENGAGEMENT_PERIODS_LOADING",
  SET_ENGAGEMENT_PERIODS: "SET_ENGAGEMENT_PERIODS",

  SET_ENGAGEMENT_GOALS_LOADING: "SET_ENGAGEMENT_GOALS_LOADING",
  SET_ENGAGEMENT_GOALS: "SET_ENGAGEMENT_GOALS",

  SET_SUBMIT_ENGAGEMENT_GOALS_LOADING: "SET_SUBMIT_ENGAGEMENT_GOALS_LOADING",
  SET_SUBMIT_ENGAGEMENT_GOALS: "SET_SUBMIT_ENGAGEMENT_GOALS",

  SET_ENGAGEMENT_STUDENT_GOAL_METRICS_LOADING:
    "SET_ENGAGEMENT_STUDENT_GOAL_METRICS_LOADING",
  SET_ENGAGEMENT_STUDENT_GOAL_METRICS: "SET_ENGAGEMENT_STUDENT_GOAL_METRICS",

  SET_ENGAGEMENT_STUDENT_METRICS_LOADING:
    "SET_ENGAGEMENT_STUDENT_METRICS_LOADING",
  SET_ENGAGEMENT_STUDENT_METRICS: "SET_ENGAGEMENT_STUDENT_METRICS",

  SET_ENGAGEMENT_INTERACTION_GOAL_METRICS_LOADING:
    "SET_ENGAGEMENT_INTERACTION_GOAL_METRICS_LOADING",
  SET_ENGAGEMENT_INTERACTION_GOAL_METRICS:
    "SET_ENGAGEMENT_INTERACTION_GOAL_METRICS",

  SET_ENGAGEMENT_INTERACTION_METRICS_LOADING:
    "SET_ENGAGEMENT_INTERACTION_METRICS_LOADING",
  SET_ENGAGEMENT_INTERACTION_METRICS: "SET_ENGAGEMENT_INTERACTION_METRICS",

  SET_ENGAGEMENT_JEWISH_STUDENTS_COUNT_LOADING:
    "SET_ENGAGEMENT_JEWISH_STUDENTS_COUNT_LOADING",
  SET_ENGAGEMENT_JEWISH_STUDENTS_COUNT: "SET_ENGAGEMENT_JEWISH_STUDENTS_COUNT",

  SET_ENGAGEMENT_STUDENT_INTERACTIONS_SUMMARY_LOADING:
    "SET_ENGAGEMENT_STUDENT_INTERACTIONS_SUMMARY_LOADING",
  SET_ENGAGEMENT_STUDENT_INTERACTIONS_SUMMARY:
    "SET_ENGAGEMENT_STUDENT_INTERACTIONS_SUMMARY",

  //TODO: SHOULD WE BE PUTTING STUDENT'S INDIVIDUAL ENGAGEMENT CALLS HERE?

  getEngagementPeriods(isRefresh = false) {
    return async (dispatch) => {
      if (!isRefresh) {
        // hide loading state on refresh to avoid loading indication in UI
        dispatch(EngagementActions.setEngagementPeriodsLoading(true));
      }

      let newState = {
        loading: false,
        success: true,
      };

      try {
        const response = await AuthRequest.get("Engagement/periods");
        newState.data = response.data.payload;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }

      dispatch(EngagementActions.setEngagementPeriods(newState));
    };
  },
  setEngagementPeriodsLoading(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_PERIODS_LOADING,
      payload,
    };
  },
  setEngagementPeriods(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_PERIODS,
      payload,
    };
  },

  getEngagementGoals(engagementPeriodId, isRefresh = false) {
    return async (dispatch) => {
      if (!isRefresh) {
        // hide loading state on refresh to avoid loading indication in UI
        dispatch(EngagementActions.setEngagementGoalsLoading(true));
      }

      let newState = {
        loading: false,
        success: true,
      };

      try {
        const response = await AuthRequest.get(
          `Engagement/periods/${engagementPeriodId}`,
        );
        newState.data = response.data.payload;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }

      dispatch(EngagementActions.setEngagementGoals(newState));
    };
  },
  setEngagementGoalsLoading(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_GOALS_LOADING,
      payload,
    };
  },
  setEngagementGoals(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_GOALS,
      payload,
    };
  },

  submitEngagementGoals(goals) {
    return async (dispatch) => {
      dispatch(EngagementActions.setSubmitEngagementGoalsLoading(true));

      let newState = {
        loading: false,
        success: true,
      };

      try {
        const response = await AuthRequest.post("Engagement/periods", goals);
        newState.data = response.data.payload;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }

      dispatch(EngagementActions.setSubmitEngagementGoals(newState));
    };
  },
  setSubmitEngagementGoalsLoading(payload) {
    return {
      type: EngagementActions.SET_SUBMIT_ENGAGEMENT_GOALS_LOADING,
      payload,
    };
  },
  setSubmitEngagementGoals(payload) {
    return {
      type: EngagementActions.SET_SUBMIT_ENGAGEMENT_GOALS,
      payload,
    };
  },

  getEngagementStudentGoalMetrics(engagementPeriodIds) {
    return async (dispatch) => {
      dispatch(EngagementActions.setEngagementStudentGoalMetricsLoading(true));

      let newState = {
        loading: false,
        success: true,
      };

      try {
        const response = await AuthRequest.get(
          `Engagement/studentGoalMetrics?${queryString.stringify({
            engagementPeriodIds,
          })}`,
        );
        const { metrics, total } = response.data.payload;
        newState.data = metrics;
        newState.total = total;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }

      dispatch(EngagementActions.setEngagementStudentGoalMetrics(newState));
    };
  },
  getEngagementStudentMetrics(monthYears) {
    return async (dispatch) => {
      dispatch(EngagementActions.setEngagementStudentMetricsLoading(true));

      let newState = {
        loading: false,
        success: true,
      };

      try {
        const response = await AuthRequest.get(
          `Engagement/studentMetrics?${queryString.stringify({ monthYears })}`,
        );
        const { metrics, total } = response.data.payload;
        newState.data = metrics;
        newState.total = total;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }

      dispatch(EngagementActions.setEngagementStudentMetrics(newState));
    };
  },
  setEngagementStudentGoalMetricsLoading(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_STUDENT_GOAL_METRICS_LOADING,
      payload,
    };
  },
  setEngagementStudentMetrics(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_STUDENT_METRICS,
      payload,
    };
  },

  setEngagementStudentMetricsLoading(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_STUDENT_METRICS_LOADING,
      payload,
    };
  },
  setEngagementStudentGoalMetrics(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_STUDENT_GOAL_METRICS,
      payload,
    };
  },

  getEngagementInteractionGoalMetrics(engagementPeriodIds) {
    return async (dispatch) => {
      dispatch(
        EngagementActions.setEngagementInteractionGoalMetricsLoading(true),
      );

      let newState = {
        loading: false,
        success: true,
      };

      try {
        const response = await AuthRequest.get(
          `Engagement/interactionGoalMetrics?${queryString.stringify({
            engagementPeriodIds,
          })}`,
        );
        newState.data = response.data.payload;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }

      dispatch(EngagementActions.setEngagementInteractionGoalMetrics(newState));
    };
  },
  getEngagementInteractionMetrics(monthYears) {
    return async (dispatch) => {
      dispatch(EngagementActions.setEngagementInteractionMetricsLoading(true));

      let newState = {
        loading: false,
        success: true,
      };

      try {
        const response = await AuthRequest.get(
          `Engagement/interactionMetrics?${queryString.stringify({
            monthYears,
          })}`,
        );
        newState.data = response.data.payload;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }

      dispatch(EngagementActions.setEngagementInteractionMetrics(newState));
    };
  },
  setEngagementInteractionGoalMetricsLoading(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_INTERACTION_GOAL_METRICS_LOADING,
      payload,
    };
  },
  setEngagementInteractionGoalMetrics(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_INTERACTION_GOAL_METRICS,
      payload,
    };
  },
  setEngagementInteractionMetricsLoading(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_INTERACTION_METRICS_LOADING,
      payload,
    };
  },
  setEngagementInteractionMetrics(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_INTERACTION_METRICS,
      payload,
    };
  },

  getJewishStudentCount() {
    return async (dispatch) => {
      dispatch(EngagementActions.setJewishStudentCountLoading(true));

      let newState = {
        loading: false,
        success: true,
      };

      try {
        const response = await AuthRequest.get("Engagement/jewishStudentCount");
        newState.data = response.data.payload;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }

      dispatch(EngagementActions.setJewishStudentCount(newState));
    };
  },
  setJewishStudentCountLoading(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_JEWISH_STUDENTS_COUNT_LOADING,
      payload,
    };
  },
  setJewishStudentCount(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_JEWISH_STUDENTS_COUNT,
      payload,
    };
  },

  getStudentInteractionsSummary(page, resultsView, filters) {
    return async (dispatch) => {
      dispatch(EngagementActions.setStudentInteractionsSummaryLoading(true));
      const newState = await getStudentInteractionsSummaryAsync(
        page,
        resultsView,
        filters,
      );
      dispatch(EngagementActions.setStudentInteractionsSummary(newState));
    };
  },
  setStudentInteractionsSummaryLoading(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_STUDENT_INTERACTIONS_SUMMARY_LOADING,
      payload,
    };
  },
  setStudentInteractionsSummary(payload) {
    return {
      type: EngagementActions.SET_ENGAGEMENT_STUDENT_INTERACTIONS_SUMMARY,
      payload,
    };
  },
};
