import React from "react";
import CopyToClipboardTextbox from "../../../components/CopyToClipboardTextbox";
import { EduProgramTypes } from "../eduUtils";

export default class EnrollmentCompleted extends React.PureComponent {
  render() {
    const {
      enrollment: { chabadHouseID, eduProgramName, eduProgramType, websiteURL },
      toggleShowEnrollmentCompleted,
    } = this.props;

    return (
      <div className="flex flex-align-center flex-justify-center full-page-state">
        <img src="/images/enrollmentComplete.svg" alt="" height="240" />
        <p className="fw-700 xxl-text mt-24 mb-40 text-center line-height-double">
          You have successfully activated your {eduProgramName}
        </p>
        {eduProgramType === EduProgramTypes.JewishU && (
          <div className="mb-32">
            <div
              className="flex flex-align-center mobile-flex-column"
              style={{ maxWidth: "100%" }}
            >
              <span className="accent-text-dark no-wrap">
                Your Chabad House's Url is
              </span>
              <CopyToClipboardTextbox
                className="desktop-hidden tablet-hidden iframe-container copy-code-container flex flex-justify-space mt-16"
                id="edu-web-url"
                label="Copy Url"
                text={websiteURL}
                style={{ border: "none" }}
              />
              <div
                className="mobile-hidden raffles-link flex flex-align-center flex-justify-space ml-16"
                style={{ maxWidth: "600px", width: "calc(100% - 192px)" }}
              >
                <p className="line-height-double">{websiteURL}</p>
                <a
                  className="link-text small-text flex flex-align-center ml-16 no-wrap"
                  href={websiteURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="material-icons medium-text mr-8">open_in_new</i>
                  Go to Link
                </a>
              </div>
            </div>
            {!!chabadHouseID && (
              <p className="small-text accent-text-dark text-center mt-8 line-height-double">
                The URL Friendly Name (the part of the URL that references your
                Chabad House) can be updated in your Chabad House profile by
                clicking{" "}
                <a
                  className="link-text"
                  href={`/account/chabadHouse/${chabadHouseID}`}
                >
                  here
                </a>
              </p>
            )}
          </div>
        )}
        <button
          className="btn btn-accent btn-large"
          onClick={toggleShowEnrollmentCompleted}
          style={{ padding: "0 40px", maxWidth: "98%" }}
        >
          Get Started
        </button>
      </div>
    );
  }
}
