import React from "react";

import ActivitiesInfo from "./ActivitiesInfo";
import OtherInfo from "./OtherInfo";

export default class ActivitiesForm extends React.PureComponent {
  require = (value) => (!value && value !== false ? true : "");

  render() {
    const {
      errors,
      handleChange,
      permissionClaims,
      sys,
      touched,
      values,
      shliach,
    } = this.props;
    return (
      <div className="profile-form-sections">
        <div className="profile-form-section">
          <p className="accent-text small-text line-height-double">
            Review and edit your Chabad House details. This information will not
            be shared publicly.
            <br />
          </p>
        </div>

        <ActivitiesInfo
          errors={errors}
          handleChange={handleChange}
          shliach={shliach}
          touched={touched}
          require={this.require}
          sys={sys}
          validateHolidayEvents={this.validateHolidayEvents}
          values={values}
        />

        <OtherInfo
          errors={errors}
          handleChange={handleChange}
          require={this.require}
          sys={sys}
          touched={touched}
        />
      </div>
    );
  }
}
