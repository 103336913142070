import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { AddCircle, OpenInNewOutlined } from "@material-ui/icons";
import { ChabadHouseInfoProps } from "./ChabadHouseInfo";
import {
  FormDateField,
  FormFieldArray,
  FormFieldWatch,
  FormSelectField,
  FormTextField,
  FormToggleField,
} from "../../../../../components/react-hook-form";
import { ChabadHousePersonnel } from "../../ProfileFormHelpers";
import {
  getProfileSectionId,
  profileSectionClassName,
} from "../../ProfileFormOutlineHelpers";
import {
  ProfileFormGridItemStyled,
  ProfileFormGridQuarterRowItemStyled,
  ProfileFormGridHalfRowItemStyled,
  ProfileFormGridItemFlexStyled,
} from "../../ProfileForm.styles";
import { SystemSelectors, ChabadHousesActions } from "../../../../../state";
//TODO: move this component to new folder
import InternPermissions from "../../../chabadHouse/general/InternPermissions";
import { ProfileFormSectionWithDelete } from "../../ProfileFormSectionWithDelete";

export const ChabadHousePersonnelInfo = React.memo(
  /**
   *
   */
  function ChabadHousePersonnelInfo(props: ChabadHouseInfoProps) {
    const { chabadHouseIndex, initialFormValues } = props;
    const { personnel: initialPersonnelList } =
      initialFormValues.chabadHouses[chabadHouseIndex];
    const personnelFieldsAccessor = `chabadHouses.${chabadHouseIndex}.personnel`;

    const [studentsList, setStudentsList] = useState([]);
    const [selectedPersonnel, setSelectedPersonnel] =
      useState<ChabadHousePersonnel>();

    const chabadHousePersonnelPositions = useSelector(
      SystemSelectors.chabadHousePersonnelPositions,
    );

    useEffect(() => {
      async function loadStudents() {
        if (!studentsList.length) {
          const response = await ChabadHousesActions.getStudents(
            initialFormValues.chabadHouses[chabadHouseIndex].id,
          );
          if (response.success && response.data?.length) {
            setStudentsList(response.data);
          }
        }
      }
      loadStudents();
    }, [studentsList, chabadHouseIndex, initialFormValues.chabadHouses]);

    return (
      <Box
        className={profileSectionClassName}
        id={getProfileSectionId("chabadHousePersonnel", chabadHouseIndex)}
        style={{ marginBottom: "50px" }}
      >
        <FormFieldArray name={personnelFieldsAccessor}>
          {(fieldArray) => (
            <>
              <Typography variant="h6">
                Personnel
                <IconButton
                  color="primary"
                  onClick={() => {
                    const newPersonnel: ChabadHousePersonnel = {
                      email: "",
                      expirationDate: "",
                      firstName: "",
                      isStudent: false,
                      lastName: "",
                      personID: null,
                      position: "",
                      positionDisplay: "",
                      studentID: null,
                    };
                    fieldArray.prepend(newPersonnel);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginTop: "6px" }}
              >
                Add non-Shluchim personnel who work/assist at your Chabad House.
                This will give them access to some of your data. You can manage
                their access using the “Manage Access” button. Note that
                personnel are not displayed on the public directory.
              </Typography>

              {fieldArray.fields?.map((field, index) => (
                <PersonnelInfoBox
                  key={field.id}
                  personnelFieldsAccessor={personnelFieldsAccessor}
                  index={index}
                  onDelete={() => {
                    fieldArray.remove(index);
                  }}
                  studentsList={studentsList}
                  person={field as unknown as ChabadHousePersonnel}
                  chabadHousePersonnelPositions={chabadHousePersonnelPositions}
                  onClickManage={() => {
                    //using intial list because FormFeildArray overrides id so can't get id from the field...
                    //TODO: sarah robbins found a fix for this on the libraries docs will implement later
                    setSelectedPersonnel(initialPersonnelList?.[index]);
                  }}
                />
              ))}
            </>
          )}
        </FormFieldArray>
        {selectedPersonnel && (
          <InternPermissions
            chabadHousePersonnelID={selectedPersonnel.id}
            firstName={selectedPersonnel.firstName}
            lastName={selectedPersonnel.lastName}
            onClose={() => {
              setSelectedPersonnel(undefined);
            }}
          />
        )}
      </Box>
    );
  },
);

interface PersonnelInfoProps {
  chabadHousePersonnelPositions: any[];
  index: number;
  onClickManage: () => void;
  onDelete: () => void;
  person: ChabadHousePersonnel;
  personnelFieldsAccessor: string;
  studentsList: {
    id: number;
    firstName: string | null;
    lastName: string | null;
    personID: number;
  }[];
}

function PersonnelInfoBox(props: PersonnelInfoProps) {
  const {
    chabadHousePersonnelPositions,
    index,
    onClickManage,
    onDelete,
    person,
    personnelFieldsAccessor,
    studentsList,
  } = props;

  return (
    <ProfileFormSectionWithDelete onDelete={onDelete}>
      <FormFieldWatch
        name={[
          `${personnelFieldsAccessor}.${index}.personnelID`,
          `${personnelFieldsAccessor}.${index}.isStudent`,
        ]}
      >
        {([personnelID, isStudent]) => {
          const isNew = !personnelID;
          const isNonStudentPersonnel = isNew ? !isStudent : !person.studentID;
          return (
            <>
              {isNew ? (
                <>
                  <ProfileFormGridQuarterRowItemStyled>
                    <FormToggleField
                      color="secondary"
                      name={`${personnelFieldsAccessor}.${index}.isStudent`}
                      options={[
                        {
                          id: false,
                          name: "Non-student",
                        },
                        {
                          id: true,
                          name: "Student",
                        },
                      ]}
                    />
                  </ProfileFormGridQuarterRowItemStyled>
                  <ProfileFormGridQuarterRowItemStyled />
                  <ProfileFormGridHalfRowItemStyled />
                  {isStudent && (
                    <>
                      <ProfileFormGridItemStyled>
                        <FormSelectField
                          label="Student"
                          name={`${personnelFieldsAccessor}.${index}.personID`}
                          options={studentsList.map((s: any) => {
                            return {
                              id: s.personID,
                              name: `${s.firstName} ${s.lastName}`,
                            };
                          })}
                        />
                      </ProfileFormGridItemStyled>
                      <ProfileFormGridItemStyled />
                      <ProfileFormGridItemStyled />
                    </>
                  )}
                </>
              ) : null}

              {isNonStudentPersonnel ? (
                <>
                  <ProfileFormGridItemStyled>
                    <FormTextField
                      label="First name"
                      name={`${personnelFieldsAccessor}.${index}.firstName`}
                    />
                  </ProfileFormGridItemStyled>
                  <ProfileFormGridItemStyled>
                    <FormTextField
                      color="secondary"
                      label="Last name"
                      name={`${personnelFieldsAccessor}.${index}.lastName`}
                    />
                  </ProfileFormGridItemStyled>
                  <ProfileFormGridItemStyled>
                    <FormTextField
                      label="Email"
                      name={`${personnelFieldsAccessor}.${index}.email`}
                      type="email"
                    />
                  </ProfileFormGridItemStyled>
                </>
              ) : (
                !isNew && (
                  <>
                    <ProfileFormGridItemStyled>
                      <InputLabel>Name</InputLabel>
                      <Typography>{`${person.firstName} ${person.lastName}`}</Typography>
                    </ProfileFormGridItemStyled>
                    <ProfileFormGridItemStyled>
                      <InputLabel>Email</InputLabel>
                      <Typography>{person.email}</Typography>
                    </ProfileFormGridItemStyled>
                    <ProfileFormGridItemStyled />
                  </>
                )
              )}
              <ProfileFormGridItemStyled>
                <FormSelectField
                  label="Position"
                  name={`${personnelFieldsAccessor}.${index}.position`}
                  options={chabadHousePersonnelPositions.map((p: any) => {
                    return { id: p.enumValue, name: p.displayValue };
                  })}
                />
              </ProfileFormGridItemStyled>
              <ProfileFormGridItemStyled>
                <FormDateField
                  label="Login valid through"
                  name={`${personnelFieldsAccessor}.${index}.expirationDate`}
                />
              </ProfileFormGridItemStyled>
              <ProfileFormGridItemFlexStyled>
                {!isNew && (
                  <Button
                    style={{ marginTop: 16 }}
                    color="primary"
                    onClick={onClickManage}
                    endIcon={<OpenInNewOutlined />}
                  >
                    Manage Access
                  </Button>
                )}
              </ProfileFormGridItemFlexStyled>
            </>
          );
        }}
      </FormFieldWatch>
    </ProfileFormSectionWithDelete>
  );
}
