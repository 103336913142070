import React from "react";
import {
  getProfileSectionId,
  profileSectionClassName,
} from "../../ProfileFormOutlineHelpers";
import { ProfileFormSectionProps } from "../../ProfileFormHelpers";
import { Box, Tooltip } from "@material-ui/core";
import {
  ChabadHouseAddressInfo,
  ChabadHouseCampusesInfo,
  ChabadHouseContactInfo,
  ChabadHouseFinancialInfo,
  ChabadHouseGeneralInfo,
  ChabadHousePersonnelInfo,
  ChabadHouseSocialInfo,
} from "./";
import {
  ProfileFormInfoIcon,
  ProfileFormTypographyFlexStyled,
} from "../../ProfileForm.styles";

export interface ChabadHouseInfoProps extends ProfileFormSectionProps {
  chabadHouseIndex: number;
}

export const ChabadHouseInfo = React.memo(
  /**
   *
   */
  function ChabadHouseInfo(props: ChabadHouseInfoProps) {
    const { chabadHouseIndex, initialFormValues } = props;

    const initialChabadHouseProfile =
      initialFormValues.chabadHouses[chabadHouseIndex];

    return (
      <>
        <Box
          className={profileSectionClassName}
          id={getProfileSectionId("chabadHouseGeneral", chabadHouseIndex)}
        >
          <ProfileFormTypographyFlexStyled variant="h6">
            Chabad house info - {initialChabadHouseProfile.name}
            <Tooltip
              title={
                <>
                  To edit Chabad House name, reach out to{" "}
                  <a
                    href="mailto:help@chabadoncampus.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    help@chabadoncampus.org
                  </a>
                </>
              }
            >
              <ProfileFormInfoIcon />
            </Tooltip>
          </ProfileFormTypographyFlexStyled>
          <ChabadHouseGeneralInfo {...props} />
          <ChabadHouseAddressInfo {...props} />
          <ChabadHouseCampusesInfo {...props} />
          <ChabadHouseContactInfo {...props} />
          <ChabadHouseSocialInfo {...props} />
        </Box>
        <ChabadHousePersonnelInfo {...props} />
        <ChabadHouseFinancialInfo {...props} />
      </>
    );
  },
);
