import React from "react";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";
import moment from "moment";
import { format } from "date-fns";
import {
  validateEmail,
  formatCurrency,
  removeEmptyFromObj,
  dateAddDays,
} from "../../../../lib";
import { RafflePaymentTypes } from "../../constants";
import Modal from "../../../../components/Modal";
import CustomSelect from "../../../../components/form/CustomSelect";
import PhoneInput from "../../../../components/form/PhoneInput";
import EditOrderAddressInfo from "./EditOrderAddressInfo";
import EditOrderPaymentInfo from "./EditOrderPaymentInfo";
import { updateOrder } from "../../../../state/raffle/requests";

export class EditOrderModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      raffle: {
        enrollmentDetails: { id },
      },
      order: {
        billingAddress1,
        billingAddress2,
        billingCity,
        billingCountry,
        billingState,
        billingZip,
        association,
        ccBrand,
        paymentNotes,
        dateOrderedOn,
        donorEmail,
        donorFirstName,
        donorLastName,
        donorPhone,
        isPrivate = false,
        isDonorThanked,
        lastFour,
        paymentMethod,
        quantity,
        referrerID,
        tribute,
        purchaseID,
        source,
      },
    } = props;

    const state = {
      errorMessage: "",
      orderLoading: false,
      orderValidationErrors: [],
      submitAttempted: false,
      order: {
        address: {
          address1: billingAddress1 || "",
          address2: billingAddress2 || "",
          country: billingCountry || "",
          city: billingCity || "",
          state: billingState || "",
          zip: billingZip || "",
        },
        association: association || "None",
        cashTypeID: "",
        ccLastFour: lastFour || "",
        ccBrand: ccBrand || "",
        email: donorEmail || "",
        firstName: donorFirstName || "",
        isDonorThanked: isDonorThanked || false,
        isPrivate: isPrivate || false,
        lastName: donorLastName || "",
        paymentType: paymentMethod || RafflePaymentTypes.CreditCard,
        paymentNotes: paymentNotes || "",
        phone: donorPhone || "",
        orderDate: moment(format(new Date(dateOrderedOn), "MM/dd/yyyy")).format(
          "YYYY-MM-DD",
        ),
        referrerID: referrerID || "",
        tribute: tribute || "",
        ticketQuantity: quantity,
        purchaseID,
        source,
        sellerEnrollmentID: id,
      },
    };
    state.initialState = _cloneDeep(state);
    this.state = state;
  }

  onChangeOrder = (name, value) => {
    let order = _cloneDeep(this.state.order);
    _set(order, name, value);

    this.setState({ order });

    if (this.state.submitAttempted && this.orderIsValid()) {
      this.setState({ errorMessage: "" });
    }
  };

  onChangeEventOrder = (event) => {
    this.onChangeOrder(
      event.target.name,
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value,
    );
  };

  onClose = (reload) => {
    this.props.closeEditOrderModal(reload);
  };

  onSave = async () => {
    this.setState({ errorMessage: "", submitAttempted: true });

    const { initialState, order } = this.state;

    if (_isEqual(order, initialState.order)) {
      return;
    }

    if (!this.orderIsValid()) {
      this.setState({ errorMessage: "Please review required fields" });
      return;
    }

    this.setState({ orderLoading: true });

    //clear cash type if pay method is no longer cash
    if (order.paymentType !== RafflePaymentTypes.Cash) {
      order.cashTypeID = "";
    }

    //TODO: fix bug with datepicker
    //moment does not include time so adding a day so it saves properly without losing a day
    order.orderDate = dateAddDays(new Date(order.orderDate), 1);

    const orderDetailsForUpdate = _cloneDeep(order);
    removeEmptyFromObj(orderDetailsForUpdate);
    const response = await updateOrder(orderDetailsForUpdate);
    if (!response.success) {
      this.setState({
        errorMessage:
          response.error ||
          "Something went wrong and your order could not be updated.  Please try again.",
        orderLoading: false,
      });
    } else {
      this.onClose(true);
    }
  };

  orderIsValid = () => {
    const {
      order: { email, orderDate },
      orderValidationErrors,
    } = this.state;

    return !!email && !!orderDate && !orderValidationErrors.length;
  };

  updateOrderValidation = (name, isValid) => {
    const { orderValidationErrors } = this.state;
    this.setState({
      orderValidationErrors: isValid
        ? orderValidationErrors.filter((err) => err !== name)
        : [...orderValidationErrors, name],
    });
  };

  render() {
    const {
      currencyCode,
      show,
      ticketPrice,
      order: { cashType },
      raffle: {
        details: { startDate, endDate, isOpenForManualTicketEntry },
        referrers,
      },
      sys: { cashTypes, countries, raffleDonorAssociations } = {},
    } = this.props;

    const {
      initialState,
      errorMessage,
      orderLoading,
      order,
      order: {
        address,
        association,
        cashTypeID,
        ccLastFour,
        ccBrand,
        email,
        firstName,
        isPrivate,
        lastName,
        paymentNotes,
        paymentType,
        phone,
        referrerID,
        ticketQuantity,
        tribute,
        orderDate,
        isDonorThanked,
      },
      orderValidationErrors,
    } = this.state;

    return (
      <Modal show={show}>
        <div className="modal-container" key="editOrderModal">
          <div className="modal card new-order-modal modal-height">
            <div className="modal-mobile-overflow">
              <p className="xl-text fw-700 mb-24">Edit Order</p>
              <div className="new-order-modal-grid mb-24">
                <div className="flex flex-align-center relative mobile-block">
                  <label className="accent-text small-text">First Name</label>
                  <p className="small-text">{firstName}</p>
                </div>
                <div className="desktop-display-contents new-order-modal-grid-reverse-order">
                  <div className="flex flex-align-center relative mobile-block">
                    <label className="accent-text small-text">
                      Qty Tickets
                    </label>
                    <div className="flex flex-justify-space flex-align-center full-width">
                      <p className="small-text">{ticketQuantity}</p>
                      {ticketQuantity === 0 ? (
                        "REFUNDED"
                      ) : (
                        <span className="ml-8">
                          {formatCurrency(
                            ticketQuantity * ticketPrice,
                            currencyCode,
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-align-center relative mobile-block">
                    <label className="accent-text small-text">Last Name</label>
                    <p className="small-text">{lastName}</p>
                  </div>
                </div>
                <div className="flex flex-justify-space flex-align-center relative mobile-block">
                  <label className="accent-text small-text">Team</label>
                  <CustomSelect
                    isClearable={true}
                    placeholder="Select a Team..."
                    onChange={this.onChangeOrder}
                    name="referrerID"
                    options={
                      referrers &&
                      referrers.map((r) => ({
                        key: r.id,
                        value: r.id,
                        label: r.name,
                      }))
                    }
                    value={referrerID}
                    disabled={!isOpenForManualTicketEntry}
                  />
                </div>
                <div className="flex flex-justify-space flex-align-center relative mobile-block">
                  <label className="accent-text small-text required">
                    Email
                  </label>
                  <input
                    type="text"
                    className={`custom-input ${
                      !email || orderValidationErrors.indexOf("email") >= 0
                        ? "error"
                        : ""
                    }`}
                    name="email"
                    onBlur={(event) => {
                      const isValidEmail =
                        !event.target.value ||
                        validateEmail(event.target.value);
                      this.updateOrderValidation(
                        event.target.name,
                        isValidEmail,
                      );
                    }}
                    onChange={this.onChangeEventOrder}
                    value={email}
                    disabled={!isOpenForManualTicketEntry}
                  />
                  {orderValidationErrors.indexOf("email") >= 0 && (
                    <span className="error-message field-error-message">
                      Invalid Email Address
                    </span>
                  )}
                </div>
                <div className="flex flex-justify-space flex-align-center relative mobile-block">
                  <label className="accent-text small-text">Phone</label>
                  <PhoneInput
                    countries={countries || []}
                    error={orderValidationErrors.indexOf("phone") >= 0}
                    name="phone"
                    onBlur={this.updateOrderValidation}
                    onChange={this.onChangeOrder}
                    value={phone}
                    disabled={!isOpenForManualTicketEntry}
                  />
                </div>
                <div className="flex flex-justify-space flex-align-center relative mobile-block">
                  <label className="accent-text small-text required">
                    Order Date
                  </label>
                  <input
                    type="date"
                    className={`custom-input 
                    ${
                      !orderDate ||
                      orderValidationErrors.indexOf("orderDate") >= 0
                        ? "error"
                        : ""
                    }`}
                    name="orderDate"
                    value={orderDate}
                    min={moment(startDate).format("YYYY-MM-DD")}
                    max={moment(endDate).format("YYYY-MM-DD")}
                    onChange={(event) => {
                      this.onChangeEventOrder(event);
                      const date = moment(event.target.value);
                      const isValidDate =
                        !!date &&
                        date >= moment(startDate) &&
                        date <= moment(endDate);
                      this.updateOrderValidation("orderDate", isValidDate);
                    }}
                    disabled={!isOpenForManualTicketEntry}
                  />
                  {orderValidationErrors.indexOf("orderDate") >= 0 && (
                    <span className="error-message field-error-message">
                      Invalid Order Date
                    </span>
                  )}
                </div>
                <div className="flex flex-justify-space flex-align-center relative mobile-block">
                  <label className="accent-text small-text">Association</label>
                  <CustomSelect
                    isClearable={true}
                    placeholder="Select association..."
                    onChange={(name, val) =>
                      this.onChangeOrder(name, val || "None")
                    }
                    name="association"
                    options={raffleDonorAssociations?.map((a) => ({
                      key: a.enumValue,
                      value: a.enumValue,
                      label: a.displayValue,
                    }))}
                    value={association}
                    disabled={!isOpenForManualTicketEntry}
                  />
                </div>
                <div className="flex flex-justify-space flex-align-center relative span-two mobile-block">
                  <label className="accent-text small-text">Tribute</label>
                  <input
                    className="custom-input"
                    name="tribute"
                    onChange={this.onChangeEventOrder}
                    placeholder="In memory of, etc"
                    type="text"
                    value={tribute}
                    disabled={!isOpenForManualTicketEntry}
                  />
                </div>
              </div>
              <EditOrderAddressInfo
                countries={countries}
                address={address}
                onChange={this.onChangeOrder}
                onChangeEvent={this.onChangeEventOrder}
                disabled={!isOpenForManualTicketEntry}
              />
              <EditOrderPaymentInfo
                paymentType={paymentType}
                ccLastFour={ccLastFour}
                ccBrand={ccBrand}
                cashTypes={cashTypes}
                cashTypeID={
                  cashTypeID || cashTypes.find((c) => c.name === cashType)?.id
                }
                paymentNotes={paymentNotes}
                onChange={this.onChangeOrder}
                onChangeEvent={this.onChangeEventOrder}
                disabled={!isOpenForManualTicketEntry}
              />
            </div>
            <div className="raffle-modal-btns flex flex-justify-space flex-align-center mt-24 relative">
              <div>
                <div className="flex custom-checkbox-container new-order-checkbox">
                  <input
                    className="custom-checkbox"
                    id="private-donation-checkbox"
                    name="isPrivate"
                    onChange={(event) =>
                      this.onChangeOrder("isPrivate", event.target.checked)
                    }
                    type="checkbox"
                    value={isPrivate}
                    checked={isPrivate}
                    disabled={!isOpenForManualTicketEntry}
                  />
                  <label
                    htmlFor="private-donation-checkbox"
                    className="flex flex-align-center"
                  >
                    <span className="small-text mt-2">
                      Do not acknowledge this donation publicly
                    </span>
                  </label>
                </div>
                <div className="flex custom-checkbox-container new-order-checkbox mt-8">
                  <input
                    className="custom-checkbox"
                    id="donor-thanked-checkbox"
                    name="isDonorThanked"
                    onChange={(event) =>
                      this.onChangeOrder("isDonorThanked", event.target.checked)
                    }
                    type="checkbox"
                    value={isDonorThanked}
                    checked={isDonorThanked}
                  />
                  <label
                    htmlFor="donor-thanked-checkbox"
                    className="flex flex-align-center"
                  >
                    <span className="small-text mt-2">Donor was thanked</span>
                  </label>
                </div>
              </div>

              <div className="flex flex-align-center">
                <button
                  className="btn btn-light btn-medium"
                  onClick={() => this.onClose(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-accent btn-medium ml-16 "
                  disabled={
                    errorMessage ||
                    orderValidationErrors.length ||
                    _isEqual(initialState.order, order)
                  }
                  onClick={this.onSave}
                  tabIndex={190}
                >
                  {orderLoading ? "Processing..." : "Save"}
                </button>
              </div>
              {!orderLoading && errorMessage && (
                <p className="error-message modal-error edit-order-error-message">
                  {errorMessage}
                </p>
              )}
            </div>
            <p className="error-message relative mt-24">
              {!isOpenForManualTicketEntry &&
                "The manual ticket entry deadline has passed. The only editable field at this time is Donor Thanked."}
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}
