import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import _set from "lodash.set";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";

import { formatDateTimeForInput, Navigation, sum } from "../../lib";
import { injectStripe } from "react-stripe-elements";
import * as formFunctions from "./FormLogic";

import ConfirmationModal from "../../components/ConfirmationModal";
import Loader from "../../components/Loader";
import GeneralSettings from "./enrollmentSettings/GeneralSettings";
import Pricing from "./enrollmentSettings/pricing/Pricing";
import Tours from "./enrollmentSettings/tours/Tours";
import Terms from "./enrollmentSettings/Terms";
import Payment from "./enrollmentSettings/payment/Payment";

class TripEnrollmentForm extends React.Component {
  constructor(props) {
    super(props);
    const { credCardInfo } = this.props.account;
    const {
      defaultShliachAcceptancePolicy,
      id,
      latestCancellationDate,
      studentRegistrationEndDate,
    } = this.props.trip.event;
    this.state = {
      enrollmentSettings: {
        tripEventID: id,
        attendees: [],
        campuses: [],
        acceptancePolicyOverride: defaultShliachAcceptancePolicy,
        tracks: [],
        promoCodes: [],
        specialInstructions: "",
        studentRegistrationEndDateOverride:
          formatDateTimeForInput(studentRegistrationEndDate) || "",
        latestCancellationDateOverride:
          formatDateTimeForInput(latestCancellationDate) || "",
        tours: [],
        acceptedTerms: [],
        didAcceptTermsAndConditions: false,
        billing: {
          stripeToken: null,
          address: {
            id: 0,
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            discriminator: "None",
          },
          cardHolderFullName: "",
          useCardOnFile: !!credCardInfo,
          saveNewCardToFile: true,
          applyPromoCode: false,
          hasCompleteCardInfo: false,
          ccRequired: true,
        },
        billingPromoCodes: [],
      },
      enrolling: false,
      errorMessage: "",
      submitAttempted: false,
      validation: {},
      checkForShliachAttendee: true,
      showAttendeeWarningModal: false,
      visibleArea: 0,
      wizardSteps: [
        {
          title: "General",
          name: "generalSettings",
          touched: false,
          component: GeneralSettings,
        },
        {
          title: "Pricing",
          name: "pricing",
          touched: false,
          component: Pricing,
        },
        {
          title: "Tours",
          name: "tours",
          touched: false,
          component: Tours,
        },
        {
          title: "Terms",
          name: "terms",
          touched: false,
          component: Terms,
        },
        {
          title: "Payment",
          name: "payment",
          touched: false,
          component: Payment,
        },
      ],
    };
    this.formFunctions = {};
    Object.keys(formFunctions).forEach(
      (func) => (this.formFunctions[func] = formFunctions[func].bind(this)),
    );
    this.visibleAreas = [0];
  }

  async componentDidMount() {
    if (this.props.pageRoute.query.tab) {
      Navigation.redirect(`${this.props.pageRoute.location.pathname}`);
    }
    await this.setEnrollmentTracks();
    this.optInOhelTour();
    await this.props.actions.getGeneralSettings(this.props.shliachID);
    this.setEnrollmentCampuses();
    this.state.initialEnrollmentSettings = _cloneDeep(
      this.state.enrollmentSettings,
    );
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  optInOhelTour = () => {
    const { tours } = this.props.trip.event;
    const { tours: enrolledTours } = this.state.enrollmentSettings;

    const ohelTour = tours.find(({ isOhelVisit }) => isOhelVisit === true);
    const ohelTourIndex = enrolledTours.findIndex(
      ({ isOhelVisit }) => isOhelVisit === true,
    );
    if (ohelTourIndex < 0 && ohelTour) {
      this.onTourOptIn(true, ohelTour);
    }
  };

  setEnrollmentTracks = async () => {
    const { tracks } = this.props.trip.event;
    const enrollmentTracks = tracks.map((track) => ({
      trackID: track.id,
      trackName: track.trackName,
      earlyBirdPriceOverride: track.earlyBirdStudentPrice,
      earlyBirdPlaceholder: track.earlyBirdStudentPrice,
      earlyBirdStudentDeadline: track.earlyBirdStudentDeadline,
      regularPriceOverride: track.regularStudentPrice,
      regularPlaceholder: track.regularStudentPrice,
    }));
    await this.handleFormChange("tracks", enrollmentTracks);
  };

  setEnrollmentCampuses = () => {
    const { schools } = this.props.trip.settings;
    this.handleFormChange("campuses", schools);
  };

  areaVisibilityChanged(isVisible, visibleArea) {
    if (isVisible) {
      if (!this.visibleAreas.includes(visibleArea)) {
        this.visibleAreas.push(visibleArea);
      }
    } else {
      this.visibleAreas = this.visibleAreas.filter((i) => i !== visibleArea);
    }
    let greatestVisibleArea = Math.max.apply(Math, this.visibleAreas);
    if (greatestVisibleArea !== this.state.visibleArea) {
      this.setState({ visibleArea: greatestVisibleArea });
    }
  }

  scrollToSection(area, index) {
    if (this.refs[area]) {
      this.refs[area].scrollIntoView({ block: "start" });
      window.scrollBy(0, -200);
    }
    // 5 ms after 10 ms scrollDelay, setting visibleStepIndex to clicked stepIndex,
    // in case visibleStepIndex was set to a higher stepIndex if higher steps are visible as well
    setTimeout(() => {
      this.setState({ visibleStepIndex: index });
    }, 15);
  }

  onTourOptIn = (value, tour) => {
    let enrolledTours = [...this.state.enrollmentSettings.tours];
    if (tour) {
      if (value) {
        const { autoEnrollStudents, id, isOhelVisit, schedules } = tour;
        let newTour = {
          tourID: id,
          autoEnrollStudentsOverride: autoEnrollStudents,
          tourScheduleIDs: schedules.length === 1 ? [schedules[0].id] : [],
          isOhelVisit: isOhelVisit,
          schedules: schedules,
        };
        if (tour.hasTransportation) {
          newTour = {
            ...newTour,
            transportationOption: null,
          };
        }
        enrolledTours.push(newTour);
      } else {
        enrolledTours = enrolledTours.filter(
          ({ tourID }) => tourID !== tour.id,
        );
      }
      this.handleFormChange("tours", enrolledTours);
    }
  };

  // handleApplyPromo = invoiceTotal => {
  //   const { promoCodeString } = this.state;
  //   const {
  //     eventSettings: { promoCode },
  //     invoice,
  //     onChange
  //   } = this.props;

  //   if (!promoCodeString) return;

  //   const isValidPromoString = promoCode.trim().toLowerCase() === promoCodeString.trim().toLowerCase();
  //   const validatedPromo = this.validatePromocode(invoiceTotal);

  //   if (!invoice.applyPromoCode && isValidPromoString && validatedPromo) {
  //     onChange('invoice.applyPromoCode', true);
  //     this.setState({ promoCodeString: '' });
  //   } else {
  //     let promoCodeError = '';

  //     if (isValidPromoString && validatedPromo && invoice.applyPromoCode) {
  //       promoCodeError = `Promo code ${promoCode} has already been applied`;
  //     } else if (isValidPromoString && !validatedPromo) {
  //       promoCodeError = `Order is not eligible for promo code ${promoCode}`;
  //     } else {
  //       promoCodeError = 'Invalid promo code';
  //     }
  //     this.setState({ promoCodeError }, () =>
  //       setTimeout(() => {
  //         this.setState({ promoCodeError: '' });
  //       }, 3000)
  //     );
  //   }
  // };

  handleChange = (name, value) => {
    let state = _cloneDeep(this.state);
    _set(state, name, value);

    return new Promise((resolve) => {
      this.setState(state, () => {
        resolve();
      });
    });
  };

  handleArrayChange = async (name, value, status) => {
    let newEnrollmentSettings;
    let array = this.state.enrollmentSettings[name]
      ? [...this.state.enrollmentSettings[name]]
      : [];
    if (status === true) {
      newEnrollmentSettings = {
        ...this.state.enrollmentSettings,
        [name]: [...this.state.enrollmentSettings[name], value],
      };
    } else {
      let index;
      if (name === "campuses" || name === "acceptedTerms") {
        index = array.findIndex((i) => i.id === value.id);
      } else {
        index = array.indexOf(value);
      }
      if (index !== -1 || typeof value === "undefined") {
        array.splice(index, 1);
        newEnrollmentSettings = {
          ...this.state.enrollmentSettings,
          [name]: array,
        };
      }
    }
    return new Promise((resolve, reject) => {
      this.setState(
        {
          enrollmentSettings: newEnrollmentSettings,
        },
        async () => {
          if (
            this.formFunctions.hasValidationErrors() ||
            this.state.submitAttempted
          ) {
            await this.validateForm();
          }
          resolve();
        },
      );
    });
  };

  handleFormChange = (name, value) => {
    let state = _cloneDeep(this.state.enrollmentSettings);
    let newEnrollmentSettings = _set(state, name, value);
    this.setState({
      enrollmentSettings: newEnrollmentSettings,
    });

    return new Promise((resolve, reject) => {
      this.setState(
        {
          enrollmentSettings: newEnrollmentSettings,
        },
        async () => {
          if (
            this.formFunctions.hasValidationErrors() ||
            this.state.submitAttempted
          ) {
            await this.validateForm();
          }
          resolve();
        },
      );
    });
  };

  checkCCRequired = () => {
    const { enrollmentSettings } = this.state;
    const { shliachPrice } = this.props.trip.event;
    let cost = shliachPrice;
    enrollmentSettings.billingPromoCodes.map((promo) => {
      cost -= promo.discountAmount;
      return "";
    });
    const promoCodesTotal = sum(
      enrollmentSettings.promoCodes.map((p) => p.discountAmount),
    );
    const mayOweMoneyForTrack = enrollmentSettings.tracks.map((track) => {
      if (
        track.earlyBirdPriceOverride - promoCodesTotal <
          track.earlyBirdPlaceholder ||
        track.regularPriceOverride - promoCodesTotal < track.regularPlaceholder
      ) {
        return true;
      } else {
        return false;
      }
    });
    const ccRequired = cost > 0 || mayOweMoneyForTrack.indexOf(true) > -1;
    if (ccRequired !== enrollmentSettings.billing.ccRequired) {
      this.handleFormChange("billing.ccRequired", ccRequired);
    }
  };

  validateForm = async () => {
    await this.checkCCRequired();
    const validation = this.formFunctions.getFormValidation();
    await this.handleChange("validation", validation);

    const errorMessage = this.formFunctions.getValidationErrorMessage();
    this.setState({ errorMessage });
  };

  onCancel = () => {
    this.setState({
      enrollmentSettings: this.state.initialEnrollmentSettings,
      enrolling: false,
      errorMessage: "",
      submitAttempted: false,
      validation: {},
    });
  };

  onSubmit = async () => {
    await this.validateForm();
    this.setState({ submitAttempted: true, enrolling: true });
    const hasValidationErrors = Object.keys(this.state.validation).length > 0;

    // validate that at least 1 shliach is selected as attendee
    if (this.state.checkForShliachAttendee) {
      const foundShliachAttendee = this.state.enrollmentSettings.attendees.find(
        (a) => !a.isChaperone,
      );
      if (!foundShliachAttendee) {
        this.setState({ enrolling: false, showAttendeeWarningModal: true });
        return;
      }
    }

    if (hasValidationErrors) {
      this.setState({ enrolling: false });
      return;
    }
    const { updatedEnrollmentSettings, stripeValues } =
      this.formFunctions.getValuesForSubmission();
    await this.props.actions.submitTripEnrollment(
      updatedEnrollmentSettings,
      stripeValues,
      this.state.enrollmentSettings.billing.ccRequired &&
        this.props.stripe.createToken,
      this.props.shliachID,
      //refresh system banners if banner exists for this event
      this.props.sys.portalBanners &&
        this.props.sys.portalBanners.find(
          (pb) =>
            pb.programScheduleID === this.props.trip.event.programScheduleID,
        ),
    );
    this.props.onEnrollmentSubmitted(true);
    if (this.props.trip.enrollment.success === false) {
      if (this._ismounted) {
        this.setState({
          enrolling: false,
          errorMessage: this.props.trip.errorMessage,
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      }
    }
  };

  render() {
    const { settings: { loading } = {} } = this.props.trip;
    const {
      enrolling,
      errorMessage,
      visibleArea,
      wizardSteps,
      submitAttempted,
      validation,
      enrollmentSettings,
      showAttendeeWarningModal,
    } = this.state;
    let dirty = !_isEqual(
      this.state.enrollmentSettings,
      this.state.initialEnrollmentSettings,
    );
    return (
      <div className="card trip-form-card full-width">
        <div className="trip-page-stepper">
          <div className="container relative">
            <div className="stepper-steps">
              {wizardSteps.map((s, index) => {
                const isActive = visibleArea === index;
                return (
                  <React.Fragment key={index}>
                    <div
                      className={`stepper-step ${isActive ? "active" : ""}`}
                      onClick={() => this.scrollToSection(s.title, index)}
                    >
                      <div className="stepper-step-circle" />
                      <p className="stepper-step-title">{s.title}</p>
                    </div>
                    {index !== wizardSteps.length - 1 && (
                      <div className="stepper-line" />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div
            className="trip-page-form-container container"
            ref="formContainer"
          >
            {wizardSteps.map((s, index) => {
              return (
                <VisibilitySensor
                  onChange={(isVisible) =>
                    this.areaVisibilityChanged(isVisible, index)
                  }
                  intervalCheck={false}
                  key={index}
                  minTopValue={350}
                  partialVisibility={true}
                  scrollCheck={true}
                  scrollDelay={10}
                >
                  <div ref={s.title}>
                    {React.createElement(wizardSteps[index].component, {
                      acceptancePolicyOverride:
                        this.props.trip.enrollment.acceptancePolicyOverride,
                      account: this.props.account,
                      actions: this.props.actions,
                      countries: this.props.sys.countries,
                      enrollmentSettings: enrollmentSettings,
                      enrollment: this.props.trip.enrollment,
                      event: this.props.trip.event,
                      onArrayChange: this.handleArrayChange,
                      onChange: this.handleFormChange,
                      onTourOptIn: this.onTourOptIn,
                      shliachID: this.props.shliachID,
                      shliachPrice: this.props.trip.event.shliachPrice,
                      submitAttempted: submitAttempted,
                      trip: this.props.trip,
                      validation: validation,
                      ohelTourTransportationOptions:
                        this.props.sys.ohelTourTransportationOptions,
                      tourTransportationOptions:
                        this.props.sys.tourTransportationOptions,
                    })}
                  </div>
                </VisibilitySensor>
              );
            })}

            <div className="stepper-btns relative">
              <div>
                {!enrolling && (
                  <button
                    className="btn btn btn-large btn-light mt-32"
                    disabled={!dirty}
                    onClick={this.onCancel}
                    style={{ minWidth: "120px" }}
                  >
                    Cancel
                  </button>
                )}
              </div>

              <button
                className="btn btn-accent btn-large mt-32"
                disabled={!dirty || enrolling}
                onClick={() => this.onSubmit()}
                style={{ minWidth: "176px" }}
              >
                {enrolling ? "Enrolling..." : "Enroll Now"}
              </button>
              {errorMessage && (
                <p className="error-message trip-form-error">{errorMessage}</p>
              )}
            </div>
          </div>
        )}
        {showAttendeeWarningModal && (
          <ConfirmationModal
            cancel={() => {
              this.setState({
                showAttendeeWarningModal: false,
              });
            }}
            confirm={() => {
              this.setState({
                checkForShliachAttendee: false,
                showAttendeeWarningModal: false,
              });
              this.onSubmit();
            }}
            title={"No Shliach/Shlucha Added"}
            confirmText={"Save Anyway"}
            secondOptionText={"Add Shliach"}
            onClickSecondOption={() => {
              this.setState({
                showAttendeeWarningModal: false,
              });
              this.scrollToSection("General", 0);
            }}
            message={
              "Don't send your students to pegisha, bring your students to pegisha. At least one Shliach or Shlucha should attend."
            }
            show={true}
          />
        )}
      </div>
    );
  }
}

export default injectStripe(TripEnrollmentForm);
