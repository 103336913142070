const unspecifiedInteractionTypeId = -1;
const unspecifiedInteractionTypeName = "Unspecified";
export const otherInteractionCategoryId = -1;
export const unspecifiedInteractionType = {
  id: unspecifiedInteractionTypeId,
  name: unspecifiedInteractionTypeName,
  category: { id: otherInteractionCategoryId },
};

export function encodeSelectedInteractionTypes(selectedValues = []) {
  return selectedValues.join(",");
}

export function decodeSelectedInteractionTypes(str) {
  if (!str) {
    return [];
  }
  return str.split(",").map(Number);
}
