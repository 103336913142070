import React from "react";
import get from "lodash.get";

export default class RadioContainer extends React.Component {
  clear = () =>
    this.props.onChange({ target: { name: this.props.name, value: "" } });

  render() {
    const {
      disabled,
      errors,
      input1,
      input2,
      name,
      onChange,
      touched,
      ...field
    } = this.props;
    const error = get(errors, name) && get(touched, name);
    if (typeof field.value === "boolean") {
      field.value = field.value.toString();
    }
    return (
      <div
        className={`radio-container ${error ? "error" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        <input
          type="radio"
          name={name}
          id={input1.id}
          onChange={onChange}
          value={input1.value}
          checked={field.value === input1.checked}
          disabled={disabled}
        />
        <label htmlFor={input1.id}>{input1.label}</label>
        <input
          type="radio"
          name={name}
          id={input2.id}
          onChange={onChange}
          value={input2.value}
          checked={field.value === input2.checked}
          disabled={disabled}
        />
        <label htmlFor={input2.id}>{input2.label}</label>
      </div>
    );
  }
}
