import React, { forwardRef } from "react";
import {
  BoxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@material-ui/core";
import { Option } from "../../../lib/types";

export type RadioGroupInputProps = {
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  label?: string | JSX.Element;
  name: string;
  onChange: (name: string, val: any) => void;
  options: Option[];
} & Omit<RadioGroupProps, "onChange">;

interface RadioGroupContainerStyledProps extends BoxProps {
  error?: boolean;
}

export const RadioGroupInput = React.memo(
  forwardRef(
    /**
     *
     */
    function RadioGroupInput(
      {
        disabled,
        error,
        helperText,
        label,
        name,
        onChange,
        options,
        ...passProps
      }: RadioGroupInputProps,
      ref: React.Ref<any> | null,
    ) {
      return (
        <>
          <FormControl ref={ref}>
            {label && <FormLabel error={error}>{label}</FormLabel>}
            <RadioGroup
              name={name}
              onChange={(e) => onChange(name, e.target.value)}
              {...passProps}
            >
              {options.map((option) => (
                <FormControlLabel
                  control={
                    <Radio
                      icon={<RadioIcon />}
                      checkedIcon={<RadioIconChecked />}
                    />
                  }
                  disabled={disabled}
                  key={option.id}
                  label={option.name}
                  value={option.id}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {helperText && (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )}
        </>
      );
    },
  ),
);

export function RadioIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="20" height="20" rx="10" fill="white" />
      <rect x="1" y="1" width="20" height="20" rx="10" stroke="#C7C9D2" />
    </svg>
  );
}

export function RadioIconChecked() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_169_5217)">
        <rect
          x="2"
          y="1"
          width="20"
          height="20"
          rx="10"
          fill="#555FE6"
          shapeRendering="crispEdges"
        />
        <circle cx="12" cy="11" r="5" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_169_5217"
          x="0"
          y="0"
          width="24"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.172153 0 0 0 0 0.199389 0 0 0 0 0.444514 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_169_5217"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_169_5217"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
