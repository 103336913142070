import React from "react";
import { Prompt } from "react-router-dom";
import { ApiCallErrorMessageHandler, formatValuesForForm } from "../../../lib";
import { Formik, Form } from "formik";

import AttributesInfo from "./AttributesInfo";
import GeneralInfo from "./GeneralInfo";
import IncompleteProfileIndicationSetter from "../IncompleteProfileIndicationSetter";
import Loader from "../../../components/Loader";
import PopulationInfo from "./PopulationInfo";
import StudiesInfo from "./StudiesInfo";

export default class School extends React.PureComponent {
  state = {
    saving: false,
    errorMessage: false,
  };

  componentDidMount() {
    const { pageRoute, actions } = this.props;
    actions.getProfileCampus(pageRoute.params.schoolId);
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  require = (value) => (!value && value !== false && value !== 0 ? true : "");

  validateProgramLevels = (value) => {
    if (value.length < 1) return true;
  };

  validatePercentageRequired = (value) => {
    if (!value && value !== 0 && value !== "0") {
      return true;
    }
    if (value < 0 || value > 100 || (value && value.length > 3)) {
      return "Value must be between 0 and 100";
    }
  };

  validatePercentage = (value) => {
    if (value && (value < 0 || value > 100 || (value && value.length > 3))) {
      return "Value must be between 0 and 100";
    }
  };

  hasSpouse = (spouse) =>
    spouse &&
    (spouse.firstName ||
      spouse.lastName ||
      spouse.nickname ||
      spouse.hebrewName ||
      spouse.dob ||
      spouse.beforeNightfall);

  hasUSAddress = (family) =>
    family && family.address && family.address.country === "USA";

  checkForErrors = (errors) => {
    errors = errors && Object.keys(errors);
    if (errors && errors.length > 0) {
      this.setState({ errorMessage: true });
    }

    return;
  };

  onSubmit = async (values, { resetForm }) => {
    this.setState({ saving: true, errorMessage: false });

    let { programLevels, ...campusValues } = values;
    programLevels = programLevels.map((p) => ({
      campusId: campusValues.id,
      programLevel: p.value ? p.value : p.programLevel,
    }));
    campusValues.programLevels = programLevels;
    await this.props.actions.submitProfileCampus(
      campusValues,
      this.props.shliachId,
    );

    if (!this.props.submitError) {
      setTimeout(() => {
        let { campusData: { campus = {} } = {} } = this.props;
        let updatedValues = formatValuesForForm({ ...campus });
        resetForm(updatedValues);
        this.setState({ saving: false });
      }, 1500);
    } else {
      this.setState({ saving: false });
    }
  };

  render() {
    const {
      campusData: { campus = {}, attributes, loading } = {},
      isIncomplete,
      permissionClaims,
      submitError,
      submitted,
      sys,
    } = this.props;

    const { errorMessage, saving } = this.state;

    return loading ? (
      <div className="card profile-form-card mr-24">
        <div>
          <Loader />
        </div>
      </div>
    ) : (
      <Formik
        initialValues={formatValuesForForm({ ...campus })}
        onSubmit={this.onSubmit}
        isInitialValid={true}
      >
        {({
          values,
          dirty,
          errors,
          isValid,
          touched,
          handleChange,
          setFieldValue,
          setTouched,
        }) => {
          return (
            <Form style={{ display: "flex", width: "100%" }}>
              <Prompt
                when={dirty}
                message="Your changes have not been saved. Are you sure you want to continue?"
              />
              {isIncomplete && (
                <IncompleteProfileIndicationSetter
                  setTouched={setTouched}
                  touched={touched}
                  values={values}
                />
              )}
              <div className="card profile-form-card mr-24">
                <div className="profile-form-sections">
                  <div className="profile-form-section">
                    <p className="accent-text small-text line-height-double">
                      Review and edit your school information. <br /> All
                      information aside from School Attributes will be displayed
                      on the public directory @ chabadoncampus.org/directory{" "}
                      <br />
                    </p>
                  </div>
                  <GeneralInfo
                    errors={errors}
                    handleChange={handleChange}
                    hasUSAddress={this.hasUSAddress}
                    require={this.require}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    sys={sys}
                    validateProgramLevels={this.validateProgramLevels}
                  />
                  <StudiesInfo
                    errors={errors}
                    require={this.require}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    sys={sys}
                  />
                  <PopulationInfo
                    errors={errors}
                    require={this.require}
                    touched={touched}
                    values={values}
                    sys={sys}
                    validatePercentageRequired={this.validatePercentageRequired}
                    validatePercentage={this.validatePercentage}
                  />
                  <AttributesInfo
                    handleChange={handleChange}
                    values={values}
                    attributes={attributes}
                  />
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <div className="profile-save-btn-container">
                  <button
                    className="btn btn-accent profile-save-btn"
                    type="submit"
                    disabled={!dirty ? true : false}
                    onClick={() => this.checkForErrors(errors)}
                  >
                    {saving ? "Saving..." : "Save"}
                  </button>
                  {submitError ? (
                    <p className="error-message">
                      {ApiCallErrorMessageHandler(
                        submitError,
                        <React.Fragment>
                          Something went wrong. <br /> Please try again.
                        </React.Fragment>,
                      )}
                    </p>
                  ) : submitted && !dirty ? (
                    <p className="accent-text-dark">
                      <i className="material-icons">check</i> All changes saved
                    </p>
                  ) : dirty ? (
                    <p className="accent-text-dark">
                      <i className="material-icons">save</i>
                      Unsaved changes
                    </p>
                  ) : null}
                  {errorMessage && !isValid ? (
                    <p className="error-message">
                      Please review all required fields
                    </p>
                  ) : null}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
