import React, { memo } from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import CopyToClipboardTextbox from "../../../../../components/CopyToClipboardTextbox";
import { AuthSelectors } from "../../../../../state";

function RsvpItemCreatedModal(props) {
  const {
    rsvpType,
    directUrl,
    sendSmsMessage,
    enrollment: { data: { chabadHouseRsvpURL, forChabadHouseID } = {} },
    isMobile,
    toggleShowEventCreated,
    pageRoute: {
      query: { cloneEventScheduleId },
    },
  } = props;

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);

  const rsvpItemCreatedContent = (
    <div className="text-center">
      <img src="/images/enrollmentComplete.svg" alt="" height="240" />
      <p className="fw-700 xxl-text mt-24 mb-40 text-center line-height-double">
        Your {rsvpType} was successfully{" "}
        {cloneEventScheduleId ? "cloned" : "created"}
      </p>
      <div className="mb-16" style={{ maxWidth: "100%" }}>
        <span className="accent-text-dark no-wrap">
          Your Chabad House's RSVP System shareable URL
        </span>
        <p className="small-text accent-text mt-8">
          Page will display all your Chabad House events and resources
        </p>
        <div className="full-width">
          <CopyToClipboardTextbox
            className="copy-to-clipboard-textbox flex-justify-space mt-8"
            id="rsvp-events-url"
            goToLabel="View Link"
            label="Copy Link"
            showGoTo={true}
            text={chabadHouseRsvpURL}
          />
        </div>
      </div>
      <div className="mb-32" style={{ maxWidth: "100%" }}>
        <span className="accent-text-dark no-wrap">
          This {rsvpType}'s shareable URL
        </span>
        <p className="small-text accent-text mt-8">
          Page will display this {rsvpType} only
        </p>
        <CopyToClipboardTextbox
          className="copy-to-clipboard-textbox flex-justify-space mt-8"
          goToLabel="View Link"
          id="rsvp-sched-url"
          label="Copy Link"
          showGoTo={true}
          text={directUrl}
        />
      </div>
      {!!forChabadHouseID && isShliach && (
        <p
          className="small-text accent-text-dark line-height-double"
          style={{ maxWidth: 600, margin: "8px auto 32px auto" }}
        >
          The URL Friendly Name (the part of the URL that references your Chabad
          House) can be updated in your Chabad House profile by clicking{" "}
          <a
            className="link-text"
            href={`/account/chabadHouse/${forChabadHouseID}`}
          >
            here
          </a>
        </p>
      )}
      <button
        className="btn btn-light btn-large"
        onClick={toggleShowEventCreated}
        style={{ padding: "0 40px", maxWidth: "98%" }}
      >
        Close
      </button>
      {isShliach && (
        <button
          className="btn btn-accent btn-large ml-16"
          onClick={sendSmsMessage}
          style={{ padding: "0 40px", maxWidth: "98%" }}
        >
          Create sms message from {rsvpType}
        </button>
      )}
    </div>
  );

  return !isMobile ? (
    <Modal isOpen={true} className="modal-container">
      <div className="modal-card card xl-modal event-created-modal">
        {rsvpItemCreatedContent}
      </div>
    </Modal>
  ) : (
    <div style={{ padding: "0 16px 32px" }}>{rsvpItemCreatedContent}</div>
  );
}

export default memo(RsvpItemCreatedModal);
