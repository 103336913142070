import React from "react";
import Loader from "../../../components/Loader";
import StudentActivity from "./StudentActivity";
import StudentEduEnrollment from "./edu/StudentEduEnrollment";
import StudentEngagement from "./engagement/StudentEngagement";
import StudentLamplightersGrants from "./lamplighters/StudentLamplightersGrants";
import StudentOverview from "./StudentOverview";
import StudentProfile from "./profile/StudentProfile";
import {
  AuthTypes,
  Navigation,
  PageURL,
  canManageActivity,
  isMobileView,
} from "../../../lib";
import { Tabs, Tab } from "@material-ui/core";
import { TabPanel } from "../../../components/TabPanel";

export default class StudentDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.isShliach = props.auth.loggedInAs === AuthTypes.shliach;
    const _canManageActivity = canManageActivity(props.auth);

    const _tabs = [
      {
        component: StudentActivity,
        id: "activity",
        title: "Activity",
        condition: _canManageActivity,
      },
      {
        component: StudentProfile,
        id: "profile",
        title: "Profile",
        condition: true,
      },
    ].filter((c) => c.condition);

    this.state = {
      editStudentAccess: null,
      initialized: false,
      mobileView: isMobileView(),
      tabs: _tabs,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResizeWindow);

    this.getStudentDetails();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResizeWindow);
  }

  onResizeWindow = () => {
    var mobileView = isMobileView();
    if (mobileView !== this.state.mobileView) {
      this.setState({ mobileView });
    }
  };

  getCurrentStudentId = () => {
    const {
      pageRoute: {
        query: { studentId: queryStudentId },
        params: { studentId: routeStudentId },
      },
    } = this.props;

    //based on if it's rendered full screen or modal, the studentId will come through params (full pg) vs query (modal)
    return routeStudentId || queryStudentId;
  };

  getStudentDetails = async (isRefresh, hideLoadingOnRefresh) => {
    const studentId = this.getCurrentStudentId();
    await this.props.actions.getStudent(studentId, hideLoadingOnRefresh);

    const {
      showEduTabs,
      student: { success },
    } = this.props;
    if (!isRefresh && success) {
      this.setEditStudentAccess();

      if (this.isShliach) {
        if (showEduTabs) {
          //get student edu enrollments list for edu tabs
          await this.getStudentEduDetails(studentId);
        }

        //get student lamplighters grants for lamplighters tab
        await this.getStudentLamplightersDetails(studentId);

        // get student interactions to check access to engagement tab
        await this.getStudentEngagementDetails(studentId);
      }
    }

    this.setState({ initialized: true });
  };

  setEditStudentAccess = async () => {
    //retrieve campuses available to the shliach to determine whether s/he has access to edit the student
    //the student must be enrolled at a campus associated with the shliach's chabad house - relevant for when a student switches campuses after signing up for an edu course or receiving a grant from this shliach, so the shliach has view but not edit access
    await this.props.actions.getStudentCampuses();

    const campuses = this.props.studentCampuses.data;
    if (campuses && campuses.length) {
      const {
        student: { data: { campus } = {} },
      } = this.props;
      const isEnrolledAtCampusAssociatedWithShliach = !!campuses.find(
        (c) => c.id === campus?.id,
      );
      this.setState({
        editStudentAccess: isEnrolledAtCampusAssociatedWithShliach,
      });
    }
  };

  getStudentActivity = async (studentId) => {
    const {
      actions: { getStudentActivity },
    } = this.props;

    await getStudentActivity(studentId);
  };

  getStudentEduDetails = async (studentId) => {
    const {
      actions: { getEduStudentEnrollments },
    } = this.props;

    await getEduStudentEnrollments(studentId);

    const {
      edu: {
        studentEnrollments: { data: studentEnrollments = [], success },
      },
    } = this.props;

    if (success && studentEnrollments && studentEnrollments.length) {
      this.setEduTabs(studentEnrollments);
    }
  };

  setEduTabs = (studentEduEnrollments) =>
    this.setState({
      tabs: [
        ...this.state.tabs,
        ...studentEduEnrollments.map((enrollment) => ({
          component: StudentEduEnrollment,
          id: enrollment.eduProgramType,
          title: enrollment.eduProgramName,
        })),
      ],
    });

  getStudentLamplightersDetails = async (studentId) => {
    const {
      actions: {
        getLamplightersEnrollment,
        getLamplightersSchedules,
        getLamplightersStudentGrants,
      },
    } = this.props;

    //get lamplighters schedules relevant to shliach
    await getLamplightersSchedules();

    const {
      lamplighters: {
        schedules: { data: schedules = [], success: scheduleSuccess },
      },
    } = this.props;

    //if shliach has enrollments in any lamplighters schedules, retrieve details for student's lamplighters tab
    if (scheduleSuccess && schedules && schedules.length) {
      await getLamplightersStudentGrants(studentId);

      const {
        lamplighters: {
          studentGrants: { data: studentGrants = [], success: studentSuccess },
        },
      } = this.props;

      if (studentSuccess && studentGrants && studentGrants.length) {
        this.setState({
          tabs: [
            ...this.state.tabs,
            {
              component: StudentLamplightersGrants,
              id: "lamplighters",
              title: "Lamplighters",
            },
          ],
        });

        //if there is a current lamplighters schedule, get allocation details for student grant activity validation
        const currentSchedule = schedules.find((s) => s.isCurrent);
        if (currentSchedule) {
          await getLamplightersEnrollment(currentSchedule.id);
        }
      }
    }
  };

  getStudentEngagementDetails = async (studentId) => {
    const {
      actions: { getStudentInteractions },
    } = this.props;

    // just check access to display tab, details are reloaded when the tab is accessed
    await getStudentInteractions(studentId, "last6Months");

    const {
      studentInteractions: { success },
    } = this.props;

    if (success) {
      this.setState({
        tabs: [
          ...this.state.tabs,
          {
            component: StudentEngagement,
            id: "engagement",
            title: "Engagement",
          },
        ],
      });
    }
  };

  getCurrentTab = () => {
    const {
      pageRoute: {
        query: { studentTab: tabId },
      },
    } = this.props;
    const { tabs } = this.state;
    return tabs.find((t) => t.id === tabId) || tabs[0];
  };

  toTab = (tabId, subTabId) => {
    this.setState({ tabValue: tabId });

    const {
      pageRoute: { query, page, params },
    } = this.props;

    const url = PageURL.to(page, params, {
      ...query,
      studentTab: tabId,
      studentSub: subTabId,
    });

    Navigation.redirect(url);
  };

  render() {
    const {
      actions,
      edu: {
        studentCourses,
        studentEnrollment,
        studentEnrollments,
        studentRewards,
        submitStudentEnrollment,
      },
      lamplighters: {
        enrollment: lamplightersEnrollment,
        schedules: lamplightersSchedules,
        studentGrants,
        submitStudentGrant,
      },
      isStudentsByCourseView,
      pageRoute,
      student: { data: student = {}, error, errorMessage, loading },
      studentObservance,
      studentInteractions,
      studentsActivity,
      sys,
    } = this.props;
    const {
      query: {
        studentSub: subTabId,
        editDemographicsMode,
        goToOnSubmitProfile,
      },
    } = pageRoute;
    const { editStudentAccess, initialized, mobileView, tabs } = this.state;
    const tabValue = this.getCurrentTab();
    return (
      <div>
        {!initialized || loading ? (
          <div className="flex flex-justify-center" style={{ height: "100%" }}>
            <Loader />
          </div>
        ) : error ? (
          <div
            className="mt-24 mb-24 fw-500 medium-text text-center"
            style={{ height: "100%" }}
          >
            {errorMessage ||
              "Something went wrong and we could not retrieve student details.  Please try again."}
          </div>
        ) : (
          student &&
          student.id && (
            <div>
              <StudentOverview
                editStudentAccess={editStudentAccess}
                editDemographicsMode={editDemographicsMode}
                goToOnSubmitProfile={goToOnSubmitProfile}
                refreshStudentDetails={() => this.getStudentDetails(true)}
                student={student}
                sys={sys}
                updateStudentFields={actions.updateStudentFields}
                submitStudent={actions.submitStudent}
              />
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={tabValue.id}
                variant="scrollable"
                onChange={(e, value) => this.toTab(value)}
              >
                {tabs.map((t) => (
                  <Tab label={t.title} value={t.id} key={t.id} />
                ))}
              </Tabs>
              {tabs.map((tab) => (
                <TabPanel key={tab.id} value={tabValue.id} index={tab.id}>
                  {React.createElement(tab.component, {
                    mobileView,
                    pageRoute,
                    student,
                    subTabId: subTabId,
                    toSubTab: (stId) => this.toTab(tab.id, stId),
                    sys,
                    ...(tab.component === StudentActivity
                      ? {
                          activity: studentsActivity,
                          getActivity: actions.getStudentsActivity,
                          isShliach: this.isShliach,
                          lamplightersSchedules: lamplightersSchedules.data,
                          refreshStudentDetails: () =>
                            this.getStudentDetails(true, true),
                        }
                      : tab.component === StudentEduEnrollment
                      ? {
                          //edu actions:
                          getStudentEnrollment: actions.getEduStudentEnrollment,
                          submitStudentEnrollmentStatus:
                            actions.submitStudentEnrollmentStatus,
                          getStudentCourses: actions.getEduStudentCourses,
                          getStudentRewards: actions.getEduStudentRewards,
                          //edu props:
                          studentEnrollment,
                          studentEnrollmentId: (
                            studentEnrollments.data.find(
                              (e) => e.eduProgramType === tab.id,
                            ) || {}
                          ).id,
                          studentCourses,
                          studentRewards,
                          submitStudentEnrollment,
                          isStudentsByCourseView,
                        }
                      : tab.component === StudentLamplightersGrants
                      ? {
                          enrollment: lamplightersEnrollment,
                          doSubmitStudentGrant:
                            actions.submitLamplightersStudentGrant,
                          getStudentGrants:
                            actions.getLamplightersStudentGrants,
                          studentGrants,
                          submitStudentGrant,
                        }
                      : tab.component === StudentEngagement
                      ? {
                          getStudentInteractions:
                            actions.getStudentInteractions,
                          getStudentObservance: actions.getStudentObservance,
                          mobileView,
                          studentInteractions,
                          studentObservance,
                        }
                      : {}),
                  })}
                </TabPanel>
              ))}
            </div>
          )
        )}
      </div>
    );
  }
}
