import React from "react";

import CustomSelect from "../../../../components/formik/CustomSelect";
import FormInput from "../../../../components/formik/FormInput";
import FormInputUpdate from "../../../../components/formik/FormInputUpdate";
import ImageUpload from "../../../../components/formik/ImageWithCropUpload";
import MultiSelect from "../../../../components/formik/MultiSelect";

import { slugify } from "../../../../lib";

export default class GeneralInfo extends React.PureComponent {
  state = {
    generalInfoShowing: true,
    showSlugUpdateWarning: false,
  };

  componentDidUpdate() {
    const { initialValues, values: { slug } = {} } = this.props;
    if ((initialValues.slug !== slug) !== this.state.showSlugUpdateWarning) {
      this.setState({ showSlugUpdateWarning: initialValues.slug !== slug });
    }
  }

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        generalInfoShowing: false,
      });
    }
  }

  getYearsOptions = () => {
    let year = new Date().getFullYear();
    let years = [];
    for (let i = 1940; i <= year; i++) {
      years.push(i);
    }
    return years;
  };

  setSlug = (evt, isBlur) => {
    const {
      target: { name, value },
    } = evt;

    const slugifiedValue = slugify(value, isBlur); // trim trailing slashes on blur
    this.props.setFieldValue(name, slugifiedValue);
  };

  render() {
    const {
      errors,
      handleChange,
      require,
      touched,
      values,
      sys: { demographics, regions },
      setFieldValue,
      validateDemographics,
    } = this.props;

    const { showSlugUpdateWarning } = this.state;

    const years = this.getYearsOptions();

    return (
      <div
        className={
          this.state.generalInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
        style={{ position: "relative" }}
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-32 mb-16 flex flex-align-center flex-justify-space"
          onClick={() => {
            this.setState({
              generalInfoShowing: !this.state.generalInfoShowing,
            });
          }}
        >
          {values.name}
          <i className="material-icons">
            {this.state.generalInfoShowing
              ? "keyboard_arrow_up"
              : "keyboard_arrow_down"}
          </i>
        </p>
        <p
          className="accent-text-dark desktop-hidden tablet-hidden"
          style={{ padding: "0" }}
        >
          {this.state.generalInfoShowing ? "" : "General Information"}
        </p>

        <div className="profile-form-inputs">
          <div className="square-image-upload">
            <ImageUpload
              name="logoURL"
              label="Upload Logo"
              uploadedLabel="Change Logo"
              onChange={(name, value) =>
                handleChange({ target: { name, value } })
              }
              imageName="Logo"
              imageType="house_logo"
              imageURL={values.logoURL}
              errors={errors}
              touched={touched}
            />
          </div>
          <div className="square-image-upload">
            <ImageUpload
              name="buildingImageURL"
              label="Upload Building Image"
              uploadedLabel="Change Building Image"
              onChange={(name, value) =>
                handleChange({ target: { name, value } })
              }
              imageName="Building Image"
              imageType="house"
              imageURL={values.buildingImageURL}
              errors={errors}
              touched={touched}
            />
          </div>
          <div
            className="flex flex-justify-space flex-align-center relative"
            style={{ gridColumnStart: "span 2" }}
          >
            <FormInput
              label={
                <React.Fragment>
                  Name
                  <span className="tooltip-container ml-8">
                    <i className="material-icons large-text link-text no-hover">
                      info
                    </i>
                    <span className="tooltip tooltip-medium-width">
                      To edit this field, contact help@chabadoncampus.org
                    </span>
                  </span>
                </React.Fragment>
              }
              type="text"
              name="name"
              validate={require}
              errors={errors}
              touched={touched}
              disabled
            />
          </div>
          <div
            className="flex flex-justify-space flex-align-center relative"
            style={{ gridColumnStart: "span 2" }}
          >
            <FormInputUpdate
              label={
                <React.Fragment>
                  URL Friendly Name
                  <span className="tooltip-container ml-8">
                    <i className="material-icons large-text link-text no-hover">
                      info
                    </i>
                    <span className="tooltip tooltip-medium-width">
                      This is a URL friendly version of the Chabad House Name
                      for use in web page links
                    </span>
                  </span>
                </React.Fragment>
              }
              type="text"
              name="slug"
              validate={require}
              errors={errors}
              touched={touched}
              onChange={this.setSlug}
              onBlur={(e) => this.setSlug(e, true)}
            />
          </div>
          {showSlugUpdateWarning && (
            <div
              className="error-text small-text fw-700 mobile-mb-16"
              style={{
                gridColumnStart: "span 2",
                display: "flex",
                marginLeft: window.innerWidth > 560 ? 104 : 0,
              }}
            >
              <span className="mr-8">*</span> Any changes made to this field
              will affect existing web page links already generated eg. RSVP,
              Grand Draw, etc. Please update any existing web pages or links.
            </div>
          )}
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="Year Established"
              type="text"
              name="yearEstablished"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <CustomSelect
                  placeholder="Select Year Established..."
                  errors={errors}
                  touched={touched}
                  {...field}
                  options={
                    years &&
                    years.map((y) => ({
                      key: y,
                      value: y,
                      label: y,
                    }))
                  }
                  setFieldValue={setFieldValue}
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Region"
              name="regionID"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <CustomSelect
                  placeholder="Select Region..."
                  errors={errors}
                  touched={touched}
                  {...field}
                  options={
                    regions &&
                    regions.map((r) => ({
                      key: r.id,
                      value: r.id,
                      label: r.name,
                    }))
                  }
                  setFieldValue={setFieldValue}
                />
              )}
            </FormInput>
          </div>
          <div
            className="flex flex-justify-space flex-align-center relative"
            style={{ gridColumnStart: "span 2" }}
          >
            <FormInput
              label="Website"
              type="text"
              name="websiteURL"
              validate={require}
              errors={errors}
              touched={touched}
            />
          </div>
          <div
            className="flex flex-justify-space flex-align-center relative"
            style={{ gridColumnStart: "span 2" }}
          >
            <FormInput
              label="Website Donation URL"
              type="text"
              name="donateURL"
              validate={require}
              errors={errors}
              touched={touched}
            />
          </div>
          <div
            className="flex flex-justify-space flex-align-center relative"
            style={{ gridColumnStart: "span 2" }}
          >
            <FormInput
              label="Demographics Served"
              name="demographics"
              validate={validateDemographics}
              errors={errors}
              touched={touched}
              className="custom-input mr-12"
              style={{ flex: "2" }}
            >
              {({ field }) => (
                <MultiSelect
                  placeholder="Please select all that apply..."
                  errors={errors}
                  touched={touched}
                  {...field}
                  setFieldValue={this.props.setFieldValue}
                  options={
                    demographics &&
                    demographics.map((d) => ({
                      key: d.enumValue,
                      value: d.enumValue,
                      label: d.displayValue,
                    }))
                  }
                  value={
                    values.demographics
                      ? values.demographics.map((value) =>
                          value.demographic
                            ? {
                                key: value.demographic,
                                value: value.demographic,
                                label: value.demographic,
                              }
                            : value,
                        )
                      : null
                  }
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Tax ID"
              type="text"
              name="taxID"
              errors={errors}
              touched={touched}
            />
          </div>
        </div>
      </div>
    );
  }
}
