import React from "react";
import { ProfileFormSectionProps } from "../../ProfileFormHelpers";
import {
  CampusJewishStudentLifeInfo,
  CampusGeneralInfo,
  CampusPopulationInfo,
} from "./";
import { Box } from "@material-ui/core";
import {
  getProfileSectionId,
  profileSectionClassName,
} from "../../ProfileFormOutlineHelpers";

export interface CampusInfoProps extends ProfileFormSectionProps {
  campusIndex: number;
}

export const CampusInfo = React.memo(
  /**
   *
   */
  function CampusInfo(props: CampusInfoProps) {
    const { campusIndex } = props;

    return (
      <>
        <Box
          className={profileSectionClassName}
          id={getProfileSectionId("campusGeneral", campusIndex)}
        >
          <CampusGeneralInfo {...props} />
          <CampusPopulationInfo {...props} />
        </Box>
        <CampusJewishStudentLifeInfo {...props} />
      </>
    );
  },
);
