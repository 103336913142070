import React, { memo, useState } from "react";
import {
  Button,
  Collapse,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { ArrowDropUp, ArrowDropDown, Lock } from "@material-ui/icons";
import {
  activityMenuItems,
  eventMenuItems,
  smsMenuItems,
} from "../../pages/engagements/menuItems";
import Pages, { AppPages } from "../../pages";
import { SubMenuProps } from "./types";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../../state";
import { subMenuOptions } from "./helpers";

const EngagementSubMenuButton = memo(function ({
  text,
  onClick,
  disabled = false,
  classes,
}: any) {
  return (
    <ListItem
      button
      disabled={disabled}
      onClick={onClick}
      className={classes.nestedItem}
    >
      <ListItemText>
        {disabled && <Lock fontSize="small" className={classes.lockIcon} />}
        {text}
      </ListItemText>
    </ListItem>
  );
});

const useStyles = makeStyles((theme) => ({
  lockIcon: { marginRight: 4, fontSize: 14 },
  nestedItem: { paddingLeft: theme.spacing(12) },
  title: { paddingLeft: theme.spacing(8), paddingRight: theme.spacing(4) },
}));

function _EngagementSubMenu({
  handleMenuClick,
  setActiveSubMenu,
}: SubMenuProps) {
  const { isEnrolledInEngagement, isEnrolledInRsvp, isEnrolledInMessaging } =
    useSelector(AuthSelectors.engagementAccess);

  const classes = useStyles();
  const [showActivityMenu, setShowActivityMenu] = useState<boolean>(false);
  const [showEventsMenu, setShowEventsMenu] = useState<boolean>(false);
  const [showSmsMenu, setShowSmsMenu] = useState<boolean>(false);

  return (
    <>
      <ListItem
        className={classes.title}
        onClick={() => setActiveSubMenu(subMenuOptions.shortcutsMenu)}
      >
        <Button variant="contained" color="primary">
          Create New
        </Button>
      </ListItem>

      <ListItem
        className={classes.title}
        button
        onClick={() => handleMenuClick(Pages.engagement.students.path)}
      >
        <ListItemText>Students</ListItemText>
      </ListItem>

      <ListItem
        selected={showActivityMenu}
        className={classes.title}
        onClick={() => {
          setShowActivityMenu(!showActivityMenu);
        }}
      >
        <ListItemText>Activity</ListItemText>
        {showActivityMenu ? <ArrowDropUp /> : <ArrowDropDown />}
      </ListItem>

      <Collapse in={showActivityMenu} timeout="auto" unmountOnExit>
        {activityMenuItems.map(
          (
            { url, text, isEnrolledInEngagementRequired = false },
            index: number,
          ) => (
            <EngagementSubMenuButton
              key={index}
              disabled={
                isEnrolledInEngagementRequired && !isEnrolledInEngagement
              }
              onClick={() => handleMenuClick(url)}
              classes={classes}
              text={text}
            />
          ),
        )}
      </Collapse>

      <ListItem
        selected={showEventsMenu}
        className={classes.title}
        onClick={() => {
          setShowEventsMenu(!showEventsMenu);
        }}
      >
        <ListItemText> Events</ListItemText>
        {showEventsMenu ? <ArrowDropUp /> : <ArrowDropDown />}
      </ListItem>

      <Collapse in={showEventsMenu} timeout="auto" unmountOnExit>
        {eventMenuItems.map(({ url, text }, index: number) => (
          <EngagementSubMenuButton
            key={index}
            disabled={!isEnrolledInRsvp}
            onClick={() => handleMenuClick(url)}
            classes={classes}
            text={text}
          />
        ))}
      </Collapse>

      <ListItem
        selected={showSmsMenu}
        className={classes.title}
        onClick={() => {
          setShowSmsMenu(!showSmsMenu);
        }}
      >
        <ListItemText>SMS Messages </ListItemText>
        {showSmsMenu ? <ArrowDropUp /> : <ArrowDropDown />}
      </ListItem>

      <Collapse in={showSmsMenu} timeout="auto" unmountOnExit>
        {smsMenuItems.map(({ url, text }, index: number) => (
          <EngagementSubMenuButton
            key={index}
            disabled={!isEnrolledInMessaging}
            onClick={() => handleMenuClick(url)}
            classes={classes}
            text={text}
          />
        ))}
        {/* only displays in mobile view */}
        <EngagementSubMenuButton
          disabled={!isEnrolledInMessaging}
          onClick={() => handleMenuClick(AppPages.engagement.smsInfo.path)}
          classes={classes}
          text={"SMS info"}
        />
      </Collapse>
    </>
  );
}

export const EngagementSubMenu = memo(_EngagementSubMenu);
