import React from "react";
import Pages from "../../..";
import { PageLink } from "../../../../lib";

import FormInput from "../../../../components/formik/FormInput";
import CustomSelect from "../../../../components/formik/CustomSelect";
import { FieldArray } from "formik";

export default class ShluchimInfo extends React.PureComponent {
  state = {
    shluchimInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        shluchimInfoShowing: false,
      });
    }
  }

  render() {
    const {
      errors,
      touched,
      values,
      setFieldValue,
      require,
      sys: { shluchimPositions },
    } = this.props;
    return (
      <div
        className={
          this.state.shluchimInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <div className="profile-form-shluchim-info">
          <FieldArray name="shluchim">
            <>
              <div className="flex mobile-flex-justify-space flex-align-center mt-48">
                <p
                  className="accent-text-dark medium-text fw-700 flex flex-align-center flex-justify-space mobile-full-width"
                  onClick={() => {
                    this.setState({
                      shluchimInfoShowing: !this.state.shluchimInfoShowing,
                    });
                  }}
                >
                  Shluchim
                  <i
                    className="material-icons collapsible"
                    onClick={() => {
                      this.setState({
                        shluchimInfoShowing: !this.state.shluchimInfoShowing,
                      });
                    }}
                  >
                    {this.state.shluchimInfoShowing
                      ? "keyboard_arrow_up"
                      : "keyboard_arrow_down"}
                  </i>
                </p>
              </div>
              <div className="profile-form-inputs mt-16">
                {values.shluchim &&
                  values.shluchim.map((shliach, index) => (
                    <div key={index}>
                      <div className="profile-form-shluchim">
                        <PageLink
                          to={Pages.directory.directoryDetails}
                          params={{ contactId: shliach.shliachID }}
                          className="link-text mr-24 line-height-double"
                        >
                          {shliach.fullName}
                        </PageLink>
                        <div>
                          <FormInput
                            name={`shluchim[${index}].shliachPosition`}
                            validate={require}
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-12"
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <CustomSelect
                                {...field}
                                errors={errors}
                                options={
                                  shluchimPositions &&
                                  shluchimPositions.map((p) => ({
                                    key: p.enumValue,
                                    value: p.enumValue,
                                    label: p.displayValue,
                                  }))
                                }
                                placeholder="Select Position..."
                                setFieldValue={setFieldValue}
                                touched={touched}
                              />
                            )}
                          </FormInput>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          </FieldArray>
        </div>
      </div>
    );
  }
}
