import React from "react";

import NumberFormat from "react-number-format";

import FormInput from "../../../components/formik/FormInput";

export default class PopulationInfo extends React.PureComponent {
  state = {
    populationInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        populationInfoShowing: false,
      });
    }
  }

  render() {
    const {
      errors,
      require,
      touched,
      validatePercentage,
      validatePercentageRequired,
    } = this.props;
    return (
      <div
        className={
          this.state.populationInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-48 mb-16 flex flex-align-center flex-justify-space"
          onClick={() => {
            this.setState({
              populationInfoShowing: !this.state.populationInfoShowing,
            });
          }}
        >
          School Population
          <i className="material-icons collapsible">
            {this.state.populationInfoShowing
              ? "keyboard_arrow_up"
              : "keyboard_arrow_down"}
          </i>
        </p>
        <div className="profile-form-inputs">
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="School Size"
              type="number"
              name="schoolSize"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={0}
                  {...field}
                  className="custom-input"
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Jewish Population"
              type="number"
              name="jewishPopulationPercentage"
              validate={validatePercentageRequired}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  {...field}
                  className="custom-input percentage-input"
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="Female Students"
              type="number"
              name="femaleStudentsPercentage"
              validate={validatePercentageRequired}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  {...field}
                  className="custom-input percentage-input"
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Male Students"
              type="number"
              name="maleStudentsPercentage"
              validate={validatePercentageRequired}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  {...field}
                  className="custom-input percentage-input"
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="Graduate Population (optional)"
              type="number"
              name="graduatePopulation"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={0}
                  {...field}
                  className="custom-input"
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Undergrad Population (optional)"
              type="number"
              name="undergradPopulation"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={0}
                  {...field}
                  className="custom-input"
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="Jewish Graduate Population"
              type="number"
              validate={validatePercentage}
              name="jewishGradPopulationPercentage"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  {...field}
                  className="custom-input percentage-input"
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Jewish Undergrad Population"
              type="number"
              validate={validatePercentage}
              name="jewishUndergradPopulationPercentage"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  {...field}
                  className="custom-input percentage-input"
                />
              )}
            </FormInput>
          </div>
          <p
            className="fw-900 accent-text mt-16"
            style={{ gridColumnStart: "span 2" }}
          >
            Religious Affiliation of Jewish Students
          </p>
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="Reform Students"
              type="number"
              name="reformStudentsPercentage"
              validate={validatePercentageRequired}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  {...field}
                  className="custom-input percentage-input"
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Conservative Students"
              type="number"
              name="conservativeStudentsPercentage"
              validate={validatePercentageRequired}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  {...field}
                  className="custom-input percentage-input"
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="Orthodox Students"
              type="number"
              name="orthodoxStudentsPercentage"
              validate={validatePercentageRequired}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  {...field}
                  className="custom-input percentage-input"
                />
              )}
            </FormInput>
          </div>
        </div>
      </div>
    );
  }
}
