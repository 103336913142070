import React from "react";

import { replaceValuesInObject } from "../../lib";
import _cloneDeep from "lodash.clonedeep";

export default class IncompleteProfileIndicationSetter extends React.PureComponent {
  componentDidMount() {
    const { setTouched, values } = this.props;

    if (values && setTouched) {
      const touchedVals = _cloneDeep(values);
      replaceValuesInObject(
        touchedVals,
        (p) => !(p instanceof Object),
        () => true,
      );
      setTouched(touchedVals);
    }
  }

  render() {
    return null;
  }
}
