import { AuthRequest, ApiCallErrorMessageHandler, toFormData } from "../../lib";
import queryString from "query-string";

export const StudentsActions = {
  SET_STUDENT_LOADING: "SET_STUDENT_LOADING",
  SET_STUDENT: "SET_STUDENT",

  SET_SUBMIT_STUDENT_LOADING: "SET_SUBMIT_STUDENT_LOADING",
  SET_SUBMIT_STUDENT: "SET_SUBMIT_STUDENT",

  SET_CHABAD_HOUSE_TAGS: "SET_CHABAD_HOUSE_TAGS",
  SET_ALL_CHABAD_HOUSE_TAGS: "SET_ALL_CHABAD_HOUSE_TAGS",

  SET_STUDENTS_LOADING: "SET_STUDENTS_LOADING",
  SET_STUDENTS: "SET_STUDENTS",

  SET_STUDENT_CAMPUSES_LOADING: "SET_STUDENT_CAMPUSES_LOADING",
  SET_STUDENT_CAMPUSES: "SET_STUDENT_CAMPUSES",

  SET_STUDENT_PROFILE_SETTINGS_LOADING: "SET_STUDENT_PROFILE_SETTINGS_LOADING",
  SET_STUDENT_PROFILE_SETTINGS: "SET_STUDENT_PROFILE_SETTINGS",

  SET_SUBMIT_STUDENT_PROFILE_SETTINGS_LOADING:
    "SET_SUBMIT_STUDENT_PROFILE_SETTINGS_LOADING",
  SET_SUBMIT_STUDENT_PROFILE_SETTINGS: "SET_SUBMIT_STUDENT_PROFILE_SETTINGS",

  SET_STUDENTS_ACTIVITY_LOADING: "SET_STUDENTS_ACTIVITY_LOADING",
  SET_STUDENTS_ACTIVITY: "SET_STUDENTS_ACTIVITY",

  SET_STUDENT_INTERACTION_LOADING: "SET_STUDENT_INTERACTION_LOADING",
  SET_STUDENT_INTERACTION: "SET_STUDENT_INTERACTION",

  SET_SUBMIT_STUDENT_INTERACTION_LOADING:
    "SET_SUBMIT_STUDENT_INTERACTION_LOADING",
  SET_SUBMIT_STUDENT_INTERACTION: "SET_SUBMIT_STUDENT_INTERACTION",

  SET_CREATE_EVENT_INTERACTIONS_FOR_CHABAD_HOUSE_LOADING:
    "SET_CREATE_EVENT_INTERACTIONS_FOR_CHABAD_HOUSE_LOADING",
  SET_CREATE_EVENT_INTERACTIONS_FOR_CHABAD_HOUSE:
    "SET_CREATE_EVENT_INTERACTIONS_FOR_CHABAD_HOUSE",

  SET_DELETE_STUDENT_INTERACTION_LOADING:
    "SET_DELETE_STUDENT_INTERACTION_LOADING",
  SET_DELETE_STUDENT_INTERACTION: "SET_DELETE_STUDENT_INTERACTION",

  SET_STUDENT_INTERACTIONS: "SET_STUDENT_INTERACTIONS",
  SET_STUDENT_INTERACTIONS_LOADING: "SET_STUDENT_INTERACTIONS_LOADING",

  SET_STUDENT_OBSERVANCE: "SET_STUDENT_OBSERVANCE",
  SET_STUDENT_OBSERVANCE_LOADING: "SET_STUDENT_OBSERVANCE_LOADING",

  getStudent(studentId, isRefresh = false) {
    return async (dispatch) => {
      if (!isRefresh) {
        // hide loading state on refresh to avoid loading indication in UI
        dispatch(StudentsActions.setStudentLoading(true));
      }

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(`Students/${studentId}`).catch(
        (err) => {
          success = false;
          newState = {
            error: err,
            errorMessage: ApiCallErrorMessageHandler(err),
          };
        },
      );

      if (success && response.data) {
        newState.data = response.data;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(StudentsActions.setStudent(newState));
    };
  },
  setStudentLoading(payload) {
    return {
      type: StudentsActions.SET_STUDENT_LOADING,
      payload,
    };
  },
  setStudent(payload) {
    return {
      type: StudentsActions.SET_STUDENT,
      payload,
    };
  },
  updateArchiveStatus(studentId, shouldArchive) {
    return async (dispatch, getState) => {
      let newState = {};

      try {
        const response = await AuthRequest.post("students/archiveStatus", {
          studentId,
          shouldArchive,
        });
        if (response?.data?.payload) {
          const students = { ...(getState().students?.students?.data || {}) };
          const results = [...(students?.results || [])].map((s) =>
            s.studentID !== studentId ? { ...s } : response.data.payload,
          );

          newState.data = { ...students, results };

          dispatch(StudentsActions.setStudents(newState));
        }
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }
    };
  },
  getAllChabadHouseTags() {
    return async (dispatch) => {
      let newState = {
        loading: false,
        success: true,
      };
      try {
        const response = await AuthRequest.get("Shluchim/studentGroups");
        newState.data = response.data;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }
      dispatch(StudentsActions.setAllChabadHouseTags(newState));
    };
  },
  getChabadHouseTags(studentID) {
    return async (dispatch) => {
      let newState = {
        loading: false,
        success: true,
      };
      try {
        const response = await AuthRequest.get(
          `Shluchim/studentGroups?studentId=${studentID}`,
        );
        newState.data = response.data;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }
      dispatch(StudentsActions.setChabadHouseTags(newState));
    };
  },
  setChabadHouseTags(payload) {
    return {
      type: StudentsActions.SET_CHABAD_HOUSE_TAGS,
      payload,
    };
  },
  setAllChabadHouseTags(payload) {
    return {
      type: StudentsActions.SET_ALL_CHABAD_HOUSE_TAGS,
      payload,
    };
  },
  submitStudent(student) {
    return async (dispatch) => {
      dispatch(StudentsActions.setSubmitStudentLoading(true));

      let newState = {
        loading: false,
        success: true,
      };

      try {
        const response = await AuthRequest.post("Students", student);
        newState.data = response.data;
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }

      dispatch(StudentsActions.setSubmitStudent(newState));
      return newState;
    };
  },
  setSubmitStudentLoading(payload) {
    return {
      type: StudentsActions.SET_SUBMIT_STUDENT_LOADING,
      payload,
    };
  },
  setSubmitChabadHouseTagsLoading(payload) {
    return {
      type: StudentsActions.SET_SUBMIT_CHABAD_HOUSE_TAGS_LOADING,
      payload,
    };
  },
  setSubmitStudent(payload) {
    return {
      type: StudentsActions.SET_SUBMIT_STUDENT,
      payload,
    };
  },
  addChabadHouseTags(tagNames) {
    return async (dispatch) => {
      let newState = {
        loading: false,
        success: true,
      };
      try {
        const response = await AuthRequest.post(
          "ChabadHouseTags/groups",
          tagNames,
        );
        if (response.data) {
          newState.data = response.data.payload;
          return response.data.payload;
        }
      } catch (err) {}
    };
  },
  deleteChabadHouseTag(tagId) {
    return async (dispatch) => {
      let newState = {
        loading: false,
        success: true,
      };
      try {
        const response = await AuthRequest.delete(
          `ChabadHouseTags/group/${tagId}`,
        );
        if (response.data) {
          newState.data = response.data.payload;
          await dispatch(StudentsActions.setAllChabadHouseTags(newState));
          return response.data.payload;
        }
      } catch (err) {}
    };
  },
  removeStudentFromGroup(studentId, tagId) {
    return async () => {
      try {
        const response = await AuthRequest.delete(
          `Students/${studentId}/tag/${tagId}`,
        );
        if (response.data) {
          return response.data.payload;
        }
      } catch (err) {}
    };
  },

  deleteStudent(id) {
    return async () => {
      const newState = {
        loading: false,
        success: true,
      };
      await AuthRequest.delete(`Students/${id}`).catch((err) => {
        newState.success = false;
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        return newState;
      });
      return newState;
    };
  },
  updateChabadHouseGroupParticipants(filters, tags, isInsert) {
    return async (dispatch) => {
      let newState = {};
      let success = true;

      const params = {
        tags,
        isInsert,
        filters,
      };

      try {
        const response = await AuthRequest.post(
          `Students/UpdateChabadHouseTagParticipants`,
          params,
        );
        if (response.data) {
          newState.data = response.data.payload;
        }
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }
      newState.loading = false;
      newState.success = success;

      dispatch(StudentsActions.setStudents(newState));
    };
  },
  getStudents(page, results, filters) {
    return async (dispatch) => {
      dispatch(StudentsActions.setStudentsLoading(true));

      let newState = {};
      let success = true;

      const {
        chabadHouseTags,
        campusId,
        engagementRoundupFilter,
        graduationYear,
        gender,
        includeChabadHouseTags,
        includeDetails = true,
        includeArchived = true,
        jewishInterestLevel,
        isExport,
        search,
        selectAll,
        studentIDsToExclude,
        sortBy: sortByOption,
      } = filters || {};
      const params = {
        chabadHouseTags,
        campusId,
        engagementRoundupFilter,
        graduationYear,
        gender,
        jewishInterestLevel,
        includeArchived,
        includeChabadHouseTags,
        includeDetails,
        isExport,
        page,
        results,
        selectAll,
        studentIDsToExclude,
        sortByOption,
        text: search,
      };

      const response = await AuthRequest.get(
        `Students/byShliach?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(StudentsActions.setStudents(newState));
      return { success, data: response?.data };
    };
  },
  setStudentsLoading(payload) {
    return {
      type: StudentsActions.SET_STUDENTS_LOADING,
      payload,
    };
  },
  setStudents(payload) {
    return {
      type: StudentsActions.SET_STUDENTS,
      payload,
    };
  },

  getStudentCampuses() {
    return async (dispatch, getState) => {
      dispatch(StudentsActions.setStudentCampusesLoading(true));

      let newState = {};
      let success = true;

      const shliachId = getState().auth.shliachID;
      const response = await AuthRequest.get(
        `Shluchim/${shliachId}/campuses/basic`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(StudentsActions.setStudentCampuses(newState));
    };
  },
  setStudentCampusesLoading(payload) {
    return {
      type: StudentsActions.SET_STUDENT_CAMPUSES_LOADING,
      payload,
    };
  },
  setStudentCampuses(payload) {
    return {
      type: StudentsActions.SET_STUDENT_CAMPUSES,
      payload,
    };
  },

  submitStudentsImport(students, campusId) {
    return async () => {
      let newState = {};
      let success = true;

      const formData = new FormData();
      formData.append("file", students);

      await AuthRequest.post(
        `Students/bulkImport?campusId=${campusId}`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        },
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      newState.loading = false;
      newState.success = success;

      return newState;
    };
  },

  getStudentProfileSettings(chabadHouseId, programId, campusId) {
    return async (dispatch) => {
      dispatch(StudentsActions.setStudentProfileSettingsLoading(true));

      let newState = {
        loading: false,
        success: true,
      };

      try {
        const params = {
          campusId,
          chabadHouseId,
          programId,
        };
        const response = await AuthRequest.get(
          `System/StudentProfileSettings?${queryString.stringify(params)}`,
        );
        newState.data = response.data.payload;
      } catch (err) {
        newState.error = err;
        newState.success = false;
      }

      dispatch(StudentsActions.setStudentProfileSettings(newState));
    };
  },
  setStudentProfileSettingsLoading(payload) {
    return {
      type: StudentsActions.SET_STUDENT_PROFILE_SETTINGS_LOADING,
      payload,
    };
  },
  setStudentProfileSettings(payload) {
    return {
      type: StudentsActions.SET_STUDENT_PROFILE_SETTINGS,
      payload,
    };
  },

  submitStudentProfileSettings(
    chabadHouseId,
    programId,
    studentProfileSettings,
    refreshSettingsOnSuccess = true,
  ) {
    return async (dispatch) => {
      dispatch(StudentsActions.setSubmitStudentProfileSettingsLoading(true));

      let newState = {
        loading: false,
        success: true,
      };

      try {
        await AuthRequest.post(
          `System/StudentProfileSettings?chabadHouseId=${chabadHouseId}&programId=${programId}`,
          studentProfileSettings,
        );
      } catch (err) {
        newState.error = err;
        newState.errorMessage = ApiCallErrorMessageHandler(err);
        newState.success = false;
      }

      dispatch(StudentsActions.setSubmitStudentProfileSettings(newState));

      if (refreshSettingsOnSuccess) {
        dispatch(
          StudentsActions.getStudentProfileSettings(chabadHouseId, programId),
        );
      }
    };
  },
  setSubmitStudentProfileSettingsLoading(payload) {
    return {
      type: StudentsActions.SET_SUBMIT_STUDENT_PROFILE_SETTINGS_LOADING,
      payload,
    };
  },
  setSubmitStudentProfileSettings(payload) {
    return {
      type: StudentsActions.SET_SUBMIT_STUDENT_PROFILE_SETTINGS,
      payload,
    };
  },

  getStudentsActivity(page, results, filters, isStudentActivityView = false) {
    return async (dispatch) => {
      dispatch(StudentsActions.setStudentsActivityLoading(true));

      let newState = {};
      let success = true;

      const {
        grantScheduleId,
        programType,
        rangeType,
        search,
        sortBy: sortByOption,
        studentId,
      } = filters || {};
      const params = {
        grantScheduleId,
        page,
        programType,
        rangeType,
        results,
        sortByOption,
        studentId,
        text: search,
      };
      if (!isStudentActivityView && studentId) {
        params.studentId = studentId;
      }

      const activityUrl = isStudentActivityView
        ? `Students/${studentId}/Activity`
        : "Interactions";
      const response = await AuthRequest.get(
        `${activityUrl}?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(StudentsActions.setStudentsActivity(newState));
    };
  },
  setStudentsActivityLoading(payload) {
    return {
      type: StudentsActions.SET_STUDENTS_ACTIVITY_LOADING,
      payload,
    };
  },
  setStudentsActivity(payload) {
    return {
      type: StudentsActions.SET_STUDENTS_ACTIVITY,
      payload,
    };
  },

  getStudentInteraction(interactionId) {
    return async (dispatch) => {
      dispatch(StudentsActions.setStudentInteractionLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(
        `Interactions/${interactionId}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(StudentsActions.setStudentInteraction(newState));
    };
  },
  setStudentInteractionLoading(payload) {
    return {
      type: StudentsActions.SET_STUDENT_INTERACTION_LOADING,
      payload,
    };
  },
  setStudentInteraction(payload) {
    return {
      type: StudentsActions.SET_STUDENT_INTERACTION,
      payload,
    };
  },

  submitStudentInteraction(
    interaction,
    checkAdvancedDuplicates,
    studentsFile,
    onUploadProgress,
  ) {
    return async (dispatch) => {
      dispatch(StudentsActions.setSubmitStudentInteractionLoading(true));

      let newState = {};
      let success = true;

      const interactionData = toFormData(interaction);
      if (studentsFile) {
        interactionData.append("studentsFile", studentsFile);
      }

      const response = await AuthRequest.post(
        `Interactions?checkAdvancedDuplicates=${checkAdvancedDuplicates}`,
        interactionData,
        {
          "Content-Type": "multipart/form-data",
          onUploadProgress,
        },
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(StudentsActions.setSubmitStudentInteraction(newState));
    };
  },
  setSubmitStudentInteractionLoading(payload) {
    return {
      type: StudentsActions.SET_SUBMIT_STUDENT_INTERACTION_LOADING,
      payload,
    };
  },
  setSubmitStudentInteraction(payload) {
    return {
      type: StudentsActions.SET_SUBMIT_STUDENT_INTERACTION,
      payload,
    };
  },

  createEventInteractionsForChabadHouse(chabadHouseId) {
    return async (dispatch) => {
      dispatch(
        StudentsActions.setCreateEventInteractionsForChabadHouseLoading(true),
      );

      let newState = {};
      let success = true;

      const response = await AuthRequest.post(
        `ChabadHouses/${chabadHouseId}/eventInteractions`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(
        StudentsActions.setCreateEventInteractionsForChabadHouse(newState),
      );
    };
  },
  setCreateEventInteractionsForChabadHouseLoading(payload) {
    return {
      type: StudentsActions.SET_CREATE_EVENT_INTERACTIONS_FOR_CHABAD_HOUSE_LOADING,
      payload,
    };
  },
  setCreateEventInteractionsForChabadHouse(payload) {
    return {
      type: StudentsActions.SET_CREATE_EVENT_INTERACTIONS_FOR_CHABAD_HOUSE,
      payload,
    };
  },

  deleteStudentInteraction(interactionId) {
    return async (dispatch) => {
      dispatch(StudentsActions.setDeleteStudentInteractionLoading(true));

      let newState = {};
      let success = true;

      await AuthRequest.delete(`Interactions/${interactionId}`).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });
      newState.loading = false;
      newState.success = success;

      dispatch(StudentsActions.setDeleteStudentInteraction(newState));
    };
  },
  setDeleteStudentInteractionLoading(payload) {
    return {
      type: StudentsActions.SET_DELETE_STUDENT_INTERACTION_LOADING,
      payload,
    };
  },
  setDeleteStudentInteraction(payload) {
    return {
      type: StudentsActions.SET_DELETE_STUDENT_INTERACTION,
      payload,
    };
  },

  getStudentInteractions(studentId, rangeType, setting) {
    return async (dispatch) => {
      dispatch(StudentsActions.setStudentInteractionsLoading(true));

      let newState = {};
      let success = true;

      const params = { rangeType, setting };
      const response = await AuthRequest.get(
        `Students/${studentId}/Interactions?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(StudentsActions.setStudentInteractions(newState));
    };
  },
  setStudentInteractionsLoading(payload) {
    return {
      type: StudentsActions.SET_STUDENT_INTERACTIONS_LOADING,
      payload,
    };
  },
  setStudentInteractions(payload) {
    return {
      type: StudentsActions.SET_STUDENT_INTERACTIONS,
      payload,
    };
  },

  getStudentObservance(studentId) {
    return async (dispatch) => {
      dispatch(StudentsActions.setStudentObservanceLoading(true));

      let newState = {};
      let success = true;

      const response = await AuthRequest.get(
        `Students/${studentId}/JewishObservanceOverTime`,
      ).catch((err) => {
        success = false;
        newState = {
          error: err,
          errorMessage: ApiCallErrorMessageHandler(err),
        };
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;

      dispatch(StudentsActions.setStudentObservance(newState));
    };
  },
  setStudentObservanceLoading(payload) {
    return {
      type: StudentsActions.SET_STUDENT_OBSERVANCE_LOADING,
      payload,
    };
  },
  setStudentObservance(payload) {
    return {
      type: StudentsActions.SET_STUDENT_OBSERVANCE,
      payload,
    };
  },

  compareStudentDuplicates: async (ids) => {
    const response = await AuthRequest.get(
      `Students/compare?${queryString.stringify({ ids })}`,
    );
    return response.data.payload || [];
  },
  mergeStudentDuplicates: async (ids, student) => {
    const response = await AuthRequest.post(
      `Students/mergeDuplicates?${queryString.stringify({ ids })}`,
      student,
    );
    return response.data.payload || {};
  },
};
