import { AuthRequest } from "../../lib";

export const UpdateActions = {
  SET_UPDATE_LOADING: "SET_UPDATE_LOADING",
  UPDATE_UPDATE: "UPDATE_UPDATE",

  getPost(id) {
    return async (dispatch) => {
      dispatch(UpdateActions.setUpdateLoading(true));
      let success = true;
      const { data } = await AuthRequest.get(
        `https://chabadoncampus.org/wp-json/wp/v2/posts/${id}?_embed`,
      ).catch((err) => {
        success = false;
        return err;
      });
      const newState = {
        loading: false,
        details: data,
        success,
      };
      dispatch(UpdateActions.updateUpdate(newState));
    };
  },
  setUpdateLoading(payload) {
    return {
      type: UpdateActions.SET_UPDATE_LOADING,
      payload,
    };
  },
  updateUpdate(payload) {
    return {
      type: UpdateActions.UPDATE_UPDATE,
      payload,
    };
  },
};
