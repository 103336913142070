import moment from "moment";
export const defaultValue = " --- ";

export const mergeFields = [
  {
    category: "general",
    name: "firstName",
    label: "First Name",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "general",
    name: "lastName",
    label: "Last Name",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "general",
    name: "isArchived",
    label: "Archived",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "general",
    name: "hebrewName",
    label: "Hebrew Name",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "general",
    name: "dob",
    label: "Birthday",
    getDisplayValue: (value) =>
      moment(value).isValid()
        ? moment.utc(value).format("MM/DD/YYYY")
        : defaultValue,
  },
  {
    category: "general",
    name: "email",
    label: "Email",
    getDisplayValue: (value) =>
      value && value.value ? value.value : defaultValue,
  },
  {
    category: "general",
    name: "phone",
    label: "Phone",
    getDisplayValue: (value) =>
      value && value.value ? value.value : defaultValue,
  },
  {
    category: "general",
    name: "address",
    label: "Address",
    getDisplayValue: (value) =>
      value && value.value ? value.value : defaultValue,
  },
  {
    category: "general",
    name: "gender",
    label: "Gender",
    displayList: "genders",
  },
  {
    category: "general",
    name: "isJewish",
    label: "Student Identifies as Jewish",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "general",
    name: "shirtSize",
    label: "Shirt Size",
    displayList: "tShirtSizes",
  },
  {
    category: "education",
    name: "campus",
    label: "School",
    getDisplayValue: (value) =>
      value && value.value ? value.value : defaultValue,
  },
  {
    category: "education",
    name: "class",
    label: "Demographic",
    displayList: "studentClasses",
  },
  {
    category: "education",
    name: "major",
    label: "Major",
    getDisplayValue: (value) =>
      value && value.value ? value.value : defaultValue,
  },
  {
    category: "education",
    name: "doubleMajor",
    label: "Double Major",
    getDisplayValue: (value) =>
      value && value.value ? value.value : defaultValue,
  },
  {
    category: "education",
    name: "graduatingYear",
    label: "Graduating Year",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "education",
    name: "studentIDNumber",
    label: "School Student ID",
    getDisplayValue: (value) => value || defaultValue,
  },

  {
    category: "allergy",
    name: "hasAllergies",
    label: "Has Allergies",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "allergy",
    name: "vegetarian",
    label: "Vegetarian",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "allergy",
    name: "vegan",
    label: "Vegan",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "allergy",
    name: "glutenFree",
    label: "Gluten Free",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "allergy",
    name: "milkAllergy",
    label: "Milk Allergy",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "allergy",
    name: "treeNutAllergy",
    label: "Tree Nut Allergy",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "allergy",
    name: "peanutAllergy",
    label: "Peanut Allergy",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "allergy",
    name: "wheatAllergy",
    label: "Wheat Allergy",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "allergy",
    name: "fishAllergy",
    label: "Fish Allergy",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "allergy",
    name: "eggAllergy",
    label: "Egg Allergy",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "allergy",
    name: "soyAllergy",
    label: "Soy Allergy",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "allergy",
    name: "sesameAllergy",
    label: "Sesame Allergy",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "allergy",
    name: "otherAllergy",
    label: "Other Allergy",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "allergy",
    name: "dietaryNotes",
    label: "Additional Dietary Information and/or Severity of Allergies",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "jewishStatus",
    name: "cityOfOrigin",
    label: "City of origin",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "jewishStatus",
    name: "cityOfOriginJewishCommunityType",
    label: "City of origin Jewish stats",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "jewishStatus",
    name: "cityOfOriginRegion",
    label: "City of origin region",
    getDisplayValue: (value) =>
      value && value.value ? value.value : defaultValue,
  },
  {
    category: "jewishStatus",
    name: "halachicJewishStatus",
    label: "Halachic Jewish status",
    displayList: "halachicJewishStatuses",
  },
  {
    category: "jewishStatus",
    name: "shliachOverrideJewishnessStatus",
    label: "Shliach override - is student Jewish",
    displayList: "jewishnessStatuses",
  },
  {
    category: "jewishStatus",
    name: "shliachOverrideJewishnessNote",
    label: "Shliach override note",
  },
  {
    category: "jewishStatus",
    name: "familyJewishAffiliation",
    label: "Family Jewish affiliation",
    displayList: "jewishFamilyAffiliationTypes",
  },
  {
    category: "jewishStatus",
    name: "childhoodShabbosHolidayStatus",
    label: "Childhood Shabbos/Holiday",
    displayList: "shabbosHolidayObservanceStatuses",
  },
  {
    category: "jewishStatus",
    name: "childhoodSynagogueAttendanceStatus",
    label: "Childhood Synagogue attendance",
    displayList: "participationStatuses",
  },
  {
    category: "jewishStatus",
    name: "childhoodTorahStudyStatus",
    label: "Childhood Torah study",
    displayList: "participationStatuses",
  },
  {
    category: "jewishStatus",
    name: "childhoodTefillinStatus",
    label: "Childhood Tefillin",
    displayList: "participationStatuses",
  },
  {
    category: "jewishStatus",
    name: "childhoodKosherStatus",
    label: "Childhood Kosher",
    displayList: "observanceStatuses",
  },
  {
    category: "jewishStatus",
    name: "childhoodJewishObservanceStatus",
    label: "Childhood observance",
    displayList: "jewishObservanceStatuses",
  },
  {
    category: "currentJewishStatus",
    name: "currentJewishObservanceStatus",
    label: "Current observance",
    displayList: "jewishObservanceStatuses",
    isKeyValue: true,
  },
  {
    category: "currentJewishStatus",
    name: "currentSynagogueAttendanceStatus",
    label: "Synagogue attendance",
    displayList: "participationStatuses",
    isKeyValue: true,
  },
  {
    category: "currentJewishStatus",
    name: "currentTorahStudyStatus",
    label: "Torah study",
    displayList: "participationStatuses",
    isKeyValue: true,
  },
  {
    category: "currentJewishStatus",
    name: "currentShabbosHolidayStatus",
    label: "Shabbos/Holiday",
    displayList: "shabbosHolidayObservanceStatuses",
    isKeyValue: true,
  },
  {
    category: "currentJewishStatus",
    name: "currentTefillinStatus",
    label: "Tefillin",
    displayList: "participationStatuses",
    isKeyValue: true,
  },
  {
    category: "currentJewishStatus",
    name: "currentKosherStatus",
    label: "Kosher",
    displayList: "observanceStatuses",
    isKeyValue: true,
  },
  {
    category: "currentJewishStatus",
    name: "jewishAffiliation",
    label: "Jewish affiliation",
    displayList: "jewishFamilyAffiliationTypes",
    isKeyValue: true,
  },
  {
    category: "currentJewishStatus",
    name: "shabbatDinnerParticipation",
    label: "Shabbat dinner",
    displayList: "participationStatuses",
    isKeyValue: true,
  },
  {
    category: "currentJewishStatus",
    name: "socialEventsParticipation",
    label: "Social events",
    displayList: "participationStatuses",
    isKeyValue: true,
  },
  {
    category: "currentJewishStatus",
    name: "learningEventsParticipation",
    label: "Learning events",
    displayList: "participationStatuses",
    isKeyValue: true,
  },
  {
    category: "currentJewishStatus",
    name: "personalShliachShluchaTimeParticipation",
    label: "Personal shliach time",
    displayList: "participationStatuses",
    isKeyValue: true,
  },
  {
    category: "currentJewishStatus",
    name: "referralSourceDescription",
    label: "Referral source",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "currentJewishStatus",
    name: "notes",
    label: "Notes",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "currentJewishStatus",
    name: "orgInvolvementTypes",
    label: "Org involvement",
    getDisplayValue: (value) =>
      value && value.value.length ? value?.value.join(", ") : defaultValue,
  },
  {
    category: "currentJewishStatus",
    name: "programParticipationTypes",
    label: "Program participation",
    getDisplayValue: (value) =>
      value && value.value.length ? value?.value.join(", ") : defaultValue,
  },
  {
    category: "currentJewishStatus",
    name: "interestTypes",
    label: "Interests",
    getDisplayValue: (value) =>
      value && value.value.length ? value?.value.join(", ") : defaultValue,
  },
  {
    category: "currentJewishStatus",
    name: "groups",
    label: "Student Groups",
    getDisplayValue: (value) =>
      value && value.value.length ? value?.value.join(", ") : defaultValue,
  },
  {
    category: "family",
    name: "fatherBackground",
    label: "Father's Background",
    displayList: "jewishBackgroundTypes",
  },
  {
    category: "family",
    name: "motherBackground",
    label: "Mother's Background",
    displayList: "jewishBackgroundTypes",
  },
  {
    category: "family",
    name: "fatherName",
    label: "Father Name",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "family",
    name: "fatherEmail",
    label: "Father Email",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "family",
    name: "fatherPhone",
    label: "Father Phone",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "family",
    name: "motherName",
    label: "Mother Name",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "family",
    name: "motherEmail",
    label: "Mother Email",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "family",
    name: "motherPhone",
    label: "Mother Phone",
    getDisplayValue: (value) => value || defaultValue,
  },
  {
    category: "family",
    name: "doParentsShareResidence",
    label: "Parents Same Address",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "jewishEducation",
    name: "hebrewHigh",
    label: "Hebrew High",
    getDisplayValue: (value) =>
      value && value.value ? value.value : defaultValue,
  },
  {
    category: "jewishEducation",
    name: "hebrewSchool",
    label: "Hebrew School",
    getDisplayValue: (value) =>
      value && value.value ? value.value : defaultValue,
  },
  {
    category: "jewishEducation",
    name: "jewishDaySchool",
    label: "Jewish Day School",
    getDisplayValue: (value) =>
      value && value.value ? value.value : defaultValue,
  },
  {
    category: "jewishEducation",
    name: "jewishHighSchool",
    label: "Jewish High School",
    getDisplayValue: (value) =>
      value && value.value ? value.value : defaultValue,
  },
  {
    category: "jewishEducation",
    name: "jewishSummerCamp",
    label: "Jewish Summer Camp",
    getDisplayValue: (value) =>
      value && value.value ? value.value : defaultValue,
  },
  {
    category: "jewishEducation",
    name: "otherJewishClasses",
    label: "Other Jewish Classes",
    getDisplayValue: (value) =>
      value && value.value ? value.value : defaultValue,
  },
  {
    category: "jewishEducation",
    name: "hebrewLevel",
    label: "Hebrew Language",
    displayList: "hebrewLevels",
  },
  {
    category: "jewishEducation",
    name: "hadBarBatMitzvah",
    label: "Had Bar/Bat Mitzvah",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "jewishEducation",
    name: "hasTakenIsraelCourses",
    label: "Courses about Israel",
    getDisplayValue: (value) =>
      value == null ? defaultValue : value ? "Yes" : "No",
  },
  {
    category: "jewishEducation",
    name: "jewishIsraelKnowledgeLevel",
    label: "Connection to Israel",
    displayList: "jewishIsraelKnowledgeLevels",
  },
];
