import React from "react";
import { Prompt } from "react-router-dom";
import { Tab, Tabs } from "@material-ui/core";
import {
  ApiCallErrorMessageHandler,
  formatValuesForForm,
  Navigation,
  PageURL,
} from "../../../lib";
import { Formik, Form } from "formik";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";

import GeneralForm from "./general/GeneralForm";
import IncompleteProfileIndicationSetter from "../IncompleteProfileIndicationSetter";
import StudentOutreachForm from "./studentOutreach/StudentOutreachForm";

export default class PersonalFormContainer extends React.PureComponent {
  //remove after updating to post success
  state = {
    errorMessage: false,
    saving: false,
    tabContentShowing: "general",
  };

  async componentDidMount() {
    this.setState({ initialOutreachValues: _cloneDeep(this.props.outreach) });
    let { tab } = this.props.pageRoute.query;
    if (tab === undefined) {
      tab = "general";
      this.navigate(tab);
    } else {
      this.setState({ tabContentShowing: tab });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageRoute !== prevProps.pageRoute) {
      const {
        query: { tab },
      } = this.props.pageRoute;
      const {
        query: { tab: prevTab },
      } = prevProps.pageRoute;
      if (tab !== prevTab) {
        this.setState({ tabContentShowing: tab });
        if (tab === undefined) {
          this.navigate("general");
        }
      }
    }
  }

  shliachDateFields = ["dob", "dateStarted", "dateJoinedCOCI"];

  navigate = (tab) => {
    const {
      pageRoute: { page, params, query },
    } = this.props;
    const url = PageURL.to(page, params, { ...query, tab });
    Navigation.redirect(url);
  };

  validateForm = (values) => {
    const { chabadHouses = [], person, spouse } = values;
    const { contactMethods = [], profileImageURL = "" } = person || {};
    const {
      contactMethods: spouseContactMethods = [],
      profileImageURL: spouseProfileImageURL = "",
    } = spouse || {};
    const { demographics: shliachDemographics, roles: shliachRoles } =
      values.outreach.shliachOutreach;
    const { demographics: spouseDemographics, roles: spouseRoles } =
      values.outreach.spouseOutreach;

    const hasSpouse = !!spouse;
    const errors = {};

    //validate contact methods
    this.validateContactMethods(contactMethods, errors);
    if (hasSpouse) {
      this.validateContactMethods(spouseContactMethods, errors, true);
    }

    //check that there is a profile image
    if (!profileImageURL) {
      errors.missingImageURL = true;
    } else {
      delete errors.missingImageURL;
    }
    if (hasSpouse) {
      //check that there is a spouse profile image
      if (!spouseProfileImageURL) {
        errors.missingSpouseImageURL = true;
      } else {
        delete errors.missingSpouseImageURL;
      }
    }

    //check that phone numbers are valid
    let inputs = [].slice.call(document.getElementsByClassName("phone-input"));
    let invalidInputs = [];
    inputs.map((input) => {
      let invalidInput = input
        .getElementsByTagName("INPUT")[0]
        .hasAttribute("invalid");
      invalidInputs.push(invalidInput);
      return invalidInputs;
    });
    if (invalidInputs.includes(true)) {
      errors.invalidPhoneNumber = true;
    } else {
      delete errors.invalidPhoneNumber;
    }
    //check that there is a primary chabad house
    if (
      chabadHouses &&
      chabadHouses.length &&
      chabadHouses.filter((ch) => ch.isPrimary).length !== 1
    ) {
      errors.noPrimaryChabadHouse = true;
    } else {
      delete errors.noPrimaryChabadHouse;
    }
    //check that shliach outreach has at least one demographic
    if (shliachDemographics.length < 1) {
      errors.shliachDemographics = true;
    } else {
      delete errors.shliachDemographics;
    }
    //check that shliach outreach has at least one role
    if (shliachRoles.length < 1) {
      errors.shliachRoles = true;
    } else {
      delete errors.shliachRoles;
    }
    //check that spouse outreach has at least one demographic
    if (spouseDemographics && spouseDemographics.length < 1) {
      errors.spouseDemographics = true;
    } else {
      delete errors.spouseDemographics;
    }
    //check that spouse outreach has at least one role
    if (spouseRoles && spouseRoles.length < 1) {
      errors.spouseRoles = true;
    } else {
      delete errors.spouseRoles;
    }
    return errors;
  };

  validateContactMethods = (contactMethods, errors, isSpouse) => {
    const incompleteErrorName = isSpouse
      ? "spouseIncompleteContactMethods"
      : "incompleteContactMethods";
    const primaryErrorName = isSpouse
      ? "spousePrimaryContactMethodsError"
      : "primaryContactMethodsError";

    //check that at least one contact method
    if (contactMethods.length < 1) {
      errors[incompleteErrorName] = true;
    } else {
      //check that at least one preferred contact method, one primary email and one primary cell
      let preferred = [];
      let primary = [];
      for (let i = 0; i < contactMethods.length; i++) {
        if (contactMethods[i].isPreferred === true) {
          preferred.push(contactMethods[i].isPreferred);
        }
        if (contactMethods[i].isPrimary === true) {
          primary.push(contactMethods[i].type);
        }
      }
      if (
        !preferred.includes(true) ||
        !primary.includes("Email") ||
        !primary.includes("MobileNumber")
      ) {
        errors[incompleteErrorName] = true;
      } else {
        delete errors[incompleteErrorName];
      }
      let emailCount = 0;
      let mobileCount = 0;
      for (let j = 0; j < primary.length; j++) {
        if (primary[j] === "Email") {
          emailCount++;
        }
        if (primary[j] === "MobileNumber") {
          mobileCount++;
        }
      }
      if (emailCount > 1 || mobileCount > 1) {
        errors[primaryErrorName] = true;
      } else {
        delete errors[primaryErrorName];
      }
    }
  };

  checkForErrors = (errors) => {
    errors = errors && Object.keys(errors);
    if (errors && errors.length > 0) {
      this.setState({ errorMessage: true });
    }
    return;
  };

  onSubmit = async (values, { resetForm }) => {
    this.setState({ saving: true, errorMessage: false });
    const studentOutreachChanges = !_isEqual(
      this.state.initialOutreachValues,
      values.outreach,
    );
    await this.props.actions.submitProfile(values, studentOutreachChanges);
    if (!this.props.submitError) {
      setTimeout(() => {
        const { shliach, family, outreach } = this.props;
        let updatedValues = formatValuesForForm(
          {
            ...shliach,
            family,
            outreach,
          },
          this.shliachDateFields,
        );
        resetForm(updatedValues);
        this.setState({ saving: false });

        if (Navigation.query.after) {
          Navigation.redirect(
            Navigation.query["after"]
              ? decodeURIComponent(Navigation.query["after"])
              : "/",
          );
        }
      }, 1500);
    } else {
      this.setState({ saving: false });
    }
  };

  render() {
    const {
      shliach,
      family,
      isIncomplete,
      outreach,
      user,
      submitError,
      submitted,
      sys,
      ui,
    } = this.props;
    const { errorMessage, saving, tabContentShowing } = this.state;

    return (
      <Formik
        initialValues={formatValuesForForm(
          {
            ...shliach,
            family,
            outreach,
          },
          this.shliachDateFields,
        )}
        validate={this.validateForm}
        onSubmit={this.onSubmit}
        isInitialValid={true}
      >
        {({
          values,
          dirty,
          errors,
          isValid,
          touched,
          handleChange,
          setFieldError,
          setFieldValue,
          setTouched,
        }) => {
          const hasGeneralErrors =
            (errors.person ||
              errors.spouse ||
              errors.family ||
              errors.incompleteContactMethods ||
              errors.missingImageURL ||
              errors.invalidPhoneNumber ||
              errors.noPrimaryChabadHouse) &&
            (touched.person || touched.family);
          const hasOutreachErrors =
            (errors.outreach ||
              errors.shliachDemographics ||
              errors.spouseDemographics ||
              errors.shliachRoles ||
              errors.spouseRoles) &&
            touched.outreach;
          return (
            <Form className="flex tablet-block full-width">
              <Prompt
                when={dirty}
                message={(location) =>
                  location.pathname.includes("/account/personal")
                    ? true
                    : `Your changes have not been saved. Are you sure you want to continue?`
                }
              />
              {isIncomplete && (
                <IncompleteProfileIndicationSetter
                  setTouched={setTouched}
                  touched={touched}
                  values={values}
                />
              )}
              <div className="card profile-form-card mr-24">
                <Tabs
                  className="mb-16 profile-form-tabs-container"
                  variant="scrollable"
                  indicatorColor="primary"
                  textColor="primary"
                  value={tabContentShowing}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor:
                        (tabContentShowing === "general" && hasGeneralErrors) ||
                        (tabContentShowing === "outreach" && hasOutreachErrors)
                          ? "#e94746"
                          : "",
                    },
                  }}
                  onChange={(_, value) => {
                    this.navigate(value);
                  }}
                >
                  <Tab
                    label="General"
                    value="general"
                    className={`${hasGeneralErrors ? "has-errors" : ""}`}
                  />
                  <Tab
                    label="Student Outreach"
                    value="outreach"
                    className={`${hasOutreachErrors ? "has-errors" : ""}`}
                  />
                </Tabs>

                {/* render these fields and hide with css when applicable so Formik is aware these fields exist - for error checking */}
                <div
                  className={
                    tabContentShowing === "outreach"
                      ? ""
                      : "desktop-hidden tablet-hidden mobile-hidden"
                  }
                >
                  <StudentOutreachForm
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    sys={sys}
                    touched={touched}
                    values={values}
                    shliach={shliach}
                    setTouched={setTouched}
                  />
                </div>
                <div
                  className={
                    tabContentShowing === "general"
                      ? ""
                      : "desktop-hidden tablet-hidden mobile-hidden"
                  }
                >
                  <GeneralForm
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    sys={sys}
                    touched={touched}
                    values={values}
                    ui={ui}
                    user={user}
                  />
                </div>
              </div>

              <div style={{ position: "relative" }}>
                <div className="profile-save-btn-container">
                  <button
                    className="btn btn-accent profile-save-btn"
                    type="submit"
                    disabled={!dirty ? true : false}
                    onClick={() => this.checkForErrors(errors)}
                  >
                    {saving ? "Saving..." : "Save"}
                  </button>
                  {submitError ? (
                    <p className="error-message">
                      {ApiCallErrorMessageHandler(
                        submitError,
                        <React.Fragment>
                          Something went wrong. <br /> Please try again.
                        </React.Fragment>,
                      )}
                    </p>
                  ) : submitted && !dirty ? (
                    <p className="accent-text-dark">
                      <i className="material-icons">check</i> All changes saved
                    </p>
                  ) : dirty ? (
                    <p className="accent-text-dark">
                      <i className="material-icons">save</i>
                      Unsaved changes
                    </p>
                  ) : null}
                  {errorMessage && !isValid ? (
                    <p className="error-message">
                      Please review all required fields for both{" "}
                      <span className="fw-700">General</span> and{" "}
                      <span className="fw-700">Student Outreach</span> sections
                    </p>
                  ) : null}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
