import React from "react";

import PaymentProvidersInfo from "./PaymentProvidersInfo";
import NumberFormat from "react-number-format";
import FormInput from "../../../../components/formik/FormInput";
import RadioContainer from "../../../../components/formik/RadioContainer";

export default class OtherInfo extends React.PureComponent {
  state = {
    otherInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        otherInfoShowing: false,
      });
    }
  }

  render() {
    const {
      errors,
      handleChange,
      require,
      sys: { zoningTypes },
      touched,
    } = this.props;
    return (
      <div
        className={
          this.state.otherInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-48 mb-16 flex flex-align-center flex-justify-space"
          onClick={() => {
            this.setState({
              otherInfoShowing: !this.state.otherInfoShowing,
            });
          }}
        >
          Other Information
          <i className="material-icons">
            {this.state.otherInfoShowing
              ? "keyboard_arrow_up"
              : "keyboard_arrow_down"}
          </i>
        </p>
        <div className="profile-form-inputs profile-activities-form-inputs">
          <div className="mb-24">
            <FormInput
              label="What is your Chabad House’s annual budget?"
              labelClassNames={"fw-700 accent-text-dark mb-8 block"}
              name="annualBudget"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => {
                return (
                  <NumberFormat
                    thousandSeparator={true}
                    decimalScale={2}
                    {...field}
                    className="custom-input"
                  />
                );
              }}
            </FormInput>
          </div>
          <div className="mb-24">
            <FormInput
              label="What is the amount your Chabad House fundraises annually?"
              labelClassNames={"fw-700 accent-text-dark mb-8 block"}
              type="number"
              name="totalFundraisedPerYear"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  {...field}
                  className="custom-input"
                />
              )}
            </FormInput>
          </div>
          <div className="mb-24">
            <FormInput
              label="Do you own or rent the Chabad House location?"
              labelClassNames={"fw-700 accent-text-dark mb-8 block"}
              name="ownsChabadHouse"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <RadioContainer
                  errors={errors}
                  touched={touched}
                  {...field}
                  input1={{
                    id: "own",
                    value: true,
                    checked: "true",
                    label: "Own",
                  }}
                  input2={{
                    id: "rent",
                    value: false,
                    checked: "false",
                    label: "Rent",
                  }}
                />
              )}
            </FormInput>
          </div>
          <div className="mb-24">
            <FormInput
              label="What is the Square Footage of your Chabad House?"
              labelClassNames={"fw-700 accent-text-dark mb-8 block"}
              type="number"
              name="squareFootage"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <div
                  className="square-footage-input-container relative"
                  style={{ maxWidth: "200px" }}
                >
                  <NumberFormat
                    thousandSeparator={true}
                    {...field}
                    className="custom-input"
                  />
                </div>
              )}
            </FormInput>
          </div>
          <div className="mb-24">
            <FormInput
              label="What is the zoning for your Chabad House?"
              labelClassNames={"fw-700 accent-text-dark mb-8 block"}
              name="zoning"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) =>
                zoningTypes.map((zoningType) => (
                  <div
                    className="radio-button-container mb-8"
                    key={zoningType.enumValue}
                  >
                    <input
                      type="radio"
                      id={`zoning-${zoningType.enumValue}`}
                      name={field.name}
                      value={zoningType.enumValue}
                      onChange={handleChange}
                      checked={field.value === zoningType.enumValue}
                    />
                    <label htmlFor={`zoning-${zoningType.enumValue}`}>
                      {zoningType.displayValue}
                    </label>
                  </div>
                ))
              }
            </FormInput>
          </div>
          <div className="mb-24">
            <FormInput
              label="Does your Chabad House run a Hebrew School?"
              labelClassNames={"fw-700 accent-text-dark mb-8 block"}
              name="hebrewSchool"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <RadioContainer
                  errors={errors}
                  touched={touched}
                  {...field}
                  input1={{
                    id: "hebrew-school-yes",
                    value: true,
                    checked: "true",
                    label: "Yes",
                  }}
                  input2={{
                    id: "hebrew-school-no",
                    value: false,
                    checked: "false",
                    label: "No",
                  }}
                />
              )}
            </FormInput>
          </div>
          <div className="mb-24">
            <FormInput
              label="Does your Chabad House have a student board?"
              labelClassNames={"fw-700 accent-text-dark mb-8 block"}
              name="studentBoard"
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <RadioContainer
                  errors={errors}
                  touched={touched}
                  {...field}
                  input1={{
                    id: "school-board-yes",
                    value: true,
                    checked: "true",
                    label: "Yes",
                  }}
                  input2={{
                    id: "school-board-no",
                    value: false,
                    checked: "false",
                    label: "No",
                  }}
                />
              )}
            </FormInput>
          </div>
          <PaymentProvidersInfo errors={errors} touched={touched} />
        </div>
      </div>
    );
  }
}
