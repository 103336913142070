import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import {
  FormDateField,
  FormFieldArray,
  FormFieldWatch,
  FormGoogleAddressField,
  FormSelectField,
  FormTextField,
  FormToggleField,
  getSetFieldValueOptions,
} from "../../../../../components/react-hook-form";
import { Genders, ProfileFormSectionProps } from "../../ProfileFormHelpers";
import {
  getProfileSectionId,
  profileSectionClassName,
} from "../../ProfileFormOutlineHelpers";
import {
  ProfileFormGridItemStyled,
  ProfileFormGridHalfRowItemStyled,
  ProfileFormGridQuarterRowItemStyled,
  ProfileFormGridStyled,
  ProfileFormInfoIcon,
} from "../../ProfileForm.styles";
import { SystemSelectors } from "../../../../../state";
import { ProfileFormSectionWithDelete } from "../../ProfileFormSectionWithDelete";

export const FamilyInfo = React.memo(
  /**
   *
   */
  function FamilyInfo(props: ProfileFormSectionProps) {
    const { form } = props;

    const countries = useSelector(SystemSelectors.countries);
    const schoolTypes = useSelector(SystemSelectors.schoolTypes);
    const youthAndAdultTShirtSizes = useSelector(
      SystemSelectors.youthAndAdultTShirtSizes,
    );

    const familyAddressFieldsAccessor = "family.address";
    const familyChildrenFieldsAccessor = "family.children";

    return (
      <Box
        className={profileSectionClassName}
        id={getProfileSectionId("family")}
      >
        <Typography variant="h6">Family information</Typography>
        <ProfileFormGridStyled
          style={{ marginTop: "24px", marginBottom: "36px" }}
        >
          <ProfileFormGridHalfRowItemStyled>
            <FormGoogleAddressField
              name={`${familyAddressFieldsAccessor}.address1`}
              label="Home address"
              onSelect={(address) =>
                form.setValue(
                  familyAddressFieldsAccessor,
                  {
                    ...address,
                    hideFromPublic:
                      form.getValues().family?.address?.hideFromPublic ?? false,
                  },
                  getSetFieldValueOptions(form),
                )
              }
            />
          </ProfileFormGridHalfRowItemStyled>
          <ProfileFormGridHalfRowItemStyled>
            <FormTextField
              name={`${familyAddressFieldsAccessor}.address2`}
              label="Address line 2"
            />
          </ProfileFormGridHalfRowItemStyled>
          <ProfileFormGridQuarterRowItemStyled>
            <FormSelectField
              name={`${familyAddressFieldsAccessor}.country`}
              label="Country"
              options={countries.map((c: any) => ({
                id: c.name,
                name: c.name,
              }))}
              onValueChange={() =>
                form.setValue(
                  `${familyAddressFieldsAccessor}.state`,
                  "",
                  getSetFieldValueOptions(form),
                )
              }
            />
          </ProfileFormGridQuarterRowItemStyled>
          <ProfileFormGridQuarterRowItemStyled>
            <FormTextField
              name={`${familyAddressFieldsAccessor}.city`}
              label="City"
            />
          </ProfileFormGridQuarterRowItemStyled>
          <ProfileFormGridQuarterRowItemStyled>
            <FormFieldWatch name={`${familyAddressFieldsAccessor}.country`}>
              {(country) => {
                const useStatesSelect =
                  country === "USA" ||
                  country === "Canada" ||
                  country === "Mexico";

                if (useStatesSelect) {
                  const states =
                    countries.find((c: any) => c.name === country)?.states ||
                    [];
                  return (
                    <FormSelectField
                      name={`${familyAddressFieldsAccessor}.state`}
                      label="State"
                      options={states.map((s: any) => ({
                        id: s.name,
                        name: s.name,
                      }))}
                    />
                  );
                }

                return (
                  <FormTextField
                    name={`${familyAddressFieldsAccessor}.state`}
                    label="State"
                  />
                );
              }}
            </FormFieldWatch>
          </ProfileFormGridQuarterRowItemStyled>
          <ProfileFormGridQuarterRowItemStyled>
            <FormTextField
              name={`${familyAddressFieldsAccessor}.zip`}
              label="Zip"
            />
          </ProfileFormGridQuarterRowItemStyled>
        </ProfileFormGridStyled>
        <FormFieldArray name={familyChildrenFieldsAccessor}>
          {(fieldArray) => (
            <>
              <Typography variant="subtitle1">
                Children
                <IconButton
                  color="primary"
                  onClick={() =>
                    fieldArray.prepend({
                      person: {
                        firstName: "",
                        lastName: form.getValues().person?.lastName || "",
                        dob: "",
                        beforeNightfall: "",
                        gender: "",
                        hebrewName: "",
                        hebrewDOB: "",
                      },
                      schoolType: "",
                      tShirtSize: "",
                    })
                  }
                >
                  <AddCircle />
                </IconButton>
              </Typography>
              {fieldArray.fields?.map((field, index) => (
                <ProfileFormSectionWithDelete
                  key={field.id}
                  onDelete={() => fieldArray.remove(index)}
                >
                  <ProfileFormGridItemStyled>
                    <FormTextField
                      label="First name"
                      name={`${familyChildrenFieldsAccessor}.${index}.person.firstName`}
                    />
                  </ProfileFormGridItemStyled>
                  <ProfileFormGridItemStyled>
                    <FormToggleField
                      color="secondary"
                      label="Gender"
                      name={`${familyChildrenFieldsAccessor}.${index}.person.gender`}
                      options={[
                        {
                          id: Genders.Male,
                          name: Genders.Male,
                        },
                        {
                          id: Genders.Female,
                          name: Genders.Female,
                        },
                      ]}
                    />
                  </ProfileFormGridItemStyled>
                  <ProfileFormGridItemStyled>
                    <FormTextField
                      label="Hebrew name"
                      name={`${familyChildrenFieldsAccessor}.${index}.person.hebrewName`}
                      placeholder="ex: Chana bas Rivkah"
                    />
                  </ProfileFormGridItemStyled>
                  <ProfileFormGridItemStyled>
                    <FormDateField
                      name={`${familyChildrenFieldsAccessor}.${index}.person.dob`}
                      label="Birthday"
                    />
                  </ProfileFormGridItemStyled>
                  <ProfileFormGridItemStyled>
                    <FormToggleField
                      color="secondary"
                      label="Nightfall/Sunset"
                      name={`${familyChildrenFieldsAccessor}.${index}.person.beforeNightfall`}
                      options={[
                        {
                          id: true,
                          name: "Before",
                        },
                        {
                          id: false,
                          name: "After",
                        },
                      ]}
                    />
                  </ProfileFormGridItemStyled>
                  <ProfileFormGridItemStyled>
                    <InputLabel>
                      Hebrew birthday{" "}
                      <Tooltip title=" Hebrew Birthday is calculated based on Birthday and Nightfall/Sunset. It will update on save.">
                        <ProfileFormInfoIcon />
                      </Tooltip>
                    </InputLabel>
                    <FormFieldWatch
                      name={`${familyChildrenFieldsAccessor}.${index}.person.hebrewDOB`}
                    >
                      {(hebrewDOB) => <Typography>{hebrewDOB}</Typography>}
                    </FormFieldWatch>
                  </ProfileFormGridItemStyled>
                  <ProfileFormGridItemStyled>
                    <FormSelectField
                      label="School type"
                      name={`${familyChildrenFieldsAccessor}.${index}.schoolType`}
                      options={schoolTypes.map((t: any) => ({
                        id: t.enumValue,
                        name: t.displayValue,
                      }))}
                    />
                  </ProfileFormGridItemStyled>
                  <ProfileFormGridItemStyled>
                    <FormSelectField
                      ignoreInvalid={true}
                      label="T-shirt size"
                      name={`${familyChildrenFieldsAccessor}.${index}.tShirtSize`}
                      options={youthAndAdultTShirtSizes.map((s: any) => ({
                        id: s.enumValue,
                        name: s.displayValue,
                      }))}
                    />
                  </ProfileFormGridItemStyled>
                </ProfileFormSectionWithDelete>
              ))}
            </>
          )}
        </FormFieldArray>
      </Box>
    );
  },
);
