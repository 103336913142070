import React from "react";
import { useFormState } from "react-hook-form";
import { Box, Button } from "@material-ui/core";
import { FormSubmissionWrapper } from "../../../components/react-hook-form";
import { ErrorMessageStyled } from "./ProfileForm.styles";
import { requiredMessage } from "./ProfileFormHelpers";
import { pickValues } from "../../../lib";

export const ProfileFormSave = React.memo(
  /**
   *
   */
  function ProfileFormSave(props: { submitErrorMessage: string }) {
    const { submitErrorMessage } = props;
    //i don't know why but isDirty is not changing unless we get dirty fields here also
    const { errors, isSubmitting, dirtyFields, isDirty } = useFormState();

    const errorMessages = pickValues(errors, "message");

    const validationMessages = errorMessages.filter(
      (m) => !m.includes(requiredMessage), // TODO: error mapping to get complete desired name
    );
    const requiredFields = errorMessages
      .filter((m) => m.includes(requiredMessage))
      .map((m) => m.replace(` ${requiredMessage}`, ""));

    const saveButtonEnabled = isDirty && !isSubmitting;

    return (
      <Box>
        <FormSubmissionWrapper>
          {(onSubmit) => (
            <Button
              color="primary"
              disabled={!saveButtonEnabled}
              onClick={onSubmit}
              type="submit"
              variant="contained"
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          )}
        </FormSubmissionWrapper>
        {submitErrorMessage && (
          <ErrorMessageStyled>{submitErrorMessage} </ErrorMessageStyled>
        )}

        {!!errorMessages.length && (
          <ErrorMessageStyled>
            {!!requiredFields.length && (
              <>
                The following required fields need to be completed before
                saving:{"\n"}
                {requiredFields.join("\n")}
                {validationMessages.length ? "\n" : ""}
              </>
            )}
            {!!validationMessages.length && (
              <>
                Please address the following before saving:{"\n"}
                {validationMessages.join("\n")}
              </>
            )}
          </ErrorMessageStyled>
        )}
      </Box>
    );
  },
);
