import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Tooltip, Typography } from "@material-ui/core";
import {
  FormCheckboxField,
  FormErrorsWatch,
  FormFieldArray,
  FormSelectField,
  FormToggleField,
  getSetFieldValueOptions,
} from "../../../../../components/react-hook-form";
import { ChabadHouseInfoProps } from "./ChabadHouseInfo";
import {
  ProfileFormGridStyled,
  ProfileFormInfoIcon,
  ProfileFormTypographyFlexStyled,
  ProfileFormGridItemFlexStyled,
  ProfileFormGridQuarterRowItemStyled,
} from "../../ProfileForm.styles";
import { SystemSelectors } from "../../../../../state";

export const ChabadHouseCampusesInfo = React.memo(
  /**
   *
   */
  function ChabadHouseCampusesInfo(props: ChabadHouseInfoProps) {
    const { chabadHouseIndex, form } = props;

    const chabadHouseCampusesFieldsAccessor = `chabadHouses.${chabadHouseIndex}.campuses`;

    const distances = useSelector(SystemSelectors.distances);
    return (
      <>
        <ProfileFormTypographyFlexStyled variant="subtitle2">
          Associated schools
          <Tooltip
            title={
              <>
                To add or remove a school, contact{" "}
                <a
                  href="mailto:help@chabadoncampus.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  help@chabadoncampus.org
                </a>
              </>
            }
          >
            <ProfileFormInfoIcon />
          </Tooltip>
        </ProfileFormTypographyFlexStyled>
        <FormErrorsWatch>
          {(errors) => {
            if (errors?.chabadHouses?.length) {
              return (
                <Typography color="error" id="campuses" variant="caption">
                  {errors.chabadHouses[chabadHouseIndex]?.campuses?.message}
                </Typography>
              );
            }
          }}
        </FormErrorsWatch>

        <ProfileFormGridStyled
          style={{ marginTop: "16px", marginBottom: "42px" }}
        >
          <ProfileFormGridQuarterRowItemStyled>
            Schools associated with this Chabad house
          </ProfileFormGridQuarterRowItemStyled>
          <ProfileFormGridQuarterRowItemStyled>
            Primary
          </ProfileFormGridQuarterRowItemStyled>
          <ProfileFormGridItemFlexStyled sm={3}>
            Full/limited service
            <Tooltip
              title={
                <>
                  Full Service School: The vast majority of your Shlichus is
                  dedicated to this university. You live close to the campus,
                  create your programs based on the school schedule, and the
                  majority of people who attend your Chabad House are from this
                  university.
                  <br />
                  <br />
                  Limited Service School: You offer programs to students at this
                  university. However, you do not base your programs on the
                  school schedule and the majority of people who attend your
                  Chabad House are not from this university.
                </>
              }
            >
              <ProfileFormInfoIcon />
            </Tooltip>
          </ProfileFormGridItemFlexStyled>
          <ProfileFormGridQuarterRowItemStyled>
            Distance to Chabad house
          </ProfileFormGridQuarterRowItemStyled>
          <FormFieldArray name={chabadHouseCampusesFieldsAccessor}>
            {(fieldArray) =>
              fieldArray.fields?.map(({ campusName }, index) => (
                <Fragment key={campusName}>
                  <ProfileFormGridQuarterRowItemStyled>
                    <Typography>{campusName}</Typography>
                  </ProfileFormGridQuarterRowItemStyled>
                  <ProfileFormGridQuarterRowItemStyled>
                    <FormCheckboxField
                      name={`${chabadHouseCampusesFieldsAccessor}.${index}.isPrimary`}
                      onValueChange={(val) => {
                        if (val) {
                          // toggle previously primary campus when setting a campus to primary
                          const otherPrimaryCampusIndex = form
                            .getValues()
                            .chabadHouses[chabadHouseIndex].campuses!.findIndex(
                              (c, i) => i !== index && c.isPrimary,
                            );
                          if (otherPrimaryCampusIndex >= 0) {
                            form.setValue(
                              `chabadHouses.${chabadHouseIndex}.campuses.${otherPrimaryCampusIndex}.isPrimary`,
                              false,
                              getSetFieldValueOptions(form),
                            );
                          }
                        }
                      }}
                    />
                  </ProfileFormGridQuarterRowItemStyled>
                  <ProfileFormGridQuarterRowItemStyled>
                    <FormToggleField
                      color="secondary"
                      name={`${chabadHouseCampusesFieldsAccessor}.${index}.isFullTime`}
                      options={[
                        {
                          id: true,
                          name: "FS",
                        },
                        {
                          id: false,
                          name: "LS",
                        },
                      ]}
                    />
                  </ProfileFormGridQuarterRowItemStyled>
                  <ProfileFormGridQuarterRowItemStyled>
                    <FormSelectField
                      name={`${chabadHouseCampusesFieldsAccessor}.${index}.distance`}
                      options={distances.map((d: any) => ({
                        id: d.enumValue,
                        name: d.displayValue,
                      }))}
                    />
                  </ProfileFormGridQuarterRowItemStyled>
                </Fragment>
              ))
            }
          </FormFieldArray>
        </ProfileFormGridStyled>
      </>
    );
  },
);
