import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { formatNumber } from "../../../../lib";
import { EngagementSelectors, SystemSelectors } from "../../../../state";
import {
  getMetricDetailsAccessor,
  getEngagementPeriodLabel,
  StudentsMetricsChartHeader,
} from ".";
import MetricsBarChart from "./MetricsBarChart";
import NoMetrics from "./NoMetrics";
import JewishInterestLevelsLegend from "../../JewishInterestLevelsLegend";
import { getJewishInterestLevelColors } from "../shared";

function formatData(data, engagementPeriodIds) {
  if (!data) return [];
  return engagementPeriodIds.map((engagementPeriodID) => {
    const monthData = {
      engagementPeriodID,
      total: 0,
    };

    data
      .filter(
        (goalMetrics) => goalMetrics.engagementPeriodID === engagementPeriodID,
      )
      .forEach((goalMetrics) => {
        const { goal, goalProgress, jewishInterestLevel } = goalMetrics;
        const goalProgressPercentage = (goalProgress * 100) / goal;

        monthData[jewishInterestLevel] =
          goalProgressPercentage > 100 ? 100 : goalProgressPercentage; //ensure that progress % does not exceed 100
        monthData[getMetricDetailsAccessor(jewishInterestLevel)] = goalMetrics;

        monthData.total += goalProgress;
      });
    return monthData;
  });
}

export default function StudentGoalMetricsChartByMonth(props) {
  const { engagementPeriodIds, engagementPeriods } = props;
  const [formattedData, setFormattedData] = useState([]);

  const { data, total, loading, errorMessage } = useSelector(
    EngagementSelectors.studentGoalMetrics,
  );

  const jewishInterestLevels = useSelector(
    SystemSelectors.engagementJewishInterestLevels,
  );

  useEffect(
    function updateLocalData() {
      if (data) {
        //we are setting the data in local state per this issue where the bar labels do not appear on initial mount when reading directly from props https://github.com/recharts/recharts/issues/829
        setFormattedData(formatData(data, engagementPeriodIds));
      }
    },
    [data, engagementPeriodIds],
  );

  const formatTickY = useCallback(
    (key, data) => {
      const monthYearDisplay = getEngagementPeriodLabel(key, engagementPeriods);
      const total = data.find((d) => d.engagementPeriodID === key)?.total;
      return `${monthYearDisplay}\nTotal: ${formatNumber(total)}`;
    },
    [engagementPeriods],
  );

  const minRowHeight = 80;
  const chartHeight = formattedData.length * minRowHeight;

  return (
    <div className="graph-outline mb-24">
      <StudentsMetricsChartHeader loading={loading} dataTotal={total} />

      <div className="mt-8 mb-16">
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <p className="error-text small-text text-center">{errorMessage}</p>
        ) : !formattedData.length ? (
          <NoMetrics message="No student goal metrics found" />
        ) : (
          <>
            <JewishInterestLevelsLegend
              levelsSelector={SystemSelectors.engagementJewishInterestLevels}
            />
            <MetricsBarChart
              getColors={getJewishInterestLevelColors}
              groupByMonth={true}
              isGoalData={true}
              chartHeight={chartHeight}
              data={formattedData}
              tickYFormatter={(key) => formatTickY(key, formattedData)}
              tickYDataKey={"engagementPeriodID"}
              tickXFormatter={(key) => `${key}%`}
              metrics={jewishInterestLevels}
            />
          </>
        )}
      </div>
    </div>
  );
}
