import React, { useState } from "react";
import { SystemSelectors } from "../../../state";
import Loader from "../../../components/Loader";
import { formatNumber, isMobileView, isTabletView, sum } from "../../../lib";
import _cloneDeep from "lodash.clonedeep";
import { shallowEqual, useSelector } from "react-redux";

export default function EngagementPeriodGoals(props) {
  const {
    engagementPeriodGoals,
    errorMessage,
    hasUnsavedChanges,
    jewishStudentCount,
    loading,
    onCancelChanges,
    onChange,
    submitErrorMessage,
    submitGoals,
    submitLoading,
  } = props;

  const [expandedCategoryIds, setExpandedCategoryIds] = useState([]);

  const jewishInterestLevels = useSelector(
    SystemSelectors.engagementJewishInterestLevels,
  );
  const interactionCategories = useSelector(
    SystemSelectors.sortedInteractionCategories,
    shallowEqual,
  );
  const interactionTypes = useSelector(SystemSelectors.interactionTypes);

  const onChangeStudentGoal = (name, value, index, jewishInterestLevel) => {
    if (value < 0) {
      return;
    }

    const studentGoals = _cloneDeep(engagementPeriodGoals.studentGoals);
    if (index < 0) {
      //create new goal if doesn't exist
      studentGoals.push({
        jewishInterestLevel,
        [name]: value,
      });
    } else {
      studentGoals[index][name] = value;
    }

    onChange({ ...engagementPeriodGoals, studentGoals });
  };

  const onChangeInteractionGoal = (name, value, index, interactionTypeID) => {
    if (value < 0) {
      return;
    }

    const interactionGoals = _cloneDeep(engagementPeriodGoals.interactionGoals);
    if (index < 0) {
      //create new goal if doesn't exist
      interactionGoals.push({
        interactionTypeID,
        [name]: value,
      });
    } else {
      interactionGoals[index][name] = value;
    }

    onChange({ ...engagementPeriodGoals, interactionGoals });
  };

  const toggleShowHideCategory = (categoryId) => {
    const newExpandedCategoryIds =
      expandedCategoryIds.indexOf(categoryId) >= 0
        ? expandedCategoryIds.filter((id) => id !== categoryId)
        : [...expandedCategoryIds, categoryId];

    setExpandedCategoryIds(newExpandedCategoryIds);
  };

  const { didPeriodEnd, interactionGoals, studentGoals } =
    engagementPeriodGoals || {};

  let studentGoalsTotal = 0,
    studentGoalsPercentage = 0;
  if (studentGoals) {
    studentGoalsTotal = sum(studentGoals.map((s) => s.goal));
    studentGoalsPercentage = (studentGoalsTotal * 100) / jewishStudentCount;
  }

  return loading ? (
    <Loader />
  ) : errorMessage ? (
    <div className="error-text small-text mt-24">
      Something went wrong and goal details could not be retrieved. Please try
      again.
    </div>
  ) : (
    engagementPeriodGoals && (
      <div>
        {!didPeriodEnd && !isMobileView() && !isTabletView() && (
          <div className="mb-16">
            <div className="flex flex-align-center flex-justify-end">
              <button
                className="btn btn-light btn-medium"
                disabled={submitLoading || !hasUnsavedChanges}
                style={{ padding: "0 32px" }}
                onClick={onCancelChanges}
              >
                Cancel
              </button>
              <button
                className="btn btn-accent btn-medium ml-16"
                disabled={submitLoading || !hasUnsavedChanges}
                style={{ padding: "0 32px" }}
                onClick={submitGoals}
              >
                {submitLoading ? "Saving..." : "Save"}
              </button>
            </div>
            {submitErrorMessage && (
              <p className="small-text error-text text-right mt-4">
                {submitErrorMessage}
              </p>
            )}
          </div>
        )}
        <div className="mb-24">
          <p className="fw-700 mb-8">Student Goals</p>
          <p className="italic-text accent-text small-text line-height-double mb-8">
            Add goals of students you intend to reach this month
          </p>
          <div className="accent-text small-text">
            {jewishInterestLevels &&
              jewishInterestLevels.map((level, levelIndex) => {
                const goalIndex = studentGoals.findIndex(
                  (g) => g.jewishInterestLevel === level.enumValue,
                );
                const studentGoal = studentGoals[goalIndex] || {};
                return (
                  <div
                    className="flex flex-align-center flex-justify-space"
                    key={levelIndex}
                    style={{ marginBottom: didPeriodEnd ? "12px" : "8px" }}
                  >
                    <p>{level.displayValue}</p>
                    {didPeriodEnd ? (
                      <p>
                        {studentGoal.goal ? formatNumber(studentGoal.goal) : ""}
                      </p>
                    ) : (
                      <input
                        className="custom-input"
                        min="0"
                        name="goal"
                        onChange={(e) =>
                          onChangeStudentGoal(
                            e.target.name,
                            e.target.value,
                            goalIndex,
                            level.enumValue,
                          )
                        }
                        style={{ width: "80px" }}
                        type="number"
                        value={studentGoal.goal || ""}
                      />
                    )}
                  </div>
                );
              })}
            <div className="mobile-text-right">
              <p className="mt-16 mb-8">
                Total: {formatNumber(studentGoalsTotal)} students
              </p>
              {!!jewishStudentCount && (
                <p>
                  {+(studentGoalsPercentage || 0).toFixed(2)}% of Campus Jewish
                  Students
                </p>
              )}
            </div>
          </div>
        </div>
        <div>
          <p className="fw-700 mb-8">Engagement Goals</p>
          <p className="italic-text accent-text small-text line-height-double mb-8">
            Add goals of engagements you intend to have in each category
          </p>
          <div className="accent-text small-text">
            {interactionCategories &&
              interactionCategories.map(
                (interactionCategory, categoryIndex) => {
                  const isExpanded =
                    expandedCategoryIds.indexOf(interactionCategory.id) >= 0;
                  const categoryInteractionTypes = interactionTypes.filter(
                    (t) => t.categoryID === interactionCategory.id,
                  );
                  const categoryGoalsTotal = sum(
                    interactionGoals
                      .filter((g) =>
                        categoryInteractionTypes.find(
                          (t) => t.id === g.interactionTypeID,
                        ),
                      )
                      .map((g) => sum([g.goal])),
                  );
                  return (
                    <div key={categoryIndex}>
                      <div
                        className="flex flex-align-center flex-justify-space mb-8"
                        onClick={() =>
                          toggleShowHideCategory(interactionCategory.id)
                        }
                        style={{
                          cursor: "pointer",
                          borderBottom: "1px solid lightgray",
                          paddingBottom: "6px",
                        }}
                      >
                        <div className="">
                          <p>{interactionCategory.name}</p>
                          <div className="interaction-category-rating mt-8 full-width">
                            {Array(interactionCategory.rating)
                              .fill()
                              .map((_, i) => (
                                <img
                                  alt=""
                                  className="mr-4"
                                  key={i}
                                  src="/images/flame-solid.png"
                                  style={{ height: "12px" }}
                                />
                              ))}
                          </div>
                        </div>
                        <div className="flex flex-align-center">
                          <p className="no-wrap">
                            {formatNumber(categoryGoalsTotal)} total
                          </p>
                          <i className="material-icons accent-text large-text pointer ml-8">
                            {isExpanded ? "expand_less" : "expand_more"}
                          </i>
                        </div>
                      </div>
                      {isExpanded && (
                        <div>
                          {categoryInteractionTypes.map(
                            (interactionType, typeIndex) => {
                              const goalIndex = interactionGoals.findIndex(
                                (g) =>
                                  g.interactionTypeID === interactionType.id,
                              );
                              const interactionGoal =
                                interactionGoals[goalIndex] || {};
                              return (
                                <div
                                  className="flex flex-align-center flex-justify-space"
                                  key={typeIndex}
                                  style={{
                                    marginBottom: didPeriodEnd ? "12px" : "8px",
                                  }}
                                >
                                  <p className="accent-text">
                                    {interactionType.name}
                                  </p>
                                  <div className="flex flex-align-center">
                                    {didPeriodEnd ? (
                                      <p>
                                        {interactionGoal.goal
                                          ? formatNumber(interactionGoal.goal)
                                          : ""}
                                      </p>
                                    ) : (
                                      <input
                                        className="custom-input full-width"
                                        readOnly={didPeriodEnd}
                                        min="0"
                                        name="goal"
                                        onChange={(e) =>
                                          onChangeInteractionGoal(
                                            e.target.name,
                                            e.target.value,
                                            goalIndex,
                                            interactionType.id,
                                          )
                                        }
                                        type="number"
                                        value={interactionGoal.goal || ""}
                                        style={{ width: "80px" }}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            },
                          )}
                        </div>
                      )}
                    </div>
                  );
                },
              )}
          </div>
        </div>
        {!didPeriodEnd && (isMobileView() || isTabletView()) && (
          <div className="mt-16">
            <div className="flex flex-align-center flex-justify-end">
              <button
                className="btn btn-light btn-medium"
                disabled={submitLoading || !hasUnsavedChanges}
                style={{ padding: "0 32px", width: "50%" }}
                onClick={onCancelChanges}
              >
                Cancel
              </button>
              <button
                className="btn btn-accent btn-medium ml-16"
                disabled={submitLoading || !hasUnsavedChanges}
                style={{ padding: "0 32px", width: "50%" }}
                onClick={submitGoals}
              >
                {submitLoading ? "Saving..." : "Save"}
              </button>
            </div>
            {submitErrorMessage && (
              <p className="small-text error-text text-right mt-4">
                {submitErrorMessage}
              </p>
            )}
          </div>
        )}
      </div>
    )
  );
}
