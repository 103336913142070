export const profileSectionClassName = "profile-section";
export const profileMenuItemClassName = "profile-menu-item";

export function getProfileSectionId(
  type:
    | "person"
    | "spouse"
    | "family"
    | "chabadHouseGeneral"
    | "chabadHousePersonnel"
    | "chabadHouseFinancial"
    | "campusGeneral"
    | "campusJewishStudentLife",
  chabadHouseOrCampusIndex?: number,
) {
  return chabadHouseOrCampusIndex
    ? `${type}-${chabadHouseOrCampusIndex}`
    : type;
}

export function getProfileMenuItemId(profileSectionId: string) {
  return `${profileMenuItemClassName}-${profileSectionId}`;
}

// #region Intersection observer

const scrollRoot = document.querySelector("#root");
const header = document.querySelector("header");

let scrollDirection = "up";
let prevPositionY = 0;

const setScrollDirection = () => {
  if (scrollRoot?.scrollTop || 0 > prevPositionY) {
    scrollDirection = "down";
  } else {
    scrollDirection = "up";
  }

  prevPositionY = scrollRoot?.scrollTop || 0;
};

const getTargetSection = (target: any) => {
  if (scrollDirection === "up") return target;

  if (target.nextElementSibling) {
    return target.nextElementSibling;
  } else {
    return target;
  }
};

const shouldUpdateVisibility = (entry: IntersectionObserverEntry) => {
  if (scrollDirection === "down" && !entry.isIntersecting) {
    return true;
  }

  if (scrollDirection === "up" && entry.isIntersecting) {
    return true;
  }

  return false;
};

const onSectionIntersect = (entries: IntersectionObserverEntry[]) => {
  setScrollDirection();

  const menuItems = document.querySelectorAll(`.${profileMenuItemClassName}`);

  entries.forEach((entry) => {
    /* Do nothing if no need to update */
    if (!shouldUpdateVisibility(entry)) return;

    menuItems.forEach((i: Element) => {
      if (i.classList.contains("highlighted"))
        i.classList.remove("highlighted");
    });

    const targetSection = getTargetSection(entry.target);
    const targetMenuItem = document.getElementById(
      getProfileMenuItemId(targetSection.getAttribute("id")),
    );
    targetMenuItem?.classList.add("highlighted");
  });
};

export function setUpProfileOutlineIntersectionObserver() {
  const options = {
    root: scrollRoot,
    rootMargin: `-${header?.offsetHeight || 72}px`,
    threshold: 0,
  };
  const observer = new IntersectionObserver(onSectionIntersect, options);

  const profileSections = document.querySelectorAll(
    `.${profileSectionClassName}`,
  );
  profileSections.forEach((section) => observer.observe(section));
}

// #endregion
