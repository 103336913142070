import React, { useCallback, useState } from "react";
import { Dialog } from "@material-ui/core";
import { DialogHeader } from "../../components";
import Select from "../../components/form/CustomSelect";
import ValidatedInput from "../../components/form/ValidatedInput";
import Toggle from "../../components/form/Toggle";

const calculateJewishness = (motherBackground) => {
  switch (motherBackground) {
    case "Born":
      return "Yes";

    case "Other":
      return "No";

    default:
      return "Unknown";
  }
};

function StudentJewishnessModal(props) {
  const {
    close,
    getJewishnessStatusIcon,
    jewishBackgroundTypes,
    onSubmit,
    show,
    student,
    student: {
      demographics: {
        jewishnessStatus: _jewishnessStatus,
        shliachOverrideJewishnessStatus,
        shliachOverrideJewishnessNote,
      } = {},
      motherBackground,
      fatherBackground,
    } = {},
    submitStudent,
  } = props;
  const [jewishnessFields, setJewishnessFields] = useState({
    shliachOverrideJewishnessStatus,
    shliachOverrideJewishnessNote,
    motherBackground,
    fatherBackground,
  });
  const [calculatedJewishnessStatus, setCalculatedJewishnessStatus] =
    useState(_jewishnessStatus); //separate field calculated dynamically, based on motherBackground and shliachOverrideJewishnessStatus
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [hasShliachOverride, setHasShliachOverride] = useState(
    !!shliachOverrideJewishnessStatus,
  );

  const onChange = useCallback(
    (name, value) => {
      if (name === "shliachOverrideJewishnessStatus") {
        setCalculatedJewishnessStatus(
          value || calculateJewishness(jewishnessFields.motherBackground),
        );
      } else if (
        name === "motherBackground" &&
        !jewishnessFields.shliachOverrideJewishnessStatus
      ) {
        setCalculatedJewishnessStatus(calculateJewishness(value));
      }
      setJewishnessFields({ ...jewishnessFields, [name]: value });
    },
    [jewishnessFields],
  );
  const toggleShliachOverride = useCallback(
    (value) => {
      if (!value) {
        setCalculatedJewishnessStatus(
          calculateJewishness(jewishnessFields.motherBackground),
        );
        setJewishnessFields({
          ...jewishnessFields,
          shliachOverrideJewishnessStatus: null,
          shliachOverrideJewishnessNote: null,
        });
      }
      setHasShliachOverride(value);
    },
    [jewishnessFields],
  );
  const onSave = useCallback(async () => {
    setLoading(true);
    const results = await submitStudent({
      ...student,
      motherBackground: jewishnessFields.motherBackground,
      fatherBackground: jewishnessFields.fatherBackground,
      demographics: {
        ...student.demographics,
        shliachOverrideJewishnessStatus:
          jewishnessFields.shliachOverrideJewishnessStatus,
        shliachOverrideJewishnessNote:
          jewishnessFields.shliachOverrideJewishnessNote,
      },
    });

    if (results.success) {
      close();
      onSubmit();
    } else if (results.errorMessage) {
      setErrorMessage(results.errorMessage);
    }
    setLoading(false);
  }, [jewishnessFields, close, onSubmit, student, submitStudent]);
  return (
    <Dialog open={show} fullWidth maxWidth="sm">
      <DialogHeader
        title={
          <div className="flex">
            Jewishness details
            <div className="ml-8">
              {getJewishnessStatusIcon(calculatedJewishnessStatus)}
            </div>
          </div>
        }
        handleClose={close}
      />
      <form className="profile-form">
        <div className="container" key="jewishness-modal">
          <div className="profile-form-section">
            <p className="accent-text mb-24">
              Complete these fields to update the student's Jewishness.
            </p>
            <p className="profile-section-title mb-12">Student profile</p>
            <div className="profile-form-grid parent-contact-info-grid mb-24">
              <div className="relative">
                <ValidatedInput
                  className="input-container"
                  input={
                    <Select
                      classNamePrefix="input-container-select"
                      isClearable={true}
                      options={jewishBackgroundTypes.map((bg) => ({
                        label: bg.displayValue,
                        value: bg.enumValue,
                      }))}
                    />
                  }
                  label="Father's background"
                  name="fatherBackground"
                  onChange={onChange}
                  value={jewishnessFields.fatherBackground}
                />
              </div>
              <div className="relative">
                <ValidatedInput
                  className="input-container"
                  input={
                    <Select
                      classNamePrefix="input-container-select"
                      isClearable={true}
                      options={jewishBackgroundTypes.map((bg) => ({
                        label: bg.displayValue,
                        value: bg.enumValue,
                      }))}
                    />
                  }
                  label="Mother's background"
                  name="motherBackground"
                  onChange={onChange}
                  value={jewishnessFields.motherBackground}
                />
              </div>
            </div>
            <p className="profile-section-title mb-12">
              <input
                id="shliach-override"
                className="custom-checkbox"
                checked={!!hasShliachOverride}
                name="shliachOverride"
                onChange={(e) => toggleShliachOverride(e.target.checked)}
                type="checkbox"
              />
              <label htmlFor="shliach-override">Shliach override</label>
            </p>
            {hasShliachOverride && (
              <>
                <div className="mb-12">Jewish?</div>
                <Toggle
                  className="jewishness-toggle mb-24"
                  name="shliachOverrideJewishnessStatus"
                  allowNoOption={true}
                  noOptionValue={null}
                  onChange={onChange}
                  options={[
                    {
                      value: "Yes",
                      display: "Yes",
                    },
                    {
                      value: "No",
                      display: "No",
                    },
                    {
                      value: "Unknown",
                      display: "Unknown",
                    },
                  ]}
                  value={jewishnessFields.shliachOverrideJewishnessStatus}
                />

                <textarea
                  className="custom-input"
                  type="text"
                  name="shliachOverrideJewishnessNote"
                  value={jewishnessFields.shliachOverrideJewishnessNote}
                  placeholder="Override note"
                  onChange={(e) =>
                    onChange("shliachOverrideJewishnessNote", e.target.value)
                  }
                />
              </>
            )}
          </div>
          <div className="text-right mb-24">
            <button
              className="btn btn-light btn-medium"
              disabled={loading}
              onClick={close}
              type="button"
            >
              Cancel
            </button>

            <button
              className="btn btn-accent btn-medium ml-16 mobile-ml-0 mobile-mt-8"
              disabled={loading}
              type="button"
              onClick={onSave}
            >
              Save
            </button>

            {errorMessage && (
              <div className="mt-4 error-text">{errorMessage}</div>
            )}
          </div>
        </div>
      </form>
    </Dialog>
  );
}
export default React.memo(StudentJewishnessModal);
