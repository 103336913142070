import React from "react";

import { FieldArray } from "formik";
import CustomSelect from "../../../../components/formik/CustomSelect";
import FormInputUpdate from "../../../../components/formik/FormInputUpdate";
import PhoneInput from "../../../../components/formik/PhoneInput";

export default class ContactInfo extends React.PureComponent {
  state = {
    contactInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        contactInfoShowing: false,
      });
    }
  }

  contactMethodsTouched = (touched) => {
    return (
      touched &&
      touched.contactMethods &&
      touched.contactMethods.map((cm) => cm && cm.value).indexOf(true) >= 0
    );
  };

  validatePhoneInput = (value, contactMethod) => {
    if (!value) {
      return true;
    }
    let input = document
      .getElementsByClassName("phone-input")[0]
      .getElementsByTagName("INPUT")[0]
      .hasAttribute("invalid");
    if (input) {
      return "Invalid Phone Number";
    }
  };

  render() {
    const {
      errors,
      require,
      touched,
      values,
      validateContactMethod,
      setFieldError,
      setFieldValue,
      sys: { countries, chabadHouseContactMethodTypes },
    } = this.props;
    const contactMethodsTouched = this.contactMethodsTouched(touched);
    return (
      <div
        className={
          this.state.contactInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <FieldArray name="contactMethods">
          {(arrayHelpers) => (
            <React.Fragment>
              <div className="flex mobile-flex-justify-space flex-align-center mb-8 mt-48">
                <div className="flex flex-align-center">
                  <p
                    className="accent-text-dark medium-text fw-700"
                    onClick={() => {
                      this.setState({
                        contactInfoShowing: !this.state.contactInfoShowing,
                      });
                    }}
                  >
                    Contact Information
                  </p>
                  <i
                    className={`material-icons link-text profile-add-icon ml-16 ${
                      !this.state.contactInfoShowing ? "hidden" : ""
                    }`}
                    onClick={() =>
                      arrayHelpers.unshift({
                        isPreferred: false,
                        type: "",
                        value: "",
                      })
                    }
                  >
                    add_circle
                  </i>
                </div>
                <i
                  className="material-icons collapsible"
                  onClick={() => {
                    this.setState({
                      contactInfoShowing: !this.state.contactInfoShowing,
                    });
                  }}
                >
                  {this.state.contactInfoShowing
                    ? "keyboard_arrow_up"
                    : "keyboard_arrow_down"}
                </i>
              </div>
              <div className="profile-form-contact-info ch-form-contact-info">
                <p className="small-text accent-text mb-16">
                  <span
                    className={
                      errors.incompleteContactMethods && contactMethodsTouched
                        ? "contact-error-message"
                        : ""
                    }
                  >
                    One number is required. You may add additional contact info.
                  </span>
                </p>
                {values.contactMethods &&
                  values.contactMethods.map((contactMethod, index) => (
                    <div
                      key={index}
                      className="flex flex-align-center mb-16 hover-delete-container mobile-card"
                    >
                      <FormInputUpdate
                        name={`contactMethods[${index}].type`}
                        validate={require}
                        errors={errors}
                        touched={touched}
                        className="custom-input mr-12"
                        style={{ flex: "2" }}
                      >
                        {({ field }) => (
                          <div className="flex flex-align-center">
                            {contactMethod.type === "MessagingNumber" &&
                            contactMethod.id ? (
                              <div
                                style={{
                                  border: "1px solid #edecec",
                                  borderRadius: 4,
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "0 8px",
                                  fontSize: 13,
                                  height: "100%",
                                  width: "100%",
                                }}
                              >
                                Messaging
                              </div>
                            ) : (
                              <CustomSelect
                                placeholder="Select..."
                                errors={errors}
                                touched={touched}
                                {...field}
                                options={
                                  chabadHouseContactMethodTypes &&
                                  chabadHouseContactMethodTypes.map((t) => ({
                                    key: t.enumValue,
                                    value: t.enumValue,
                                    label: t.displayValue,
                                  }))
                                }
                                setFieldValue={setFieldValue}
                              />
                            )}

                            {contactMethod.type === "MessagingNumber" && (
                              <div className="tooltip-container ml-8">
                                <i className="material-icons link-text no-hover large-text">
                                  info
                                </i>
                                <span className="tooltip">
                                  The number that students can send messages to
                                  for the automated messaging service, for ex,
                                  for RSVP
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </FormInputUpdate>
                      <div className="relative">
                        {contactMethod.type === "" ||
                        contactMethod.type === "Email" ? (
                          <FormInputUpdate
                            type="Email"
                            name={`contactMethods[${index}].value`}
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-24"
                            style={{ flex: "3" }}
                            validate={(value) =>
                              validateContactMethod(value, contactMethod)
                            }
                          />
                        ) : (
                          <FormInputUpdate
                            name={`contactMethods[${index}].value`}
                            validate={(value) =>
                              this.validatePhoneInput(value, contactMethod)
                            }
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-12"
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <PhoneInput
                                {...field}
                                countries={countries || []}
                                countryFieldName={`contactMethods[${index}].phoneCountryID`}
                                countryId={contactMethod.phoneCountryID}
                                defaultCountryName={
                                  values.address && values.address.country
                                }
                                errors={errors}
                                readOnly={
                                  contactMethod.type === "MessagingNumber" &&
                                  contactMethod.id
                                }
                                setFieldError={setFieldError}
                                setFieldValue={setFieldValue}
                                touched={touched}
                                value={contactMethod.value}
                              />
                            )}
                          </FormInputUpdate>
                        )}
                      </div>
                      <div className="relative flex flex-align-center">
                        {contactMethod.type === "WorkNumber" ? (
                          <FormInputUpdate
                            label="Ext"
                            type="number"
                            name={`contactMethods[${index}].phoneExtension`}
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-24"
                          />
                        ) : null}
                      </div>
                      <div className="flex flex-align-center">
                        {contactMethod.type === "MessagingNumber" &&
                        contactMethod.id
                          ? null
                          : !(
                              contactMethod.type === "WorkNumber" &&
                              values.contactMethods.filter(
                                (cm) => cm.type === contactMethod.type,
                              ).length === 1
                            ) && (
                              <i
                                className="material-icons link-text hover-delete"
                                style={{ fontSize: "16px", width: "20px" }}
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                delete
                              </i>
                            )}
                      </div>
                    </div>
                  ))}
              </div>
            </React.Fragment>
          )}
        </FieldArray>
      </div>
    );
  }
}
