import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  InputLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  FormFieldWatch,
  FormFileUploadField,
  FormSelectField,
  FormTextField,
  FormCheckboxField,
  getSetFieldValueOptions,
} from "../../../../../components/react-hook-form";
import { ChabadHouseInfoProps } from "./ChabadHouseInfo";
import {
  FormUploadContainerStyled,
  ProfileFormGridItemStyled,
  ProfileFormGridStyled,
  ProfileFormInfoIcon,
  ProfileFormGridHalfRowItemStyled,
} from "../../ProfileForm.styles";
import { SystemSelectors } from "../../../../../state";
import { PROFILE_PLACEHOLDER_URL, slugify } from "../../../../../lib";
import { ChipStyled } from "../../../../../components";

export const ChabadHouseGeneralInfo = React.memo(
  /**
   *
   */
  function ChabadHouseGeneralInfo(props: ChabadHouseInfoProps) {
    const { chabadHouseIndex, form, initialFormValues } = props;

    const chabadHouseFieldsAccessor = `chabadHouses.${chabadHouseIndex}`;

    const initialChabadHouseProfile =
      initialFormValues.chabadHouses[chabadHouseIndex];

    const regions = useSelector(SystemSelectors.regions);

    const handleChangeSlug = useCallback(
      (value: string, blurred = false) => {
        form.setValue(
          `chabadHouses.${chabadHouseIndex}.slug`,
          slugify(value, blurred), // trim trailing slashes on blur
          getSetFieldValueOptions(form),
        );
      },
      [chabadHouseIndex, form],
    );

    const [
      chabadHouseLegalNameMatchesName,
      setChabadHouseLegalNameMatchesName,
    ] = useState(false);

    return (
      <ProfileFormGridStyled
        style={{ marginTop: "24px", marginBottom: "42px" }}
      >
        <ProfileFormGridHalfRowItemStyled>
          {/* TODO: upload image to storage UPON SUBMISSION */}
          <FormFileUploadField
            image={true}
            name={`${chabadHouseFieldsAccessor}.logoURL`}
            uploadComponent={(fileOrUrl) => (
              <FormUploadContainerStyled>
                <Avatar
                  alt="logo"
                  src={
                    !fileOrUrl
                      ? PROFILE_PLACEHOLDER_URL
                      : typeof fileOrUrl === "string"
                      ? fileOrUrl
                      : fileOrUrl.previewUrl
                  }
                />
                <Typography variant="body2">
                  {fileOrUrl ? "Change" : "Upload"} logo
                </Typography>
              </FormUploadContainerStyled>
            )}
          />
        </ProfileFormGridHalfRowItemStyled>
        <ProfileFormGridHalfRowItemStyled>
          {/* TODO: upload image to storage UPON SUBMISSION */}
          <FormFileUploadField
            image={true}
            name={`${chabadHouseFieldsAccessor}.buildingImageURL`}
            uploadComponent={(fileOrUrl) => (
              <FormUploadContainerStyled>
                <Avatar
                  alt="logo"
                  src={
                    !fileOrUrl
                      ? PROFILE_PLACEHOLDER_URL
                      : typeof fileOrUrl === "string"
                      ? fileOrUrl
                      : fileOrUrl.previewUrl
                  }
                />
                <Typography variant="body2">
                  {fileOrUrl ? "Change" : "Upload"} building image
                </Typography>
              </FormUploadContainerStyled>
            )}
          />
        </ProfileFormGridHalfRowItemStyled>
        <FormFieldWatch
          name={[
            "person.outreach.demographics",
            "spouse.outreach.demographics",
          ]}
        >
          {([shliachDemographics, spouseDemographics]) => {
            const demographics = Array.from(
              new Set([...shliachDemographics, ...spouseDemographics]),
            );
            return (
              <ProfileFormGridItemStyled>
                <InputLabel>Demographics served</InputLabel>
                <Box>
                  {demographics.map((d: string, i: number) => (
                    <ChipStyled key={i} label={d} />
                  ))}
                </Box>
              </ProfileFormGridItemStyled>
            );
          }}
        </FormFieldWatch>

        <ProfileFormGridItemStyled sm={8}>
          <FormSelectField
            label="Region"
            name={`${chabadHouseFieldsAccessor}.regionID`}
            options={regions.map((r: any) => ({
              id: r.id,
              name: r.name,
            }))}
          />
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <FormTextField
            label={
              <>
                URL friendly name
                <Tooltip title="This is a URL friendly version of the Chabad House Name for use in web page links">
                  <ProfileFormInfoIcon />
                </Tooltip>
              </>
            }
            name={`${chabadHouseFieldsAccessor}.slug`}
            // slugify the value on blur
            onBlur={(e) => handleChangeSlug(e.target.value, true)}
            // slugify the value on change (override the default change handler)
            onChange={(e) => handleChangeSlug(e.target.value)}
          />
          <FormFieldWatch name={`${chabadHouseFieldsAccessor}.slug`}>
            {(slug) =>
              slug !== initialChabadHouseProfile.slug ? (
                <Box>
                  Any changes made to this field will affect existing web page
                  links already generated eg. RSVP, Grand Draw, etc. Please
                  update any existing web pages or links.
                </Box>
              ) : null
            }
          </FormFieldWatch>
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <FormTextField
            label="Website"
            name={`${chabadHouseFieldsAccessor}.websiteURL`}
          />
        </ProfileFormGridItemStyled>
        <ProfileFormGridItemStyled>
          <FormTextField
            label="Website donation URL"
            name={`${chabadHouseFieldsAccessor}.donateURL`}
          />
        </ProfileFormGridItemStyled>
        <ProfileFormGridHalfRowItemStyled>
          <FormTextField
            label={
              <>
                Chabad house legal name
                <Tooltip title="Chabad house legal name will not be displayed on the public directory">
                  <ProfileFormInfoIcon />
                </Tooltip>
                <FormCheckboxField
                  style={{ marginLeft: 32 }}
                  label="Same as Chabad house name"
                  name="legalNameCheckbox"
                  value={chabadHouseLegalNameMatchesName}
                  onValueChange={(val) => {
                    setChabadHouseLegalNameMatchesName(val);
                    if (val) {
                      form.setValue(
                        `chabadHouses.${chabadHouseIndex}.legalName`,
                        initialChabadHouseProfile.name,
                        getSetFieldValueOptions(form),
                      );
                    } else {
                      form.setValue(
                        `chabadHouses.${chabadHouseIndex}.legalName`,
                        "",
                        getSetFieldValueOptions(form),
                      );
                    }
                  }}
                />
              </>
            }
            name={`${chabadHouseFieldsAccessor}.legalName`}
          />
        </ProfileFormGridHalfRowItemStyled>
      </ProfileFormGridStyled>
    );
  },
);
