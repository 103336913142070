import React from "react";

import { FieldArray } from "formik";
import CustomSelect from "../../../../components/formik/CustomSelect";
import FormInputUpdate from "../../../../components/formik/FormInputUpdate";
import PhoneInput from "../../../../components/formik/PhoneInput";

export default class ContactInfo extends React.PureComponent {
  state = {
    contactInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        contactInfoShowing: false,
      });
    }
  }

  contactMethodsTouched = (touched) => {
    var touchedPerson = touched && touched[this.props.personType];
    return (
      touchedPerson &&
      touchedPerson.contactMethods &&
      touchedPerson.contactMethods
        .map((cm) => (cm && cm.value ? cm.value : null))
        .indexOf(true) >= 0
    );
  };

  validatePhoneInput = (value, contactMethod) => {
    if (!value) {
      return true;
    }
    let input = document
      .getElementsByClassName("phone-input")[0]
      .getElementsByTagName("INPUT")[0]
      .hasAttribute("invalid");
    if (input) {
      return "Invalid Phone Number";
    }
  };

  getContactMethodsAccessor = (personType) => `${personType}.contactMethods`;

  getContactMethodsValues = (personType, values) =>
    values && values[personType] && values[personType].contactMethods;

  render() {
    const {
      errors,
      handleChange,
      personType,
      require,
      setFieldError,
      setFieldValue,
      touched,
      validateContactMethod,
      values,
      sys: { countries, shluchimContactMethodTypes },
    } = this.props;

    const contactMethodsAccessor = this.getContactMethodsAccessor(personType);
    const contactMethods = this.getContactMethodsValues(personType, values);

    const incompleteContactMethodsError =
      personType === "spouse"
        ? errors.spouseIncompleteContactMethods
        : errors.incompleteContactMethods;
    const primaryContactMethodsError =
      personType === "spouse"
        ? errors.spousePrimaryContactMethodsError
        : errors.primaryContactMethodsError;

    const shliachDescription =
      values[personType] && values[personType].gender === "Female"
        ? "Shlucha"
        : "Shliach";

    return (
      <div
        className={
          this.state.contactInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <FieldArray name={contactMethodsAccessor}>
          {(arrayHelpers) => {
            const contactMethodsTouched = this.contactMethodsTouched(touched);
            return (
              <React.Fragment>
                <div className="flex mobile-flex-justify-space flex-align-center mt-48 mb-8">
                  <div className="flex flex-align-center">
                    <p className="accent-text-dark medium-text fw-700">
                      {shliachDescription} Contact Information
                    </p>
                    <i
                      className={`material-icons link-text profile-add-icon ml-16 ${
                        !this.state.contactInfoShowing ? "hidden" : ""
                      }`}
                      onClick={() => {
                        this.contactMethodsTouched();
                        arrayHelpers.unshift({
                          isPreferred: false,
                          type: "",
                          value: "",
                          isPrimary: false,
                        });
                      }}
                    >
                      add_circle
                    </i>
                  </div>
                  <i
                    className="material-icons collapsible-icon desktop-hidden tablet-hidden"
                    onClick={() => {
                      this.setState({
                        contactInfoShowing: !this.state.contactInfoShowing,
                      });
                    }}
                  >
                    {this.state.contactInfoShowing
                      ? "keyboard_arrow_up"
                      : "keyboard_arrow_down"}
                  </i>
                </div>
                <div className="profile-form-contact-info">
                  <p className="small-text accent-text mb-16">
                    <span
                      className={
                        incompleteContactMethodsError && contactMethodsTouched
                          ? "contact-error-message"
                          : ""
                      }
                    >
                      One primary email and one primary cell are required.
                      Please select one preferred method of contact.
                    </span>
                    <span style={{ display: "block" }}>
                      Please ensure that one email is a google address so it can
                      be used to log in.
                    </span>
                    {primaryContactMethodsError && contactMethodsTouched ? (
                      <span
                        className="contact-error-message"
                        style={{ display: "block", marginTop: "8px" }}
                      >
                        Please select one primary email and one primary cell.
                      </span>
                    ) : null}
                  </p>
                  {contactMethods &&
                    contactMethods.map((contactMethod, index) => (
                      <div
                        key={index}
                        className="flex flex-align-center flex-justify-space mb-16 hover-delete-container mobile-card"
                      >
                        <div className="relative">
                          <FormInputUpdate
                            name={`${contactMethodsAccessor}[${index}].type`}
                            validate={require}
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-12"
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <CustomSelect
                                {...field}
                                errors={errors}
                                options={
                                  shluchimContactMethodTypes &&
                                  shluchimContactMethodTypes.map((t) => ({
                                    key: t.enumValue,
                                    value: t.enumValue,
                                    label: t.displayValue,
                                  }))
                                }
                                placeholder="Select..."
                                setFieldValue={setFieldValue}
                                touched={touched}
                              />
                            )}
                          </FormInputUpdate>
                        </div>
                        <div className="relative">
                          {contactMethod.type === "" ||
                          contactMethod.type === "Email" ? (
                            <FormInputUpdate
                              type="Email"
                              placeholder="Enter Value"
                              name={`${contactMethodsAccessor}[${index}].value`}
                              errors={errors}
                              touched={touched}
                              className="custom-input mr-24"
                              style={{ flex: "3" }}
                              validate={(value) =>
                                validateContactMethod(
                                  value,
                                  contactMethod,
                                  touched,
                                )
                              }
                            />
                          ) : (
                            <FormInputUpdate
                              name={`${contactMethodsAccessor}[${index}].value`}
                              validate={(value) =>
                                this.validatePhoneInput(value, contactMethod)
                              }
                              // errors={personType === "spouse" ? errors : null}
                              touched={touched}
                              className="custom-input mr-12"
                              style={{ flex: "2" }}
                            >
                              {({ field }) => (
                                <div
                                  style={{
                                    border: "1px solid #edecec",
                                    borderRadius: "3px",
                                  }}
                                >
                                  <PhoneInput
                                    {...field}
                                    countries={countries || []}
                                    countryFieldName={`${contactMethodsAccessor}[${index}].phoneCountryID`}
                                    countryId={
                                      contactMethods[index].phoneCountryID
                                    }
                                    defaultCountryName={
                                      values &&
                                      values.family &&
                                      values.family.address &&
                                      values.family.address.country
                                    }
                                    errors={errors}
                                    value={contactMethods[index].value}
                                    setFieldError={setFieldError}
                                    setFieldValue={setFieldValue}
                                    touched={touched}
                                  />
                                </div>
                              )}
                            </FormInputUpdate>
                          )}
                        </div>
                        <div
                          className="relative flex flex-align-center"
                          style={{ minHeight: "32px" }}
                        >
                          <FormInputUpdate
                            name={`${contactMethodsAccessor}[${index}].isPreferred`}
                            errors={errors}
                            touched={touched}
                          >
                            {({ field }) => (
                              <div className="radio-button-container">
                                <div className="flex">
                                  <input
                                    type="checkbox"
                                    id={`contact-method-${personType}-${index}`}
                                    {...field}
                                    value="isPreferred"
                                    checked={contactMethod.isPreferred}
                                    onChange={(e) => {
                                      const currentPrimaryCMIndex =
                                        contactMethods
                                          .map((cm) => cm.isPreferred)
                                          .indexOf(true);
                                      handleChange({
                                        target: {
                                          name: `${contactMethodsAccessor}[${currentPrimaryCMIndex}].isPreferred`,
                                          value: false,
                                        },
                                      });
                                      field.onChange(e);
                                    }}
                                  />

                                  <label
                                    htmlFor={`contact-method-${personType}-${index}`}
                                  />
                                  <span>preferred contact method</span>
                                </div>
                              </div>
                            )}
                          </FormInputUpdate>
                        </div>
                        <div
                          className="relative flex flex-align-center"
                          style={{ minHeight: "32px" }}
                        >
                          <FormInputUpdate
                            name={`${contactMethodsAccessor}[${index}].isPrimary`}
                            errors={errors}
                            touched={touched}
                          >
                            {({ field }) =>
                              contactMethod.type === "Email" ||
                              contactMethod.type === "MobileNumber" ? (
                                <div className="radio-button-container">
                                  <div className="flex">
                                    <input
                                      type="checkbox"
                                      id={`contact-method-${personType}-primary-${index}`}
                                      {...field}
                                      value="isPrimary"
                                      checked={contactMethod.isPrimary}
                                      onChange={(e) => {
                                        let primaryEmail = contactMethods
                                          .map(
                                            (cm) =>
                                              cm.isPrimary &&
                                              cm.type === "Email",
                                          )
                                          .indexOf(true);
                                        let primaryCell = contactMethods
                                          .map(
                                            (cm) =>
                                              cm.isPrimary &&
                                              cm.type === "MobileNumber",
                                          )
                                          .indexOf(true);

                                        if (contactMethod.type === "Email")
                                          handleChange({
                                            target: {
                                              name: `${contactMethodsAccessor}[${primaryEmail}].isPrimary`,
                                              value: false,
                                            },
                                          });
                                        if (
                                          contactMethod.type === "MobileNumber"
                                        ) {
                                          handleChange({
                                            target: {
                                              name: `${contactMethodsAccessor}[${primaryCell}].isPrimary`,
                                              value: false,
                                            },
                                          });
                                        }
                                        field.onChange(e);
                                      }}
                                    />
                                    <label
                                      htmlFor={`contact-method-${personType}-primary-${index}`}
                                    />
                                    <span>
                                      primary{" "}
                                      {contactMethod.type === "Email"
                                        ? "email"
                                        : contactMethod.type === "MobileNumber"
                                        ? "cell"
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              ) : null
                            }
                          </FormInputUpdate>
                        </div>
                        <div className="flex flex-align-center delete-container">
                          <p className="desktop-hidden tablet-hidden fw-500 no-wrap">
                            Contact Method {index + 1}
                          </p>
                          {!(
                            (contactMethod.type === "Email" ||
                              contactMethod.type === "MobileNumber") &&
                            contactMethods.filter(
                              (cm) => cm.type === contactMethod.type,
                            ).length === 1
                          ) && (
                            <i
                              className="material-icons link-text hover-delete"
                              style={{ fontSize: "16px" }}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              delete
                            </i>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </React.Fragment>
            );
          }}
        </FieldArray>
      </div>
    );
  }
}
