import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab } from "@material-ui/core";
import { EngagementActions, EngagementSelectors } from "../../../state";
import EngagementPeriodGoals from "./EngagementPeriodGoals";
import EngagementPeriodsDropdown from "./EngagementPeriodsDropdown";
import EngagementGoalsMetrics from "./metrics/EngagementGoalsMetrics";
import NoAccess from "../../shared/NoAccess";
import {
  Navigation,
  PageURL,
  IsApiErrorStatus,
  isTabletView,
  isMobileView,
} from "../../../lib";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import VibrancyMeter from "./vibrancyMeter/VibrancyMeter";
import { TabPanel } from "../../../components/TabPanel";

const tabIds = {
  goals: "goals",
  metrics: "metrics",
  vibrancy: "vibrancy",
};

const tabs = [
  { id: tabIds.metrics, title: "Metrics" },
  { id: tabIds.vibrancy, title: "Vibrancy Meter" },
  { id: tabIds.goals, title: "Engagement Goals", isMobileOnly: true },
];

export default function StudentEngagementGoals({ pageRoute }) {
  const {
    page,
    params,
    query,
    query: { id: engagementPeriodId, tabId },
  } = pageRoute;

  const {
    data: engagementPeriodsData,
    errorMessage: engagementPeriodsErrorMessage,
    loading: engagementPeriodsLoading,
    error: engagementPeriodsError,
    success: engagementPeriodsSuccess,
  } = useSelector(EngagementSelectors.engagementPeriods);

  const {
    errorMessage: engagementGoalsErrorMessage,
    loading: engagementGoalsLoading,
    data: engagementGoalsData,
  } = useSelector(EngagementSelectors.engagementGoals);

  const {
    loading: submitGoalsLoading,
    data: submittedEngagementPeriodGoals,
    errorMessage: submitGoalsErrorMessage,
    success: submitGoalsSuccess,
  } = useSelector(EngagementSelectors.submittedEngagementGoals);

  const { data: jewishStudentCount } = useSelector(
    EngagementSelectors.jewishStudentCount,
  );

  const [engagementPeriods, setEngagementPeriods] = useState([]);
  const [engagementPeriodsWithId, setEngagmentPeriodsWithId] = useState([]);
  const [engagementPeriodGoals, setEngagementPeriodGoals] = useState(null);
  const [initialEngagementPeriodGoals, setInitialEngagementPeriodGoals] =
    useState(null);
  const [metricsEngagementPeriodIds, setMetricsEngagementPeriodIds] = useState(
    [],
  );
  const [selectedDates, setSelectedDates] = useState([]);
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);
  const [isNewEnagagementPeriod, setIsNewEnagagementPeriod] = useState(false);
  const [showGoalsSidebar, setShowGoalsSidebar] = useState(true);
  const [tabValue, setTabValue] = useState(
    isMobileView() ? tabIds.goals : tabIds.metrics,
  );

  const dispatch = useDispatch();

  const getEngagementGoalMetrics = useCallback(
    (metricsEngagementPeriodIds) => {
      dispatch(
        EngagementActions.getEngagementStudentGoalMetrics(
          metricsEngagementPeriodIds,
        ),
      );
      dispatch(
        EngagementActions.getEngagementInteractionGoalMetrics(
          metricsEngagementPeriodIds,
        ),
      );
    },
    [dispatch],
  );

  const getEngagementMetrics = useCallback(
    (monthYears) => {
      dispatch(EngagementActions.getEngagementStudentMetrics(monthYears));
      dispatch(EngagementActions.getEngagementInteractionMetrics(monthYears));
    },
    [dispatch],
  );

  const filterMetricData = useCallback(
    (selectedPeriods, showGoalDataOnly = true) => {
      if (showGoalDataOnly) {
        getEngagementGoalMetrics(selectedPeriods);
        setMetricsEngagementPeriodIds(selectedPeriods);
      } else {
        setSelectedDates(selectedPeriods);
        getEngagementMetrics(selectedPeriods);
      }
    },
    [getEngagementGoalMetrics, getEngagementMetrics],
  );

  const getEngagementPeriods = useCallback(
    async (isRefresh = false) => {
      dispatch(EngagementActions.getEngagementPeriods(isRefresh));
      setIsRefresh(isRefresh);
    },
    [dispatch],
  );

  useEffect(
    function onMount() {
      getEngagementPeriods();
      dispatch(EngagementActions.getJewishStudentCount());
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function onGetEngagementPeriods() {
      if (engagementPeriodsSuccess) {
        //map over engagement periods to set identifiers and save to local state
        const _engagementPeriods = engagementPeriodsData
          ? _cloneDeep(engagementPeriodsData).map((period) => ({
              ...period,
              identifier:
                period.id || (period.isCurrent ? "current" : "upcoming"),
            }))
          : [];

        setEngagementPeriods(_engagementPeriods);
        setEngagmentPeriodsWithId(
          _engagementPeriods && _engagementPeriods.filter((p) => p.id),
        );

        if (!isRefresh) {
          if (!engagementPeriodId && _engagementPeriods.length) {
            // retrieving the goals for an engagementPeriodId is handled by another useEffect
            //if no period id is specific in the url, redirect to current/latest engagement period
            const defaultEngagementPeriod =
              _engagementPeriods.find((p) => p.isCurrent) ||
              _engagementPeriods[0];
            Navigation.redirect(
              PageURL.to(page, params, {
                id: defaultEngagementPeriod.identifier,
              }),
            );
          }

          //if there is a current engagement period, default metrics period filters to the current period and get metrics
          const currentEngagementPeriodId = _engagementPeriods.find(
            (p) => p.isCurrent,
          )?.id;
          if (currentEngagementPeriodId) {
            filterMetricData([currentEngagementPeriodId]);
          }
        }
      }
    },
    // leaving out engagementPeriodId and params from the dependency array because
    // this effect should run only when there's new engagementPeriodsData, not every time the engagementPeriodId is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      engagementPeriodsData,
      engagementPeriodsSuccess,
      filterMetricData,
      isRefresh,
      page,
    ],
  );

  useEffect(
    function onUpdatePeriodIdToNewSetEngagementPeriodGoals() {
      //set default goals state for not-yet-created (current/upcoming) engagement period
      const selectedEngagementPeriod = engagementPeriods.find(
        (p) => p.identifier === engagementPeriodId,
      );
      if (selectedEngagementPeriod) {
        const newEngagementPeriodGoals = {
          month: selectedEngagementPeriod.month,
          year: selectedEngagementPeriod.year,
          interactionGoals: [],
          studentGoals: [],
        };
        setEngagementPeriodGoals(_cloneDeep(newEngagementPeriodGoals));
        setInitialEngagementPeriodGoals(_cloneDeep(newEngagementPeriodGoals));
      }
    },
    [engagementPeriodId, engagementPeriods],
  );

  useEffect(
    function onUpdatePeriodIdToExistingGetEngagementPeriodGoals() {
      async function getEngagementPeriodGoals() {
        //request updated engagement period goals when period id is updated to an existing periodId (ie, not new)
        if (engagementPeriodId > 0) {
          dispatch(EngagementActions.getEngagementGoals(engagementPeriodId));
        }
      }

      getEngagementPeriodGoals();
    },
    [dispatch, engagementPeriodId],
  );

  useEffect(
    function onGetEngagementPeriodGoals() {
      if (engagementGoalsData) {
        setEngagementPeriodGoals(_cloneDeep(engagementGoalsData));
        setInitialEngagementPeriodGoals(_cloneDeep(engagementGoalsData));
      }
    },
    [engagementGoalsData],
  );

  const submitEngagementPeriodGoals = async () => {
    const engagementPeriodGoalsForSubmission = engagementPeriodGoals;

    if (submissionErrorMessage) {
      setSubmissionErrorMessage("");
    }

    //format goals:
    //remove student/interaction goals with no goal value set
    engagementPeriodGoalsForSubmission.studentGoals =
      engagementPeriodGoalsForSubmission.studentGoals.filter((g) => g.goal > 0);
    engagementPeriodGoalsForSubmission.interactionGoals =
      engagementPeriodGoalsForSubmission.interactionGoals.filter(
        (g) => g.goal > 0,
      );
    //replace empty strings with 0 for empty interaction goal values
    engagementPeriodGoalsForSubmission.interactionGoals.forEach((g) => {
      if (g.goal === "") g.goal = 0;
    });

    dispatch(
      EngagementActions.submitEngagementGoals(
        engagementPeriodGoalsForSubmission,
      ),
    );
    setIsNewEnagagementPeriod(!engagementPeriodGoalsForSubmission.id);
  };

  useEffect(
    function onSubmitEngagementPeriodGoals() {
      if (!submitGoalsSuccess) {
        setSubmissionErrorMessage(submitGoalsErrorMessage);
        return;
      }

      setEngagementPeriodGoals(_cloneDeep(submittedEngagementPeriodGoals));
      setInitialEngagementPeriodGoals(
        _cloneDeep(submittedEngagementPeriodGoals),
      );

      //if new engagement period, reload enagagement periods list and set period id in page route
      if (isNewEnagagementPeriod) {
        getEngagementPeriods(true);
        Navigation.redirect(
          PageURL.to(page, params, {
            id: submittedEngagementPeriodGoals.id,
          }),
        );
      }
      //load metrics for the period when a new current engagement period is created
      if (isNewEnagagementPeriod && engagementPeriodId === "current") {
        filterMetricData([submittedEngagementPeriodGoals.id]);
      }
      //reload metrics when a period selected for metrics is updated, reload metrics
      else if (
        metricsEngagementPeriodIds.some(
          (id) =>
            id.toString() === submittedEngagementPeriodGoals.id.toString(),
        )
      ) {
        getEngagementGoalMetrics(metricsEngagementPeriodIds);
      }

      setIsNewEnagagementPeriod(false);
    },
    // leaving out engagementPeriodId, metricsEngagementPeriodIds and params from the dependency array because
    // this effect should run only when goals are submitted, not every time the engagementPeriodId is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      submitGoalsSuccess,
      submitGoalsErrorMessage,
      submittedEngagementPeriodGoals,
      filterMetricData,
      getEngagementGoalMetrics,
      getEngagementPeriods,
      isNewEnagagementPeriod,
      page,
    ],
  );

  useEffect(() => {
    if (tabIds.hasOwnProperty(tabId)) setTabValue(tabId);
  }, [tabId]);

  const toTab = (tabId) => {
    setTabValue(tabId);
    const url = PageURL.to(page, params, {
      ...query,
      tabId,
    });

    Navigation.redirect(url);
  };

  const renderGoals = () => {
    const hasUnsavedChanges = !_isEqual(
      engagementPeriodGoals,
      initialEngagementPeriodGoals,
    );

    return (
      <>
        <EngagementPeriodsDropdown
          engagementPeriodId={engagementPeriodId}
          engagementPeriods={engagementPeriods}
          errorMessage={engagementPeriodsErrorMessage}
          hasUnsavedChanges={hasUnsavedChanges}
          loading={engagementPeriodsLoading}
          pageRoute={pageRoute}
        />
        <EngagementPeriodGoals
          engagementPeriodGoals={engagementPeriodGoals}
          errorMessage={engagementGoalsErrorMessage}
          hasUnsavedChanges={hasUnsavedChanges}
          jewishStudentCount={jewishStudentCount}
          loading={engagementGoalsLoading}
          onChange={(goals) => setEngagementPeriodGoals(goals)}
          onCancelChanges={() => {
            setEngagementPeriodGoals(_cloneDeep(initialEngagementPeriodGoals));
            setSubmissionErrorMessage("");
          }}
          submitGoals={submitEngagementPeriodGoals}
          submitErrorMessage={submissionErrorMessage}
          submitLoading={submitGoalsLoading}
        />
      </>
    );
  };

  const renderMetrics = () => (
    <EngagementGoalsMetrics
      engagementPeriods={engagementPeriodsWithId} //only include saved engagement periods (not current/upcoming templates)
      engagementPeriodsLoading={engagementPeriodsLoading}
      engagementPeriodIdsFilter={metricsEngagementPeriodIds}
      selectedDates={selectedDates}
      filterMetrics={filterMetricData}
    />
  );

  const renderVibrancyMeter = () => (
    <VibrancyMeter
      engagementPeriods={engagementPeriodsWithId}
      engagementPeriodsLoading={engagementPeriodsLoading}
    />
  );

  if (engagementPeriodsError && IsApiErrorStatus(engagementPeriodsError, 403)) {
    return <NoAccess systemName="the Engagement Portal" />;
  }

  return (
    <div className="card flex student-engagement-page mobile-card">
      <div
        className={
          showGoalsSidebar
            ? "goals-sidebar-expanded mobile-hidden"
            : "goals-sidebar-collapsed"
        }
      >
        <div
          className={`flex flex-align-start mobile-hidden ${
            showGoalsSidebar ? "flex-justify-space" : ""
          }`}
          onClick={() => setShowGoalsSidebar(!showGoalsSidebar)}
        >
          {!showGoalsSidebar && (
            <i
              className="material-icons mobile-hidden pointer"
              style={{ fontSize: "18px", marginBottom: "16px" }}
            >
              keyboard_arrow_up
            </i>
          )}
          <p className="medium-text fw-700 mb-16">
            My Student Engagement Goals
          </p>
          {showGoalsSidebar && (
            <i
              className="material-icons mobile-hidden pointer"
              style={{ fontSize: "18px" }}
            >
              keyboard_arrow_left
            </i>
          )}
        </div>
        {showGoalsSidebar && renderGoals()}
      </div>
      <div className="divider mobile-hidden" />
      <div className="full-width">
        <Tabs
          className="mt-16"
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          value={tabValue}
          onChange={(_, value) => {
            toTab(value);
          }}
        >
          {tabs
            .filter((t) =>
              t.isMobileOnly ? isTabletView() || isMobileView() : true,
            )
            .map((t) => (
              <Tab label={t.title} value={t.id} key={t.id} />
            ))}
        </Tabs>
        {tabs.map((t) => (
          <TabPanel value={tabValue} index={t.id}>
            {t.id === tabIds.goals && renderGoals()}
            {t.id === tabIds.metrics && renderMetrics()}
            {t.id === tabIds.vibrancy && renderVibrancyMeter()}
          </TabPanel>
        ))}
      </div>
    </div>
  );
}
