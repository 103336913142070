import React from "react";
import { Field } from "formik";
import get from "lodash.get";

export default class FormInput extends React.PureComponent {
  render() {
    const {
      children,
      className,
      component,
      disabled,
      errors,
      label,
      labelTag,
      name,
      readOnly,
      touched,
      type,
      validate,
      style,
      ...props
    } = this.props;
    const error = get(errors, name) && get(touched, name);
    const errorText = get(errors, name);
    return (
      <React.Fragment>
        {label && (
          <label className={`accent-text small-text ${error ? "error" : ""}`}>
            {label}
          </label>
        )}
        <Field
          name={name}
          className={`custom-input ${error ? "error" : ""} ${
            className ? className : ""
          }`}
          component={component}
          type={type}
          validate={validate}
          {...props}
          disabled={disabled}
          readOnly={readOnly}
        >
          {children ? children : null}
        </Field>
        {error && <p className="error-message">{errorText}</p>}
      </React.Fragment>
    );
  }
}
