import React from "react";
import FilePreviewModal from "../../../../components/FilePreviewModal";
import PaginatedList from "../../../../components/PaginatedList";
import { formatNumber } from "../../../../lib";
import moment from "moment";
import ApproveReject from "../../../edu/components/ApproveRejects";

export default class StudentCourses extends React.PureComponent {
  state = {
    page: 1,
    resultsView: 10,
    showThesisPreviewModal: false,
  };

  getCourses = (page) => {
    this.setState({ page });

    const { getStudentCourses, studentEnrollment } = this.props;
    getStudentCourses(
      studentEnrollment.eduStudentEnrollmentID,
      page,
      this.state.resultsView,
    );
  };

  render() {
    const {
      mobileView,
      studentEnrollment: {
        numOfCoursesCompleted,
        thesisDocumentURL,
        status: enrollmentStatus,
      },
      studentCourses: {
        data: { results = [], numberOfRows = 0 } = {},
        loading,
        success,
      },
    } = this.props;

    const { page, resultsView, showThesisPreviewModal } = this.state;
    const progress =
      ((numOfCoursesCompleted > 8 ? 8 : numOfCoursesCompleted) / 8) * 100;
    return (
      <div>
        <div className="flex flex-align-center flex-justify-space tablet-block mb-24">
          <p className="fw-900 medium-text tablet-mb-8 mobile-hidden">
            Course History
          </p>
          <div className="flex flex-justify-end medium-text mobile-block">
            <div className="mobile-mb-16">
              <p>
                <span className="fw-700 mr-8">Courses Completed</span>
                <span>{formatNumber(numOfCoursesCompleted)}/8</span>
              </p>
              <div className="percentage-bar relative mt-12">
                <div className="bar" />
                <div className="filled-in" style={{ width: `${progress}%` }} />
              </div>
            </div>
            <div className="ml-24 mobile-ml-0">
              <p>
                <span className="fw-700 mr-16">Thesis Completed</span>{" "}
                {thesisDocumentURL ? "Yes" : "No"}
              </p>
              {!!thesisDocumentURL && (
                <div>
                  <p
                    className="link-text mt-8"
                    onClick={() =>
                      this.setState({ showThesisPreviewModal: true })
                    }
                  >
                    View Thesis
                  </p>
                  <FilePreviewModal
                    close={() =>
                      this.setState({ showThesisPreviewModal: false })
                    }
                    show={showThesisPreviewModal}
                    title="Student Thesis Preview"
                    url={thesisDocumentURL}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <PaginatedList
            loadData={this.getCourses}
            loading={loading}
            name="courses"
            page={page}
            records={results}
            renderHeaderRow={() => (
              <div className="student-courses-table-header-row">
                <p>Name</p>
                <p>Date Started</p>
                <p>Status</p>
                <p>Review Completed</p>
              </div>
            )}
            renderRow={(course, index) => (
              <div className="student-courses-table-row" key={index}>
                <p>{course.courseScheduleName}</p>
                {!mobileView ? (
                  <React.Fragment>
                    <p>
                      {course.firstClassDate
                        ? moment(course.firstClassDate).format("MM/DD/YYYY")
                        : ""}
                    </p>
                    <p>
                      {course.studentCourseScheduleEnrollmentStatus ===
                      "Pending" ? (
                        <ApproveReject
                          key={index}
                          data={course}
                          onApproveRejectStudent={
                            this.props.onApproveRejectStudent
                          }
                        />
                      ) : (
                        <>
                          {enrollmentStatus === "Rejected"
                            ? "N/A"
                            : course.studentCourseScheduleEnrollmentStatus}
                        </>
                      )}
                    </p>
                    <p>{course.didCompleteSurvey ? "Yes" : "No"}</p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {course.firstClassDate && (
                      <div className="flex flex-align-center">
                        <p
                          className="small-text accent-text"
                          style={{ width: 144 }}
                        >
                          Date Started
                        </p>
                        <p className="small-text accent-text">
                          {" "}
                          {course.firstClassDate
                            ? moment(course.firstClassDate).format("MM/DD/YYYY")
                            : ""}
                        </p>
                      </div>
                    )}
                    <div className="flex flex-align-center">
                      <p
                        className="small-text accent-text"
                        style={{ width: 144 }}
                      >
                        Status
                      </p>
                      <p className="small-text accent-text">
                        {course.studentCourseScheduleEnrollmentStatus ===
                        "Pending" ? (
                          <ApproveReject
                            key={index}
                            data={course}
                            onApproveRejectStudent={
                              this.props.onApproveRejectStudent
                            }
                          />
                        ) : (
                          <>
                            {enrollmentStatus === "Rejected"
                              ? "N/A"
                              : course.studentCourseScheduleEnrollmentStatus}
                          </>
                        )}
                      </p>
                    </div>
                    <div className="flex flex-align-center">
                      <p
                        className="small-text accent-text"
                        style={{ width: 144 }}
                      >
                        Review Completed
                      </p>
                      <p className="small-text accent-text">
                        {course.didCompleteSurvey ? "Yes" : "No"}
                      </p>
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
            resultsView={resultsView}
            success={success}
            totalCount={numberOfRows}
          />
        </div>
      </div>
    );
  }
}
