import React from "react";
import { Typography } from "@material-ui/core";
import {
  FormFieldWatch,
  FormPhoneField,
  FormTextField,
} from "../../../../../components/react-hook-form";
import { ContactMethodTypes } from "../../ProfileFormHelpers";
import { ShliachInfoProps } from "./ShliachInfo";
import {
  ProfileFormGridQuarterRowItemStyled,
  ProfileFormGridStyled,
} from "../../ProfileForm.styles";

interface ShliachContactInfoProps extends ShliachInfoProps {
  shliachDescription: string;
}

export const ShliachContactInfo = React.memo(
  /**
   *
   */
  function ShliachContactInfo(props: ShliachContactInfoProps) {
    const { personType, shliachDescription, initialFormValues } = props;
    const initialContactMethods = initialFormValues[personType]!.contactMethods || [];

    const contactMethodsFieldsAccessor = `${personType}.contactMethods`;

    const emailIndex = initialContactMethods.findIndex(
      (cm) => cm.type === ContactMethodTypes.Email,
    );

    const cellPhoneIndex = initialContactMethods.findIndex(
      (cm) => cm.type === ContactMethodTypes.MobileNumber,
    );

    return (
      <>
        <Typography variant="subtitle1">
          {shliachDescription} contact information
        </Typography>
        <Typography variant="body2" color="textSecondary">
          This email will be your login. Pease ensure that it's a Google
          Address.
        </Typography>
        <ProfileFormGridStyled
          style={{ marginTop: "0px", marginBottom: "32px" }}
        >
          <ProfileFormGridQuarterRowItemStyled>
            <FormTextField
              label="Email"
              name={`${contactMethodsFieldsAccessor}.${emailIndex}.value`}
              type="email"
            />
          </ProfileFormGridQuarterRowItemStyled>
          <FormFieldWatch
            name={[
              "family.address.country",
              `${contactMethodsFieldsAccessor}.${cellPhoneIndex}.type`,
            ]}
          >
            {([country]) => (
              <>
                <ProfileFormGridQuarterRowItemStyled>
                  <FormPhoneField
                    label="Cell phone"
                    countryIdFieldName={`${contactMethodsFieldsAccessor}.${cellPhoneIndex}.phoneCountryID`}
                    defaultCountryName={country}
                    name={`${contactMethodsFieldsAccessor}.${cellPhoneIndex}.value`}
                  />
                </ProfileFormGridQuarterRowItemStyled>
              </>
            )}
          </FormFieldWatch>
        </ProfileFormGridStyled>
      </>
    );
  },
);
