import React, { useState, useEffect, useCallback } from "react";
const selectedInputColor = "#33b7ae";
const MergeDuplicateRow = (props) => {
  const {
    getDisplayValue,
    readOnly,
    isEqual,
    name,
    setAsUnresolved,
    students,
    label,
    resolveField,
    ids,
    selectedAllIndex,
    selectedSectionIndex,
    showAllFields,
    equalFields,
    setEqualFields,
  } = props;
  //Selected index can either come from selectAll, selectedSection, or directly from onChange.
  //The most recent user action determines the value, e.g. 'select all' can be overridden by selecting a different section or individual field, and vice versa.
  const [selectedStudentIndex, setSelectedStudentIndex] = useState(null);
  const [values, setValues] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const setStudentValues = useCallback(() => {
    var values = students.map((student, index) => ({
      value: student[name],
      displayValue: getDisplayValue(student[name]),
      studentIndex: index,
    }));
    var valuesEqual = isEqual(values.map((v) => v.displayValue));
    if (valuesEqual && !equalFields.includes(name)) {
      setEqualFields([...equalFields, name]);
    } else if (!valuesEqual && equalFields.includes(name)) {
      setEqualFields([...equalFields.splice(equalFields.indexOf(name), 1)]);
    }
    setValues(values);
    setIsEditable(readOnly ? false : !valuesEqual);
    if (!readOnly && !valuesEqual) {
      setAsUnresolved(name);
    }
  }, [
    getDisplayValue,
    isEqual,
    readOnly,
    name,
    setAsUnresolved,
    students,
    setEqualFields,
    equalFields,
  ]);
  useEffect(() => {
    setStudentValues();
  }, [setStudentValues, ids]);

  useEffect(() => {
    if (selectedAllIndex !== null) setSelectedStudentIndex(selectedAllIndex);
  }, [selectedAllIndex]);

  useEffect(() => {
    if (selectedSectionIndex !== null)
      setSelectedStudentIndex(selectedSectionIndex);
  }, [selectedSectionIndex]);

  return (
    (showAllFields || isEditable) && (
      <div
        className="field-row flex flex-align-center"
        style={{
          gridTemplateColumns: `180px repeat(${students.length}, 300px)`,
        }}
      >
        <div style={{ paddingBottom: 8 }}>{label}</div>
        {values.map((val, studentIndex) => {
          const { displayValue, value } = val;
          return (
            <div
              key={studentIndex}
              className="flex flex-align-center merge-duplicates-row mb-12"
              style={{
                borderColor:
                  selectedStudentIndex === studentIndex
                    ? selectedInputColor
                    : "#edecec",
              }}
            >
              {isEditable && (
                <div className="merge-radio-container">
                  <input
                    type="radio"
                    value={value || ""}
                    style={{
                      marginRight: 8,
                    }}
                    checked={selectedStudentIndex === studentIndex}
                    onChange={() => {
                      setSelectedStudentIndex(studentIndex);
                      resolveField(value, name);
                    }}
                  />
                </div>
              )}
              <div className="tooltip-container accent-text">
                {displayValue?.length > 28
                  ? displayValue.substring(0, 26) + "..."
                  : displayValue}
                {displayValue?.length > 28 && (
                  <span
                    className="tooltip"
                    style={{
                      left: 100,
                    }}
                  >
                    {displayValue}
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};
export default React.memo(MergeDuplicateRow);
