import React from "react";
import { useState, useEffect } from "react";

import CustomSelect from "../../../../components/formik/CustomSelect";
import FormInput from "../../../../components/formik/FormInput";
import FormInputHebrew from "../../../../components/formik/FormInputHebrew";
import ImageUpload from "../../../../components/formik/ImageWithCropUpload";
import RadioContainer from "../../../../components/formik/RadioContainer";
import { isNotValidDOB } from "../../../../lib";

export default function PersonalInfo(props) {
  const [personalInfoShowing, setPersonalInfoShowing] = useState(true);

  const {
    contactInfo,
    errors,
    personType,
    handleChange,
    require,
    setFieldValue,
    touched,
    values,
    sys: { titles },
    ui: { isPhone },
  } = props;

  const disabled = personType === "spouse" && !values[personType];

  const shliachDescription =
    values[personType] && values[personType].gender === "Female"
      ? "Shlucha"
      : "Shliach";

  useEffect(() => {
    if (window.innerWidth < 560) {
      setPersonalInfoShowing(false);
    }
  }, []);

  return (
    <div
      className={`${
        personType === "spouse" ? "spouse-card mt-32 mobile-mt-0" : ""
      }`}
    >
      <div
        className={`profile-form-section ${
          !personalInfoShowing ? "mobile-tab-collapsed" : ""
        }`}
      >
        <div
          className="accent-text-dark medium-text fw-700 mt-32 mb-16 flex flex-align-center flex-justify-space mobile-full-width"
          onClick={() => {
            setPersonalInfoShowing(!personalInfoShowing);
          }}
        >
          <p className="flex flex-align-center">
            {shliachDescription} Information
            {disabled && (
              <span className="tooltip-container">
                <i
                  className={`material-icons link-text no-hover spouse-info-icon ml-16 ${
                    !personalInfoShowing ? "hidden" : ""
                  }`}
                  onClick={(e) => e.stopPropagation()}
                >
                  info
                </i>
                <span className="tooltip">
                  To add a spouse contact &nbsp;
                  <a
                    href="mailto:help@chabadoncampus.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    help@chabadoncampus.org
                  </a>
                </span>
              </span>
            )}
          </p>
          <i className="desktop-hidden tablet-hidden material-icons">
            {personalInfoShowing ? "keyboard_arrow_up" : "keyboard_arrow_down"}
          </i>
        </div>
        <div
          className={`profile-form-inputs ${
            personType === "spouse"
              ? !values.spouse
                ? "spouse-disabled"
                : ""
              : ""
          }`}
        >
          <div style={{ gridColumnStart: "span 2" }}>
            <ImageUpload
              name={`${personType}.profileImageURL`}
              label="Upload Profile Image"
              uploadedLabel="Change Profile Image"
              disabled={disabled}
              errors={errors}
              errorName={
                personType === "spouse"
                  ? "missingSpouseImageURL"
                  : "missingImageURL"
              }
              onChange={(name, value) =>
                handleChange({ target: { name, value } })
              }
              imageName="Profile Image"
              imageType="profile"
              imageURL={
                values[personType] ? values[personType].profileImageURL : ""
              }
              touched={touched}
              required={!!require}
              person={true}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="First Name"
              type="text"
              name={`${personType}.firstName`}
              validate={require}
              disabled={disabled}
              errors={errors}
              touched={touched}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Last Name"
              type="text"
              name={`${personType}.lastName`}
              validate={require}
              disabled={disabled}
              errors={errors}
              touched={touched}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="Name called by"
              type="text"
              name={`${personType}.nickname`}
              disabled={disabled}
              errors={errors}
              touched={touched}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInputHebrew
              label="Hebrew Name"
              type="text"
              name={`${personType}.hebrewName`}
              placeholder="ex: Chana bas Rivkah"
              validate={require}
              disabled={disabled}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              isPhone={isPhone}
            />
          </div>

          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="Title"
              name={`${personType}.title`}
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <CustomSelect
                  {...field}
                  disabled={disabled}
                  errors={errors}
                  options={
                    titles &&
                    titles.map((t) => ({
                      key: t.enumValue,
                      value: t.enumValue,
                      label: t.displayValue,
                    }))
                  }
                  placeholder="Select Title..."
                  setFieldValue={setFieldValue}
                  touched={touched}
                />
              )}
            </FormInput>
          </div>

          <div className="flex flex-align-center relative">
            <FormInput
              label="Gender"
              name={`${personType}.gender`}
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <RadioContainer
                  disabled={disabled}
                  errors={errors}
                  touched={touched}
                  {...field}
                  input1={{
                    id: `${personType}-male`,
                    value: "Male",
                    checked: "Male",
                    label: "Male",
                  }}
                  input2={{
                    id: `${personType}-female`,
                    value: "Female",
                    checked: "Female",
                    label: "Female",
                  }}
                />
              )}
            </FormInput>
          </div>

          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="Birthday"
              type="date"
              name={`${personType}.dob`}
              validate={(date) => require(date) || isNotValidDOB(date)}
              disabled={disabled}
              errors={errors}
              touched={touched}
            />
          </div>
          <div className="flex flex-align-center relative">
            <FormInput
              label="Nightfall/Sunset"
              name={`${personType}.beforeNightfall`}
              validate={require}
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <RadioContainer
                  disabled={disabled}
                  errors={errors}
                  touched={touched}
                  {...field}
                  input1={{
                    id: `${personType}-before`,
                    value: true,
                    checked: "true",
                    label: "Before",
                  }}
                  input2={{
                    id: `${personType}-after`,
                    value: false,
                    checked: "false",
                    label: "After",
                  }}
                />
              )}
            </FormInput>
          </div>

          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label={
                <div className="line-height-double accent-text small-text flex flex-justify-space flex-align-center">
                  Hebrew Birthday
                  <span className="tooltip-container ml-8 mt-4">
                    <i
                      className="material-icons link-text no-hover profile-add-icon"
                      style={{ fontSize: 14 }}
                    >
                      info
                    </i>
                    <span className="tooltip">
                      Hebrew Birthday is calculated based on Birthday and
                      Nightfall/Sunset. It will update on save.
                    </span>
                  </span>
                </div>
              }
              type="text"
              readOnly={true}
              name="person.hebrewDOB"
              value={values[personType]?.hebrewDOB}
              labelClassNames="line-height-double accent-text small-text flex flex-justify-space flex-align-center"
              className="custom-input ml-8 mobile-ml-0"
            />
          </div>
          <div className={`flex flex-justify-space flex-align-center relative`}>
            <FormInput
              label="Zoom User Email"
              type="text"
              name={`${personType}.zoomUserEmail`}
              disabled={disabled}
              errors={errors}
              touched={touched}
            />
          </div>

          {personType !== "spouse" && (
            <div className="flex flex-justify-space flex-align-center mr-24 relative">
              <FormInput
                label="Date went on Shlichus"
                type="date"
                name="dateStarted"
                validate={require}
                errors={errors}
                touched={touched}
              />
            </div>
          )}
        </div>
      </div>
      {personType === "spouse" && (
        <div style={{ gridColumnStart: "span 2" }}>{contactInfo}</div>
      )}
    </div>
  );
}
