import React from "react";
import CopyToClipboardTextbox from "../../../components/CopyToClipboardTextbox";

export default class EnrollmentCompleted extends React.PureComponent {
  render() {
    const {
      enrollment: { chabadHouseRsvpURL, forChabadHouseID },
      toggleShowEnrollmentCompleted,
    } = this.props;

    return (
      <div className="flex flex-align-center full-page-state">
        <img src="/images/enrollmentComplete.svg" alt="" height="240" />
        <p className="fw-700 xxl-text mt-24 mb-40 text-center line-height-double">
          You have successfully activated your RSVP System
        </p>
        <div className="mb-32">
          <div
            className="flex flex-align-center mobile-flex-column"
            style={{ maxWidth: "100%" }}
          >
            <span className="accent-text-dark mr-8 no-wrap">
              Your Chabad House's Url is
            </span>
            <CopyToClipboardTextbox
              className="copy-to-clipboard-textbox flex-justify-space"
              id="rsvp-events-url"
              goToLabel="View Link"
              label="Copy Link"
              showGoTo={true}
              text={chabadHouseRsvpURL}
            />
          </div>
          {!!forChabadHouseID && (
            <p className="small-text accent-text-dark text-center mt-8">
              The URL Friendly Name (the part of the URL that references your
              Chabad House) can be updated in your Chabad House profile by
              clicking{" "}
              <a
                className="link-text"
                href={`/account/chabadHouse/${forChabadHouseID}`}
              >
                here
              </a>
            </p>
          )}
        </div>
        <div
          className="fw-700 accent-text mb-32 line-height-double flex"
          style={{ background: "#fff", maxWidth: 504, padding: "12px 16px" }}
        >
          <i className="material-icons medium-text mr-16 mt-4">info</i>
          <span>
            For best visibility and to increase engagment, make sure to add a
            link on your Chabad house’s current website to the RSVP system.
          </span>
        </div>
        <button
          className="btn btn-accent btn-large"
          onClick={toggleShowEnrollmentCompleted}
          style={{ padding: "0 40px", maxWidth: "98%" }}
        >
          Get Started
        </button>
      </div>
    );
  }
}
