import React from "react";
import Select from "react-select";
import get from "lodash.get";

export default class MultiSelect extends React.Component {
  render() {
    const { errors, touched, options, name, value, setFieldValue } = this.props;
    const error = get(errors, name) && get(touched, name);
    return (
      <Select
        className={`multiselect ${error ? `error` : null}`}
        classNamePrefix="multiselect"
        closeMenuOnSelect={true}
        isMulti={true}
        name={name}
        onChange={(option) => setFieldValue(name, option)}
        options={options}
        value={value}
      />
    );
  }
}
