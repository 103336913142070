import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import Loader from "../../../../components/Loader";
import {
  StudentsMetricsChartHeader,
  getMetricDetailsAccessor,
  formatStudentMetricsTickY,
  getEngagementPeriodLabel,
} from "./";
import MetricsBarChart from "./MetricsBarChart";
import NoMetrics from "./NoMetrics";
import ColoredLegendEntry from "../../ColoredLegendEntry";
import { SystemSelectors, EngagementSelectors } from "../../../../state";

function formatData(data, jewishInterestLevels) {
  if (!data) return [];
  return jewishInterestLevels
    .map(({ enumValue }) => enumValue)
    .map((jewishInterestLevel) => {
      const jewishInterestLevelData = {
        jewishInterestLevel,
        total: 0,
      };

      data
        .filter(
          (goalMetrics) =>
            goalMetrics.jewishInterestLevel === jewishInterestLevel,
        )
        .forEach((goalMetrics) => {
          const { engagementPeriodID, goal, goalProgress } = goalMetrics;
          const goalProgressPercentage = (goalProgress * 100) / goal;

          jewishInterestLevelData[engagementPeriodID] =
            goalProgressPercentage > 100 ? 100 : goalProgressPercentage; //ensure that progress % does not exceed 100
          jewishInterestLevelData[
            getMetricDetailsAccessor(engagementPeriodID)
          ] = goalMetrics;

          jewishInterestLevelData.total += goalProgress;
        });
      return jewishInterestLevelData;
    });
}

export default function StudentGoalMetricsChart(props) {
  const { selectedEngagementPeriodIds, engagementPeriods, getColors } = props;

  const [formattedData, setFormattedData] = useState([]);

  const { data, total, loading, errorMessage } = useSelector(
    EngagementSelectors.studentGoalMetrics,
  );
  const jewishInterestLevels = useSelector(
    SystemSelectors.engagementJewishInterestLevels,
  );

  useEffect(
    function updateLocalData() {
      if (data) {
        //we are setting the data in local state per this issue where the bar labels do not appear on initial mount when reading directly from props https://github.com/recharts/recharts/issues/829
        setFormattedData(formatData(data, jewishInterestLevels));
      }
    },
    [data, jewishInterestLevels],
  );

  const rowHeight = selectedEngagementPeriodIds?.length * 20;

  const minRowHeight = 60;

  const chartHeight =
    formattedData.length *
    (rowHeight >= minRowHeight ? rowHeight : minRowHeight);

  return (
    <div className="graph-outline mb-24">
      <StudentsMetricsChartHeader dataTotal={total} loading={loading} />
      <div className="mt-16 mb-16">
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <p className="error-text small-text text-center">{errorMessage}</p>
        ) : !formattedData.length ? (
          <NoMetrics message="No student goal metrics found" />
        ) : (
          <>
            <Box className="flex flex-align-center mobile-block">
              {selectedEngagementPeriodIds?.map((id) => (
                <ColoredLegendEntry
                  getColors={getColors}
                  id={id}
                  name={getEngagementPeriodLabel(id, engagementPeriods)}
                  key={id}
                />
              ))}
            </Box>
            <MetricsBarChart
              chartHeight={chartHeight}
              data={formattedData}
              getColors={getColors}
              isGoalData={true}
              metrics={selectedEngagementPeriodIds}
              tickYDataKey={"jewishInterestLevel"}
              tickYFormatter={(key) =>
                formatStudentMetricsTickY(
                  jewishInterestLevels,
                  key,
                  formattedData,
                )
              }
              tickXFormatter={(key) => `${key}%`}
            />
          </>
        )}
      </div>
    </div>
  );
}
