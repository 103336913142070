import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

export function ProfileFormSectionWithDelete(props: any) {
  const { children, onDelete, ...passProps } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        margin: "16px 0px 0px",
        padding: "16px",
        border: "1px solid #D8D8DD",
        borderRadius: "3px",
      }}
    >
      <Grid container spacing={4} {...passProps}>
        {children}
      </Grid>
      <IconButton
        color="primary"
        style={{ marginLeft: "16px" }}
        onClick={onDelete}
      >
        <Delete />
      </IconButton>
    </div>
  );
}
