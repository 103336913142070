import React, { Fragment, useCallback, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import { Genders, ShliachProfile } from "./ProfileFormHelpers";
import {
  getProfileSectionId,
  setUpProfileOutlineIntersectionObserver,
  profileMenuItemClassName,
  getProfileMenuItemId,
} from "./ProfileFormOutlineHelpers";
import { ProfileFormOutlineStyled } from "./ProfileForm.styles";

interface ProfileFormOutlineProps {
  initialFormValues: ShliachProfile;
}

export const ProfileFormOutline = React.memo(
  /**
   *
   */

  function ProfileFormOutline({ initialFormValues }: ProfileFormOutlineProps) {
    useEffect(() => {
      setUpProfileOutlineIntersectionObserver();
    }, []);

    return (
      <ProfileFormOutlineStyled>
        <Typography color="textSecondary" variant="overline">
          Personal
        </Typography>
        <OutlineItem
          sectionId={getProfileSectionId("person")}
          title={
            initialFormValues.person?.gender === Genders.Female
              ? "Shlucha"
              : "Shliach"
          }
        />
        {initialFormValues.spouse && (
          <OutlineItem
            sectionId={getProfileSectionId("spouse")}
            title={
              initialFormValues.spouse?.gender === Genders.Female
                ? "Shlucha"
                : "Shliach"
            }
          />
        )}
        <OutlineItem sectionId={getProfileSectionId("family")} title="Family" />

        {initialFormValues.chabadHouses.map((chabadHouse, index) => (
          <Fragment key={chabadHouse.id}>
            <Typography color="textSecondary" variant="overline">
              {chabadHouse.name}
            </Typography>

            <OutlineItem
              sectionId={getProfileSectionId("chabadHouseGeneral", index)}
              title="Chabad House info"
            />
            <OutlineItem
              sectionId={getProfileSectionId("chabadHousePersonnel", index)}
              title="Personnel"
            />
            <OutlineItem
              sectionId={getProfileSectionId("chabadHouseFinancial", index)}
              title="Financial info"
            />
          </Fragment>
        ))}

        {initialFormValues.campuses.map((campus, index) => (
          <Fragment key={campus.id}>
            <Typography color="textSecondary" variant="overline">
              {campus.name}
            </Typography>
            <OutlineItem
              sectionId={getProfileSectionId("campusGeneral", index)}
              title="School info"
            />
            <OutlineItem
              sectionId={getProfileSectionId("campusJewishStudentLife", index)}
              title="Jewish life"
            />
          </Fragment>
        ))}
      </ProfileFormOutlineStyled>
    );
  },
);

interface OutlineItemProps {
  sectionId: string;
  title: string;
}

function OutlineItem({ sectionId, title }: OutlineItemProps) {
  const onClick = useCallback(() => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [sectionId]);

  return (
    <Button
      className={profileMenuItemClassName}
      fullWidth
      id={getProfileMenuItemId(sectionId)}
      onClick={onClick}
      variant="text"
      style={{ display: "block", textAlign: "left" }}
    >
      {title}
    </Button>
  );
}
