import React, { memo, useCallback } from "react";
import PaginatedTable from "../../../components/PaginatedTableDeprecated";
import StudentsListFilters from "./StudentsListFilters";
import StudentsListHeader from "./StudentsListHeader";
import StudentsListRow from "./StudentsListRow";
import { PageURL } from "../../../lib";

function StudentsList(props) {
  const {
    updateArchiveStatus,
    deleteStudent,
    chabadHouseTags: { data: chabadHouseTags } = {},
    deleteTag,
    getStudents,
    pageRoute,
    pageRoute: {
      query: { p: page = 1, r: resultsView = 15 },
    },
    numberSelected,
    excludedStudentIds,
    results,
    removeSingleParticipantFromGroup,
    students: { data: { numberOfRows } = {}, loading, success },
    studentCampuses: { data: campuses = [] },
    selectedGroupName,
    toggleSelectAll,
    toggleSelectStudent,
    updateStudentGroup,
    viewStudentsByGroup,
    showSearch = true,
  } = props;

  const getNavigationURL = useCallback(
    (queryUpdates) => {
      const {
        pageRoute: { query, page, params },
      } = props;

      return PageURL.to(page, params, {
        ...query,
        ...queryUpdates,
      });
    },
    [props],
  );

  const getURLOnPaginate = useCallback(
    (page, resultsView) => {
      return getNavigationURL({ p: page, r: resultsView });
    },
    [getNavigationURL],
  );

  return (
    <div>
      <PaginatedTable
        className="my-students-table"
        getURL={getURLOnPaginate}
        filterComponent={
          <StudentsListFilters
            showSearch={showSearch}
            campuses={campuses}
            pageRoute={pageRoute}
            viewStudentsByGroup={viewStudentsByGroup}
            chabadHouseTags={chabadHouseTags}
            deleteTag={deleteTag}
            loadData={getStudents}
            totalCount={numberOfRows}
            getURL={getURLOnPaginate}
            resultsView={resultsView}
          />
        }
        filterComponentClassName={`students-filters-container mobile-block mobile-mb-8 flex-align-top`}
        twoRowFilter={true}
        loadData={getStudents}
        loading={loading}
        name="Students"
        page={page}
        pageRoute={pageRoute}
        records={results || []}
        renderHeaderRow={() => (
          <StudentsListHeader
            viewStudentsByGroup={viewStudentsByGroup}
            numberSelected={numberSelected}
            selectedGroupName={selectedGroupName}
            numberOfRows={numberOfRows}
            excludedStudentIds={excludedStudentIds}
            toggleSelect={toggleSelectAll}
            updateGroups={updateStudentGroup}
            chabadHouseTags={chabadHouseTags}
            loading={loading}
          />
        )}
        renderRow={(student, index) => (
          <StudentsListRow
            key={index}
            index={index}
            student={student}
            viewStudentsByGroup={viewStudentsByGroup}
            toggleSelectStudent={toggleSelectStudent}
            removeSingleParticipantFromGroup={removeSingleParticipantFromGroup}
            updateArchiveStatus={updateArchiveStatus}
            deleteStudent={deleteStudent}
          />
        )}
        resultsIncrement={15}
        resultsView={resultsView}
        showResultsTotal={true}
        showResultsView={true}
        success={success}
        totalCount={numberOfRows}
        viewStudentsByGroup={viewStudentsByGroup}
        hideViewResults={true}
      />
    </div>
  );
}

export default memo(StudentsList);
