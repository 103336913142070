import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Creatable } from "react-select";
import { Button, Checkbox, Tooltip } from "@material-ui/core";
import queryString from "query-string";
import Modal from "../../../components/Modal";
import Pages from "../../../pages";
import { PageLink } from "../../../lib";
import { AuthSelectors } from "../../../state";
import { getStudentListColumnLayout } from "../engagement/shared";

export default (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const studentMergePath = window.location.pathname.includes("engagement")
    ? Pages.engagement.studentMerge.path
    : Pages.students.studentMerge.path;
  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const { isEnrolledInEngagement } = useSelector(
    AuthSelectors.engagementAccess,
  );
  const showJewishInterestLevel = isShliach && isEnrolledInEngagement;

  const {
    chabadHouseTags,
    selectedGroupName,
    numberSelected,
    numberOfRows,
    viewStudentsByGroup,
    updateGroups,
    loading: propsLoading,
    toggleSelect,
    excludedStudentIds,
  } = props;

  return viewStudentsByGroup ? (
    !propsLoading ? (
      <div>
        <Modal show={modalOpen}>
          <div className="student-details-modal-container modal-container full-page-mobile-modal-container">
            <div className="sms-compose-mes-modal">
              <div className="large-text">Add to student groups</div>
              <div className="mt-20">
                <span className="accent-text">
                  Choose groups or enter a new one
                </span>
                <div className="mt-10">
                  <Creatable
                    onChange={(vals) => {
                      setTags(vals.map((g) => ({ ...g, tagName: g.value })));
                    }}
                    isMulti
                    options={chabadHouseTags?.map((e, i) => ({
                      ...e,
                      key: i,
                      value: e.tagName,
                      label: e.tagName,
                    }))}
                    value={tags}
                    name={"chabadHouseTags"}
                  />
                </div>
              </div>
              <div className="buttons-section">
                <button
                  className="btn btn-light mr-16"
                  onClick={() => {
                    setTags([]);
                    setModalOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={async () => {
                    setLoading(true);
                    await updateGroups(true, tags, false);
                    setTags([]);
                    setModalOpen(false);
                    setLoading(false);
                  }}
                  className="btn btn-accent"
                >
                  {loading ? "Adding..." : "Add"}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <div className="paginated-table-header-row student-group-table-row students-groups-table-controls mb-16">
          <div>
            <span>
              <Checkbox
                checked={!!numberSelected}
                color="default"
                indeterminate={
                  !!numberSelected && numberSelected !== numberOfRows
                }
                inputProps={{ "aria-label": "checkbox with default color" }}
                onChange={(p) => {
                  toggleSelect(p.target.checked);
                }}
              />
            </span>

            <span className="accent-text">{numberSelected || 0} Selected</span>
          </div>
          {!!numberSelected && (
            <div>
              <Button
                onClick={() => {
                  setModalOpen(true);
                }}
                size="small"
                variant="text"
              >
                <i className="material-icons student-groups-heading-icons">
                  add
                </i>{" "}
                Add to a group
              </Button>
              <Tooltip
                disableHoverListener={!!selectedGroupName}
                arrow
                title="Select a group to remove students."
              >
                <span>
                  <Button
                    disabled={!selectedGroupName}
                    onClick={() => {
                      updateGroups(false);
                    }}
                    variant="text"
                    size="small"
                    style={{ alignItems: "center", maxWidth: "250px" }}
                  >
                    <i
                      className={`material-icons ${
                        !!selectedGroupName ? "accent-text" : ""
                      } student-groups-heading-icons`}
                    >
                      remove_circle
                    </i>{" "}
                    Remove
                    {selectedGroupName ? " from " + selectedGroupName : ""}
                  </Button>
                </span>
              </Tooltip>
            </div>
          )}
        </div>
        <div
          className="paginated-table-header-row student-group-table-row student-group-table-header"
          style={{ padding: "12px 2px" }}
        >
          <p className="fw-900"> </p>
          <p className="fw-900">Name</p>
          <p className="fw-900">Grad Year</p>
          <p className="fw-900">Group</p>
          <p />
        </div>
      </div>
    ) : (
      <div></div>
    )
  ) : (
    <div>
      {isShliach && (
        <div className="paginated-table-header-row student-group-table-row students-groups-table-controls mb-16">
          <div>
            <span>
              <Checkbox
                checked={!!numberSelected}
                color="default"
                indeterminate={
                  !!numberSelected && numberSelected !== numberOfRows
                }
                inputProps={{ "aria-label": "checkbox with default color" }}
                onChange={(p) => {
                  toggleSelect(p.target.checked);
                }}
              />
            </span>

            <span className="accent-text">{numberSelected || 0} Selected</span>
          </div>
          {numberSelected > 1 && (
            <PageLink
              className="btn"
              to={`${studentMergePath}?${queryString.stringify({
                id: excludedStudentIds,
              })}`}
            >
              <Button size="small" variant="text">
                <i className="material-icons student-groups-heading-icons">
                  merge
                </i>
                Merge Duplicates
              </Button>
            </PageLink>
          )}
        </div>
      )}
      <div className={isShliach && "students-list-table-row-container"}>
        {
          isShliach && <p></p>
          //match spacing for checkbox
        }
        <div
          className="paginated-table-header-row students-list-table-row"
          style={{
            gridTemplateColumns: getStudentListColumnLayout(
              showJewishInterestLevel,
            ),
          }}
        >
          <p className="fw-900">Name</p>
          <p className="fw-900">Email</p>
          <p className="fw-900">Phone</p>
          <p className="fw-900">Graduation Year</p>
          <p className="fw-900">Campus</p>
          {showJewishInterestLevel && <p className="fw-900">Status</p>}
          <p />
        </div>
      </div>
    </div>
  );
};
