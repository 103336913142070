import React, { useState } from "react";
import { FieldArray } from "formik";
import FormInput from "../../../../components/formik/FormInput";

function PaymentProvidersInfo(props) {
  const { errors, touched } = props;
  const [showSecret, setShowSecret] = useState(false);

  const formInput = (label, name, tooltip, key, endIcon, type = "text") => (
    <div className="profile-form-stripe-info-field relative">
      <FormInput
        label={
          <React.Fragment>
            {label}
            <span className="tooltip-container ml-8 flex flex-align-center">
              <i className="material-icons large-text link-text no-hover">
                info
              </i>
              <span className="tooltip-medium-width tooltip-left">
                {tooltip}
              </span>
            </span>
          </React.Fragment>
        }
        type={type}
        key={key}
        name={name}
        labelClassNames="accent-text-dark profile-form-stripe-info-label flex flex-align-center"
        className="profile-form-stripe-info-input"
        errors={errors}
        touched={touched}
        autoComplete={"off"}
      />
      {endIcon && (
        <div style={{ right: 24, position: "absolute" }}>{endIcon}</div>
      )}
    </div>
  );

  const paymentProviderAccountsTouched = () =>
    touched &&
    touched.paymentProviderAccounts &&
    touched.paymentProviderAccounts.some(
      (ppa) =>
        !!ppa.paymentProviderAccountID || !!ppa.publicKey || !!ppa.secretKey,
    );

  return (
    <>
      <div className="flex flex-align-center flex-justify-space">
        <p className="fw-700 accent-text-dark mb-8 flex flex-align-center">
          Stripe Account
          <span className="accent-text small-text ml-8">(optional)</span>
        </p>
      </div>
      <div className="accent-text small-text mb-16">
        A Stripe account is required to set an event registration fee or to
        request an event donation
        {errors.paymentProviderAccounts && paymentProviderAccountsTouched() && (
          <div className="error-text small-text fw-400 mt-4">
            All account information must be entered.
          </div>
        )}
      </div>
      <div className="profile-form-stripe-info">
        <FieldArray name="paymentProviderAccounts">
          {/* currently only one payment provider - Stripe - is allowed per Chabad House */}
          <div key={0}>
            {formInput(
              "Account ID",
              `paymentProviderAccounts[0].paymentProviderAccountID`,
              <span>
                Log into Stripe account. Click on the gears icon to view
                settings (top right). Click account details. Copy your{" "}
                <b>account ID</b> from the far right.
              </span>,
              "accountId",
            )}
            {formInput(
              "Publishable Key",
              `paymentProviderAccounts[0].publicKey`,
              <span>
                Log into Stripe account. Click developers (top right). Click API
                Keys (left side menu). Find your <b>publishable key</b>.
              </span>,
              "publishKey",
            )}
            {formInput(
              "Secret Key",
              `paymentProviderAccounts[0].secretKey`,
              <span>
                Use your full, 24+ character-long <b>Secret Key</b>. If you
                don’t know it, or already generated it and only have the short
                version (ex sk_live…abc), you’ll need to create a new one. Log
                into Stripe account. Click developers (top right). Click API
                Keys. Click Create secret key. Copy the value before closing the
                confirmation modal.
              </span>,
              showSecret,
              <span
                key="show-hide-icon"
                className="tooltip-container flex flex-align-center"
              >
                <i
                  className="material-icons medium-text accent-text-secondary ml-8"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowSecret(!showSecret)}
                >
                  {showSecret ? "visibility" : "visibility_off"}
                </i>
                <span className="tooltip">View/hide secret key</span>
              </span>,
              showSecret ? "text" : "password",
            )}
          </div>
        </FieldArray>
      </div>
    </>
  );
}

export default PaymentProvidersInfo;
