import React from "react";
import { Prompt } from "react-router-dom";
import { Formik, Form } from "formik";
import { Tab, Tabs } from "@material-ui/core";
import {
  ApiCallErrorMessageHandler,
  formatValuesForForm,
  Navigation,
  PageURL,
} from "../../../lib";

import ActivitiesForm from "./activities/ActivitiesForm";
import GeneralForm from "./general/GeneralForm";
import IncompleteProfileIndicationSetter from "../IncompleteProfileIndicationSetter";
import Loader from "../../../components/Loader";

export default class ChabadHouse extends React.PureComponent {
  state = {
    errorMessage: false,
    saving: false,
    tabContentShowing: "general",
  };

  async componentDidMount() {
    let {
      getProfileChabadHouse,
      pageRoute: {
        params: { chabadHouseId },
        query: { tab },
      },
    } = this.props;

    if (tab === undefined) {
      tab = "general";
      this.navigate(tab);
    } else {
      this.setState({ tabContentShowing: tab });
    }

    if (!chabadHouseId) {
      const { chabadHouses } = this.props;
      let url;
      if (chabadHouses && chabadHouses.length > 0) {
        chabadHouseId = chabadHouses[0].id;
        url = `/account/ChabadHouse/${chabadHouseId}`;
        if (tab) url = `${url}?tab=${tab}`;
        Navigation.go(url);
      }
    } else {
      getProfileChabadHouse(chabadHouseId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageRoute !== prevProps.pageRoute) {
      const {
        query: { tab },
      } = this.props.pageRoute;
      const {
        query: { tab: prevTab },
      } = prevProps.pageRoute;

      if (tab !== prevTab) {
        this.setState({ tabContentShowing: tab });
        if (tab === undefined) {
          this.navigate("general");
        }
      }
    }
  }

  navigate = (tab) => {
    const {
      pageRoute: { page, params, query },
    } = this.props;
    const url = PageURL.to(page, params, { ...query, tab });
    Navigation.redirect(url);
  };

  validateForm = (values) => {
    const {
      contactMethods = [],
      campuses = [],
      holidayEvents = [],
      paymentProviderAccounts = [],
    } = values;
    const errors = {};
    if (contactMethods.length < 1) {
      errors.incompleteContactMethods = true;
    } else {
      let types = [];
      for (let i = 0; i < contactMethods.length; i++) {
        types.push(contactMethods[i].type);
      }
      if (!types.includes("WorkNumber")) {
        errors.incompleteContactMethods = true;
      } else {
        delete errors.incompleteContactMethods;
      }
    }
    let inputs = [].slice.call(document.getElementsByClassName("phone-input"));
    let invalidInputs = [];
    inputs.map((input) => {
      let invalidInput = input
        .getElementsByTagName("INPUT")[0]
        .hasAttribute("invalid");
      invalidInputs.push(invalidInput);
      return invalidInputs;
    });
    if (invalidInputs.includes(true)) {
      errors.invalidPhoneNumber = true;
    } else {
      delete errors.invalidPhoneNumber;
    }

    let primarySchools = [];
    for (let j = 0; j < campuses.length; j++) {
      primarySchools.push(campuses[j].isPrimary);
    }
    if (campuses.length > 0 && !primarySchools.includes(true)) {
      errors.noPrimaryCampus = true;
    } else {
      delete errors.noPrimaryCampus;
    }

    if (holidayEvents.length < 1) {
      errors.holidayEvents = true;
    } else {
      delete errors.holidayEvents;
    }

    if (
      paymentProviderAccounts.some(
        (ppa) =>
          !!ppa.paymentProviderAccountID !== !!ppa.publicKey ||
          !!ppa.paymentProviderAccountID !== !!ppa.secretKey,
      )
    ) {
      errors.paymentProviderAccounts = true;
    } else {
      delete errors.paymentProviderAccounts;
    }

    if (paymentProviderAccounts.some((a) => a.secretKey.length < 20)) {
      errors["paymentProviderAccounts[0].secretKey"] =
        "Secret key is too short. (See field tooltip for more details)";
    } else {
      delete errors["paymentProviderAccounts[0].secretKey"];
    }

    return errors;
  };

  checkForErrors = (errors) => {
    errors = errors && Object.keys(errors);
    if (errors && errors.length > 0) {
      this.setState({ errorMessage: true });
    }

    return;
  };

  getValuesForSubmission = (values) => {
    let { demographics, squareFootage, ...chabadHouseValues } = values;
    //set demographics to be an array of objects not strings
    demographics = demographics.map((d) => ({
      chabadHouseId: chabadHouseValues.id,
      demographic: d.value ? d.value : d.demographic,
    }));
    chabadHouseValues.demographics = demographics;
    //set square footage as an int if its a string
    if (typeof squareFootage === "string") {
      chabadHouseValues.squareFootage = parseInt(
        squareFootage.replace(/,/g, ""),
        10,
      );
    } else {
      chabadHouseValues.squareFootage = squareFootage;
    }
    return chabadHouseValues;
  };

  onSubmit = async (values, { resetForm }) => {
    this.setState({ saving: true, errorMessage: false });

    let chabadHouseValues = this.getValuesForSubmission(values);
    await this.props.actions.submitProfileChabadHouse(
      chabadHouseValues,
      this.props.shliachId,
    );

    if (!this.props.submitError) {
      setTimeout(() => {
        let { chabadHouse: { chabadHouse = {} } = {} } = this.props;
        let updatedValues = formatValuesForForm({ ...chabadHouse });
        resetForm(updatedValues);
        this.setState({ saving: false });
      }, 1500);
    } else {
      this.setState({ saving: false });
    }
  };

  render() {
    const {
      campuses = [],
      chabadHouse: { chabadHouse = {}, loading } = {},
      isIncomplete,
      permissionClaims,
      submitError,
      submitted,
      sys,
      user,
    } = this.props;

    const { errorMessage, saving, tabContentShowing } = this.state;

    return loading ? (
      <div className="card profile-form-card mr-24">
        <div>
          <Loader />
        </div>
      </div>
    ) : (
      <Formik
        initialValues={formatValuesForForm({ ...chabadHouse })}
        validate={this.validateForm}
        onSubmit={this.onSubmit}
        isInitialValid={true}
      >
        {({
          initialValues,
          values,
          dirty,
          isValid,
          errors,
          touched,
          handleChange,
          setFieldError,
          setFieldValue,
          setTouched,
        }) => {
          const hasGeneralErrors =
            (errors.demographics ||
              errors.contactMethods ||
              errors.isCHAddressPreferred ||
              errors.regionID ||
              errors.websiteURL ||
              errors.campuses) &&
            (isIncomplete || this.state.errorMessage);
          const hasActivityErrors =
            (errors.annualBudget ||
              errors.friDinnerFrequency ||
              errors.hebrewSchool ||
              errors.holidayEvents ||
              errors.numberStudentsFriNight ||
              errors.ownsChabadHouse ||
              errors.squareFootage ||
              errors.studentBoard ||
              errors.totalFundraisedPerYear ||
              errors.zoning) &&
            (isIncomplete || this.state.errorMessage);
          return (
            <Form className="flex tablet-block mobile-block full-width">
              <Prompt
                when={dirty}
                message={(location) =>
                  location.pathname.includes("/account/chabadHouse")
                    ? true
                    : `Your changes have not been saved. Are you sure you want to continue?`
                }
              />
              {isIncomplete && (
                <IncompleteProfileIndicationSetter
                  setTouched={setTouched}
                  touched={touched}
                  values={values}
                />
              )}
              <div className="card profile-form-card mr-24">
                <Tabs
                  className="mb-16 profile-form-tabs-container"
                  variant="scrollable"
                  indicatorColor="primary"
                  textColor="primary"
                  value={tabContentShowing}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor:
                        (tabContentShowing === "general" && hasGeneralErrors) ||
                        (tabContentShowing === "activities" &&
                          hasActivityErrors)
                          ? "#e94746"
                          : "",
                    },
                  }}
                  onChange={(_, value) => {
                    this.navigate(value);
                  }}
                >
                  <Tab
                    label="General"
                    value="general"
                    className={`${hasGeneralErrors ? "has-errors" : ""}`}
                  />
                  <Tab
                    label="Activities & Other"
                    value="activities"
                    className={`${hasActivityErrors ? "has-errors" : ""}`}
                  />
                </Tabs>

                {/* render these fields and hide with css when applicable so Formik is aware these fields exist - for error checking */}
                <div
                  className={
                    tabContentShowing === "activities"
                      ? ""
                      : "desktop-hidden tablet-hidden mobile-hidden"
                  }
                >
                  <ActivitiesForm
                    errors={errors}
                    handleChange={handleChange}
                    permissionClaims={permissionClaims}
                    sys={sys}
                    touched={touched}
                    values={values}
                  />
                </div>
                <div
                  className={
                    tabContentShowing === "general"
                      ? ""
                      : "desktop-hidden tablet-hidden mobile-hidden"
                  }
                >
                  <GeneralForm
                    campuses={campuses}
                    errors={errors}
                    handleChange={handleChange}
                    initialValues={initialValues}
                    permissionClaims={permissionClaims}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    sys={sys}
                    touched={touched}
                    user={user}
                    values={values}
                  />
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <div className="profile-save-btn-container">
                  <button
                    className="btn btn-accent profile-save-btn"
                    type="submit"
                    disabled={!dirty ? true : false}
                    onClick={() => this.checkForErrors(errors)}
                  >
                    {saving ? "Saving..." : "Save"}
                  </button>
                  {submitError ? (
                    <p className="error-message">
                      {ApiCallErrorMessageHandler(
                        submitError,
                        <React.Fragment>
                          Something went wrong. <br /> Please try again.
                        </React.Fragment>,
                      )}
                    </p>
                  ) : submitted && !dirty ? (
                    <p className="accent-text-dark">
                      <i className="material-icons">check</i> All changes saved
                    </p>
                  ) : dirty ? (
                    <p className="accent-text-dark">
                      <i className="material-icons">save</i>
                      Unsaved changes
                    </p>
                  ) : null}
                  {errorMessage && !isValid ? (
                    <p className="error-message">
                      Please review all required fields for both{" "}
                      <span className="fw-700">General</span> and{" "}
                      <span className="fw-700">Activities & Other</span>{" "}
                      sections
                    </p>
                  ) : null}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
