import React from "react";

import FormInput from "../../../components/formik/FormInput";

export default class AttributesInfo extends React.PureComponent {
  state = {
    attributesInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        attributesInfoShowing: false,
      });
    }
  }

  getNestedAttributes = (attributes) => {
    let nestedAttributes = {};
    attributes &&
      attributes.forEach((attribute) => {
        if (!nestedAttributes.hasOwnProperty(attribute.section)) {
          nestedAttributes[attribute.section] = {};
        }
        if (
          !nestedAttributes[attribute.section].hasOwnProperty(
            attribute.subSection,
          )
        ) {
          nestedAttributes[attribute.section][attribute.subSection] = [];
        }
        nestedAttributes[attribute.section][attribute.subSection].push(
          attribute,
        );
      });
    return nestedAttributes;
  };

  render() {
    const { values, attributes } = this.props;
    const nestedAttributes = this.getNestedAttributes(attributes);
    return (
      <div
        className={
          this.state.attributesInfoShowing
            ? "profile-form-section"
            : "profile-form-section school-attributes-info mobile-tab-collapsed"
        }
      >
        {Object.keys(nestedAttributes).map((section) => (
          <React.Fragment key={section}>
            <p className="accent-text-dark medium-text fw-700 mt-48 mb-16 school-attributes-info-title flex flex-align-center flex-justify-space">
              {section}
              <i
                className="material-icons collapsible"
                onClick={() => {
                  this.setState({
                    attributesInfoShowing: !this.state.attributesInfoShowing,
                  });
                }}
              >
                {this.state.attributesInfoShowing
                  ? "keyboard_arrow_up"
                  : "keyboard_arrow_down"}
              </i>
            </p>
            <p className="small-text accent-text mb-16 fw-400">
              Please select all that apply.
            </p>
            {Object.keys(nestedAttributes[section]).map((subsection, index) => (
              <React.Fragment key={index}>
                <p className="accent-text fw-700 mb-8">{subsection}</p>
                <div className="attributes-grid">
                  {nestedAttributes[section][subsection].map((attribute) => {
                    const attributeIndex = values.attributes
                      .map((a) => a.chabadHouseAttributeID)
                      .indexOf(attribute.id);
                    return (
                      <div
                        key={attribute.name}
                        style={
                          attribute.dataType !== "Boolean"
                            ? { gridColumnStart: "span 2" }
                            : null
                        }
                      >
                        {attribute.dataType === "Boolean" ? (
                          <FormInput
                            name={`attributes[${attributeIndex}].value`}
                          >
                            {({ field }) => (
                              <div className="flex custom-checkbox-container">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox"
                                  id={`attribute-${attribute.name}`}
                                  name={field.name}
                                  onChange={this.props.handleChange}
                                  value={
                                    values.attributes[attributeIndex].value
                                  }
                                  checked={
                                    !values.attributes[attributeIndex].value ||
                                    values.attributes[attributeIndex].value ===
                                      "false"
                                      ? false
                                      : true
                                  }
                                />
                                <label htmlFor={`attribute-${attribute.name}`}>
                                  <span>{attribute.name}</span>
                                </label>
                              </div>
                            )}
                          </FormInput>
                        ) : (
                          <div className="attributes-grid-input flex flex-align-center">
                            <FormInput
                              name={`attributes[${attributeIndex}].value`}
                              type="number"
                              label={attribute.name}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </div>
    );
  }
}
