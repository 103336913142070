import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export default class DateTimePicker extends React.PureComponent {
  render() {
    const {
      className,
      dateFormat,
      disabled,
      maxDate,
      minDate,
      name,
      onChange,
      readOnly,
      showTime,
      timeIntervals,
      value,
      ...passProps
    } = this.props;

    // because safari new Date() passing in our dotnet date format does not recognize the no-timezone and converts to local tz, we instead get the JS date by doing moment().toDate()
    return (
      <ReactDatePicker
        className={className}
        dateFormat={
          dateFormat || (showTime ? "MM/dd/yyyy h:mm a" : "MM/dd/yyyy")
        }
        disabled={disabled}
        id={name}
        maxDate={maxDate ? moment(maxDate).toDate() : undefined}
        minDate={minDate ? moment(minDate).toDate() : undefined}
        onChange={(date) => onChange(name, date)}
        readOnly={readOnly}
        selected={value ? moment(value).toDate() : null}
        showTimeSelect={showTime}
        timeIntervals={showTime ? timeIntervals || 15 : undefined}
        {...passProps}
      />
    );
  }
}
