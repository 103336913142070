import React from "react";
import Pages from "../../../pages";
import Loader from "../../../components/Loader";
import { PROFILE_PLACEHOLDER_URL, PageLink } from "../../../lib";

export default class ChabadHouseCard extends React.PureComponent {
  renderPersonnel = (personnel, i) => {
    return (
      <div className="ch-personnel-member" key={i}>
        <img
          src={
            personnel.profileImageURL
              ? personnel.profileImageURL
              : PROFILE_PLACEHOLDER_URL
          }
          alt="personnel profile pic"
        />
        {personnel.shliachID &&
        personnel.shliachID !== parseInt(this.props.contactId, 10) ? (
          <PageLink
            to={Pages.directory.directoryDetails}
            params={{ contactId: personnel.shliachID }}
            className="ml-8 link-text"
          >
            {personnel.title} {personnel.fullName}
          </PageLink>
        ) : (
          <p className="ml-8">
            {personnel.shliachID
              ? `${personnel.title} ${personnel.fullName}`
              : `${personnel.firstName} ${personnel.lastName}`}
          </p>
        )}
        <p className="accent-text small-text">
          {personnel.shliachID
            ? `${personnel.shliachPositionDisplay}`
            : `${personnel.positionDisplay}`}
        </p>
      </div>
    );
  };

  renderSchools = (school) => {
    return (
      <div className="ch-school" key={school.campusID}>
        <p className="large-text fw-700 flex flex-align-center">
          {school.campusName}
          {school.isPrimary ? (
            <span className="tag small primary ml-16">Primary</span>
          ) : (
            ""
          )}
        </p>
        <PageLink
          to={Pages.directory.directoryDetails}
          params={{ contactId: this.props.contactId }}
          query={{
            view: "school",
            schoolId: school.campusID,
            chabadHouseId: this.props.chabadHouse.id,
          }}
          className="link-text uppercase-text text-center"
        >
          View <span className="mobile-hidden">Details</span>
        </PageLink>
      </div>
    );
  };

  renderSchoolCardDetails = (id) => {
    const { schools } = this.props.schoolsObject;
    const { schoolId } = this.props.pageRoute.query;
    if (!schools) return null;

    let chabadHouseIds = [];
    schools.forEach((school) =>
      chabadHouseIds.push(...school.chabadHouses.map((ch) => ch.chabadHouseID)),
    );

    if (chabadHouseIds.indexOf(id) >= 0) {
      const school = schools.find((s) => s.id.toString() === schoolId);
      if (school) {
        return (
          <div className="school-card directory-card">
            <div className="school-card-header">
              <p className="xxl-text fw-700 flex flex-align-center">
                {school.name}
                {school.isPrimary ? (
                  <span className="tag small primary ml-16">Primary</span>
                ) : (
                  ""
                )}
              </p>
              <div className="school-contact-info">
                <p className="contact-info medium-text">
                  {this.props.renderPhone(school.phone, true)}
                </p>
                <p className="contact-info medium-text">
                  {this.props.renderEmail(school.email, true)}
                </p>
              </div>
              <div className="social-links">
                {school.facebookURL ? (
                  <a
                    href={school.facebookURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook" />
                  </a>
                ) : null}
                {school.instagramURL ? (
                  <a
                    href={school.instagramURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                ) : null}
                {school.twitterURL ? (
                  <a
                    href={school.twitterURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                ) : null}
                {school.snapchatURL ? (
                  <a
                    href={school.snapchatURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-snapchat-ghost" />
                  </a>
                ) : null}
              </div>
            </div>
            <div className="school-card-section">
              <p className="uppercase-text accent-text-dark mb-16 fw-500">
                Programs and Services Offered
              </p>
              <div className="services tag-container">
                {school.jewishStudentLife.map(({ name }) => (
                  <p className="tag medium light rounded" key={name}>
                    {name}
                  </p>
                ))}
              </div>
            </div>
            <div className="school-card-section four-column-section">
              <p className="fw-700">Area of Study</p>
              <p>
                {school.studyAreas?.length
                  ? school.studyAreas.map((a, i) => {
                      return (
                        <span className="comma-separated" key={i}>
                          {a.studyArea}
                        </span>
                      );
                    })
                  : "— —"}
              </p>
              <p className="fw-700">Tuition</p>
              <p>
                {school.annualTuition
                  ? "$" +
                    school.annualTuition
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "— —"}
              </p>
              <p className="fw-700">Student Residency</p>
              <p>
                {school.studentResidencyType !== "Unknown"
                  ? school.studentResidencyType
                  : "— —"}
              </p>
              <p className="fw-700">Level of Education</p>
              <p>
                {school.programLevels?.length
                  ? school.programLevels.map((level, i) => {
                      return (
                        <span className="comma-separated" key={i}>
                          {level.programLevel}
                        </span>
                      );
                    })
                  : "— —"}
              </p>
              <p className="fw-700">Population</p>
              <p>
                {school.undergradPopulation || school.graduatePopulation
                  ? (
                      (school.undergradPopulation || 0) +
                      (school.graduatePopulation || 0)
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " students"
                  : "— —"}
              </p>
            </div>
            <div className="school-card-section two-column-section">
              <p className="fw-700">Shliach recognized chaplain</p>
              <p>{school.shliachChaplainStatusDisplay || "— —"}</p>
              <p className="fw-700">Shlucha recognized chaplain</p>
              <p>{school.shluchaChaplainStatusDisplay || "— —"}</p>
              <p className="fw-700">Chabad student group recognition</p>
              <p>{school.chabadStudentGroupStatusDisplay || "— —"}</p>
            </div>
          </div>
        );
      }
    }

    return null;
  };

  render() {
    const {
      id,
      address,
      campuses,
      facebookURL,
      instagramURL,
      twitterURL,
      snapchatURL,
      name,
      shluchim,
      personnel,
      phone,
      email,
      websiteURL,
      region,
    } = this.props.chabadHouse;
    const { isFullTime } =
      this.props.chabadHouse &&
      this.props.chabadHouse.campuses.length > 0 &&
      this.props.chabadHouse.campuses[0];
    const { loading } = this.props.schoolsObject;
    const { renderEmail, renderPhone } = this.props;
    const addressHref = `https://www.google.com/maps/place/${
      address.address1 ? `${address.address1.replace(/ /g, "+")}+` : ""
    }${address.address2 ? `${address.address2.replace(/ /g, "+")}+` : ""}${
      address.city ? `${address.city.replace(/ /g, "+")}+` : ""
    }${address.state ? `${address.state.replace(/ /g, "+")}+` : ""}${
      address.zip ? `${address.zip.replace(/ /g, "+")}` : ""
    }`;

    return this.props.pageRoute.query.view !== "school" ? (
      <div className="ch-card directory-card">
        <p className="uppercase-text accent-text-dark mb-8 fw-500">
          Chabad House
        </p>
        <div className="ch-card-top">
          <p className="fw-700 xl-text align-self-center">{name}</p>
          <p className="fw-500 accent-text-dark small-text uppercase-text align-self-center tablet-hidden mobile-hidden">
            personnel
          </p>
          <div className="social-links align-self-center">
            {facebookURL ? (
              <a href={facebookURL} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook" />
              </a>
            ) : null}
            {instagramURL ? (
              <a href={instagramURL} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram" />
              </a>
            ) : null}
            {twitterURL ? (
              <a href={twitterURL} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter" />
              </a>
            ) : null}
            {snapchatURL ? (
              <a href={snapchatURL} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-snapchat-ghost" />
              </a>
            ) : null}
          </div>
          <div className="ch-contact-info">
            <p className="mb-16">
              <span className="fw-700">Phone:</span>
              {renderPhone(phone)}
            </p>
            <p className="mb-16">
              <span className="fw-700">Email:</span>
              {renderEmail(email)}
            </p>
            <p className="mb-16 ch-address">
              <span className="fw-700">Address:</span>
              <a
                href={addressHref}
                target="_blank"
                rel="noopener noreferrer"
                className="link-text-secondary underline-text"
                style={{ lineHeight: "1.4" }}
              >
                <span>
                  {address.address1 !== null ? address.address1 : null}
                </span>
                &nbsp;
                <span>
                  {address.address2 !== null ? address.address2 : null}
                </span>
                {address.address2 ? <span>&nbsp;</span> : null}
                <span className="desktop-hidden tablet-hidden block" />
                <span>{address.city !== null ? address.city : null}</span>
                &nbsp;
                <span>{address.state !== null ? address.state : null}</span>
                &nbsp;
                <span>{address.zip !== null ? address.zip : null}</span>
                &nbsp;
                <span className="desktop-hidden tablet-hidden block" />
                <span>{address.country !== null ? address.country : null}</span>
              </a>
            </p>
            <p className="mb-16">
              <span className="fw-700">Website:</span>
              {websiteURL ? (
                <a
                  href={
                    websiteURL
                      ? `https://${websiteURL
                          .replace(/^https?:\/\//i, "")
                          .replace(/\/$/, "")}`
                      : ""
                  }
                  className="link-text-secondary underline-text"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ wordBreak: "break-word", lineHeight: "1.4" }}
                >
                  {websiteURL.replace(/^https?:\/\//i, "").replace(/\/$/, "")}
                </a>
              ) : (
                ""
              )}
            </p>

            <p className="mb-16">
              <span className="fw-700">Region:</span> {region && region.name}
            </p>
            <p>
              <span className="fw-700">Serves Campus:</span>{" "}
              {isFullTime ? "Full Service" : "Limited Service"}
            </p>
          </div>
          <p className="fw-500 accent-text-dark small-text uppercase-text align-self-center desktop-hidden mt-32 ch-personnel-header">
            personnel
          </p>
          <div className="ch-personnel">
            {shluchim.map(this.renderPersonnel)}
            {personnel.map(this.renderPersonnel)}
          </div>
        </div>
        {!loading ? (
          campuses.length ? (
            <div className="ch-schools">
              <p className="uppercase-text accent-text-dark mb-32 fw-500">
                Schools
              </p>
              {campuses.map(this.renderSchools)}
            </div>
          ) : null
        ) : (
          <div className="medium-loader mt-16">
            <Loader />
          </div>
        )}
      </div>
    ) : (
      this.renderSchoolCardDetails(id)
    );
  }
}
