import React, { useState, Fragment, useEffect, useCallback } from "react";
import { Prompt } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Pages from "../../../pages";
import queryString from "query-string";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loader";
import ConfirmationModal from "../../../components/ConfirmationModal";
import MergeDuplicateForm from "./MergeDuplicateForm";
import { ApiCallErrorMessageHandler } from "../../../lib";
import { StudentsActions, SystemSelectors } from "../../../state";
import { mergeFields, defaultValue } from "./constants";

const incompleteSubmissionErrorMessage =
  "You must select one option for each field that has a conflict";
const getTypeDisplayValue = (typeList, value, isKeyValue) => {
  if (typeList) {
    const fieldValue = isKeyValue ? value?.value : value;
    const index = typeList.findIndex((t) => t.enumValue === fieldValue);
    return index > -1 ? typeList[index].displayValue : defaultValue;
  }
};

const StudentMergeDuplicates = (props) => {
  const {
    pageRoute: {
      history,
      query,
      query: { id: ids },
    },
  } = props;

  const studentsListPath = window.location.pathname.includes("engagement")
    ? Pages.engagement.students.path
    : Pages.students.students.path;
  const sysLists = useSelector(SystemSelectors.allSysLists);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mergeErrorMessage, setMergeErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [finalStudent, setFinalStudent] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [students, setStudents] = useState([]);
  const [submitMergeLoading, setSubmitMergeLoading] = useState(false);
  const [unresolvedFields, setUnresolvedFields] = useState([]);
  let dirty = unresolvedFields.length > 0 && students.length > 1;

  const removeStudent = (index) => {
    students.splice(index, 1);
    setFinalStudent({});
    setStudents(students);
    const studentIds = students.map((s) => s.studentID);
    studentIds.length > 1
      ? history.replace(
          `${studentsListPath}/merge?${queryString.stringify({
            id: studentIds,
          })}`,
        )
      : history.push(studentsListPath);
  };

  const getStudentFields = useCallback(() => {
    const fieldsToResolve = [];
    const mappedFields = mergeFields.map((field) => ({
      ...field,
      getDisplayValue:
        field.getDisplayValue ||
        ((value) =>
          getTypeDisplayValue(
            sysLists[field.displayList],
            value,
            field.isKeyValue,
          )),
      setAsUnresolved: (name) => {
        if (!fieldsToResolve.includes(name)) {
          fieldsToResolve.push(name);
          setUnresolvedFields(fieldsToResolve);
        }
      },
    }));
    setFields(mappedFields);
  }, [sysLists]);

  const compareStudents = useCallback(
    async ({ id: ids }) => {
      setLoading(true);
      try {
        const students = await StudentsActions.compareStudentDuplicates(ids);
        // reordering the list of students to match the order of ids - so finalStudent defaults to the first student selected
        const reorderedStudentsList = ids.map((id) =>
          students.find((s) => s.studentID === parseInt(id)),
        );
        setFinalStudent(reorderedStudentsList[0]);
        setLoading(false);
        setStudents(reorderedStudentsList);
        getStudentFields();
      } catch (err) {
        if (!axios.isCancel(err)) {
          const is404 = err.response?.status === 404;
          const is403 = err.response?.status === 403;
          const msg = is404
            ? "One or more of the requested students could not be found."
            : is403
            ? "Cannot merge requested students."
            : ApiCallErrorMessageHandler(
                err,
                "Sorry, something went wrong. Please try again.",
              );
          setError(true);
          setErrorMessage(msg);
          setLoading(false);
        }
      }
    },
    [getStudentFields],
  );

  const resolveField = (val, name) => {
    setFinalStudent({
      ...finalStudent,
      [name]: val,
    });
    const unresolved = unresolvedFields.filter((f) => f !== name);
    setUnresolvedFields(unresolved);

    if (!unresolved.length) {
      setMergeErrorMessage("");
    }
  };

  const selectAllFields = (index) => {
    setFinalStudent(students[index]);
    setUnresolvedFields([]);
  };

  const selectAllSectionFields = (index, section) => {
    const student = students[index];
    let fieldsToUpdate = {};
    const sectionFieldNames = fields
      .filter((f) => f.category === section)
      .map((f) => f.name);
    sectionFieldNames.forEach((name) => {
      fieldsToUpdate = { ...fieldsToUpdate, [name]: student[name] };
    });

    setFinalStudent({
      ...finalStudent,
      ...fieldsToUpdate,
    });
    const unresolved = unresolvedFields.filter(
      (field) => !sectionFieldNames.includes(field),
    );
    setUnresolvedFields(unresolved);

    if (!unresolved.length) {
      setMergeErrorMessage("");
    }
  };

  const merge = async () => {
    try {
      setShowConfirmationModal(false);
      if (unresolvedFields.length > 0) {
        setMergeErrorMessage(incompleteSubmissionErrorMessage);
        return;
      }
      setSubmitMergeLoading(true);
      const response = await StudentsActions.mergeStudentDuplicates(
        ids,
        finalStudent,
      );
      toast.success("Students have been successfully merged", "success");
      setSubmitMergeLoading(false);
      history.replace(`${studentsListPath}/${response.studentID}`);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setMergeErrorMessage(ApiCallErrorMessageHandler(err));
        setSubmitMergeLoading(false);
      }
    }
  };
  useEffect(() => {
    compareStudents(query);
  }, [compareStudents, query]);

  return (
    <div>
      <Prompt
        when={dirty && !submitMergeLoading}
        message="Are you sure you want to leave this page? Your unsaved changes will be lost."
      />
      {loading ? (
        <div className="flex flex-justify-center" style={{ height: "100%" }}>
          <Loader />
        </div>
      ) : error ? (
        <div className="text-center mt-32">
          <img src="/images/error.svg" alt="error-robot" height="320" />
          <p
            className="text-center error-message mt-24"
            style={{ position: "relative" }}
          >
            {errorMessage ||
              "Something went wrong and we could not retrieve student details. Please try again."}
          </p>
        </div>
      ) : (
        <div>
          <div className="page-title">Merge potential duplicates</div>
          <p className="accent-text">
            Select fields to save and merge into a single profile. History of
            activity will be merged from all accounts. Review student activity
            after merge to ensure there are no duplicate interactions.
          </p>
          <div className="col-xs-12 mt-16 mb-32">
            <MergeDuplicateForm
              fields={fields}
              ids={ids}
              students={students}
              resolveField={resolveField}
              selectAllFields={selectAllFields}
              selectAllSectionFields={selectAllSectionFields}
              removeStudent={removeStudent}
            />
            <div className="merge-duplicates-form-footer">
              {mergeErrorMessage && (
                <p
                  className="error-text"
                  style={{ textAlign: "right", marginBottom: 8 }}
                >
                  {mergeErrorMessage}
                </p>
              )}
              <div className="merge-duplicates-btns">
                {submitMergeLoading ? (
                  <div className="medium-loader">
                    <Loader />
                  </div>
                ) : (
                  <Fragment>
                    <button
                      className="cancel-btn btn link-text uppercase-text fw-500 mr-20"
                      onClick={() => history.push(studentsListPath)}
                    >
                      Cancel
                    </button>
                    <button
                      className="merge-btn btn uppercase-text fw-500"
                      disabled={unresolvedFields.length > 0}
                      onClick={() => setShowConfirmationModal(true)}
                    >
                      Merge Students
                    </button>
                  </Fragment>
                )}
              </div>
            </div>
            <ConfirmationModal
              cancel={() => setShowConfirmationModal(false)}
              confirm={merge}
              confirmText="Yes"
              message={`Are you sure you want to merge these student accounts? This cannot be undone!`}
              show={showConfirmationModal}
              noTitle={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(StudentMergeDuplicates);
