import React from "react";
import Pages from "../../..";
import { PageLink } from "../../../../lib";

export default class AccountInfo extends React.PureComponent {
  state = {
    accountInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        accountInfoShowing: false,
      });
    }
  }

  render() {
    const { user } = this.props;
    return user.userName ? (
      <div
        className={
          this.state.accountInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        {/* if no username it means its a google signin, hide entire account information - check if value is UserName like this */}
        <p
          className="accent-text-dark medium-text fw-700 mt-16 mb-16"
          onClick={() => {
            this.setState({
              accountInfoShowing: !this.state.accountInfoShowing,
            });
          }}
        >
          Account Information
        </p>
        <div className="profile-form-inputs account-info">
          <div className="flex flex-justify-space flex-align-center mr-32">
            <label className="accent-text small-text">Email/Phone</label>
            <input
              type="text"
              className="custom-input"
              disabled={true}
              value={user.userName}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center">
            <label className="accent-text small-text">Password</label>
            <input
              type="password"
              className="custom-input"
              disabled={true}
              value="xxxxxxxxx"
            />
            <PageLink
              to={Pages.account.resetPass}
              className="link-text ml-16 uppercase-text"
              style={{ fontSize: "10px" }}
            >
              Reset
            </PageLink>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}
