import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { CampusesActions, ProfileActions } from "../../state";
import Profile from "./Profile";

export default connect(
  function mapState(state) {
    return {
      campuses: state.campuses.list,
      profile: state.profile,
      permissionClaims: state.auth.permissionClaims,
      user: {
        ...state.auth.person,
        userName: state.auth.userName,
        shliachId: state.auth.shliachID,
      },
      sys: state.sys,
      ui: state.ui,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: {
        ...bindActionCreators(CampusesActions, dispatch),
        ...bindActionCreators(ProfileActions, dispatch),
      },
    };
  },
)(Profile);
