import React, { useState } from "react";
import MergeDuplicateRow from "./MergeDuplicateRow";
import _isEqual from "lodash.isequal";
const selectedInputColor = "#33b7ae";
const MergeDuplicateSection = (props) => {
  const {
    sectionFields,
    students,
    ids,
    sectionName,
    sectionTitle,
    showAllFields,
    selectedSectionIndex,
    setSelectedSectionIndex,
    selectAllSectionFields,
    selectedAllIndex,
    resolveField,
  } = props;
  const isEqual = (values) => {
    return values.every((item) => _isEqual(item, values[0]));
  };

  const [matchingFields, setMatchingFields] = useState([]);

  return (
    <>
      {(showAllFields || matchingFields?.length !== sectionFields?.length) && (
        <>
          <div className="section-divider fw-700 medium-text">
            {sectionTitle}
          </div>
          <div
            className="field-row"
            style={{
              gridTemplateColumns: `180px repeat(${students.length}, 300px)`,
            }}
          >
            <p className="mb-16" />

            {students.map((val, studentIndex) => (
              <div key={studentIndex}>
                <div
                  className="exclude-student mb-12"
                  style={{
                    borderColor:
                      selectedSectionIndex[sectionName] === studentIndex
                        ? selectedInputColor
                        : "#edecec",
                  }}
                >
                  <p className="accent-text medium-text merge-radio-container">
                    <input
                      type="radio"
                      value={studentIndex || ""}
                      style={{ marginRight: 8 }}
                      checked={
                        selectedSectionIndex[sectionName] === studentIndex
                      }
                      onChange={() => {
                        setSelectedSectionIndex({
                          ...selectedSectionIndex,
                          [sectionName]: studentIndex,
                        });
                        selectAllSectionFields(studentIndex, sectionName);
                      }}
                    />
                    Select all fields in this section
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {sectionFields?.map((props, index) => (
        <>
          <MergeDuplicateRow
            {...props}
            key={index}
            students={students}
            isEqual={(values) => isEqual(values, props.name)}
            equalFields={matchingFields}
            setEqualFields={setMatchingFields}
            ids={ids}
            resolveField={(val, name) => {
              resolveField(val, name);
            }}
            selectedAllIndex={selectedAllIndex}
            selectedSectionIndex={selectedSectionIndex[sectionName]}
            showAllFields={showAllFields}
          />
          {sectionName === "general" &&
            props.name === "email" &&
            !matchingFields.includes("email") && (
              <div className="small-text accent-text-secondary mb-16">
                <p>
                  Only one email can be saved per student, which will become the
                  student's login. If you would like to save the other email
                  addresses, please copy them into the notes.
                </p>
              </div>
            )}
        </>
      ))}
    </>
  );
};
export default React.memo(MergeDuplicateSection);
